import { Injectable } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ActionNotificationComponent } from '../_shared/action-natification/action-notification.component';
import { DeleteEntityDialogComponent } from '../_shared/delete-entity-dialog/delete-entity-dialog.component';
import { FetchEntityDialogComponent } from '../_shared/fetch-entity-dialog/fetch-entity-dialog.component';
import { UpdateStatusDialogComponent } from '../_shared/update-status-dialog/update-status-dialog.component';




export enum MessageType {
	Success,
	Warning,
	Error
}

@Injectable()
export class LayoutUtilsService {
	constructor(private snackBar: MatSnackBar,
		private dialog: MatDialog) { }

	// SnackBar for notifications
	showActionNotification(
		message: string,
		type: MessageType = MessageType.Success,
		duration: number = 10000,
		showCloseButton: boolean = true,
		showUndoButton: boolean = false,
		undoButtonDuration: number = 3000,
		verticalPosition: 'top' | 'bottom' = 'top'
	) {
		return this.snackBar.openFromComponent(ActionNotificationComponent, {
			duration: duration,
			data: {
				message,
				snackBar: this.snackBar,
				showCloseButton: showCloseButton,
				showUndoButton: showUndoButton,
				undoButtonDuration,
				verticalPosition,
				type,
				action: 'Undo'
			},
			verticalPosition: verticalPosition
		});
	}

	// Method returns instance of MatDialog
	showConfirmationDialog(title: string = '', description: string = '') {
		return this.dialog.open(DeleteEntityDialogComponent, {
			data: { title, description },
			width: '440px'
		});
	}
}
