import { Observable } from 'rxjs';
import { QueryParamsModel } from '../../models/query-models/query-params.model';
import { QueryResultsModel } from '../../models/query-models/query-results.model';

export abstract class BaseContract<T> {
abstract get(id: number): Observable<T>;
// abstract async get(id: number): Promise<T>;
abstract getAll(): Observable<T[]>;
abstract delete(id:number):Observable<any>;
abstract update(entity:T):Observable<any> 
abstract insert(entity:T):Observable<any>;
// abstract find(queryParams: QueryParamsModel): Observable<QueryResultsModel>;

}
