import { Injectable } from '@angular/core';
import { Api } from './api';
import { Observable } from 'rxjs';
import { SettingsProviderContract } from '../../contracts/settings.contract';
import { AttachmentsTypesModel, EntityType, EventType, Emoji } from '../../../models/settings.model';
import { HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class SettingsServiceDataProvider implements SettingsProviderContract  {
  getAllEventsTypes(): Observable<EventType[]> {
    return this.api.get('DayEvents/GetEventsTypes');
  }
  addEventType(entity: EventType, formData: FormData): Observable<any> {
// tslint:disable-next-line: max-line-length
    return this.api.post(`DayEvents/AddEventType?DName1=${entity.DName1}&DName2=${entity.DName2}&Color=${entity.Color}&IsVisibleToClient=${entity.IsVisibleToClient}&IsNotesRequired=${entity.IsNotesRequired}&IsEmojiRequired=${entity.IsEmojiRequired}`, formData, { headers: new HttpHeaders() });
  }
  updateEventType(entity: EventType, formData: FormData): Observable<any> {
// tslint:disable-next-line: max-line-length
    return this.api.post(`DayEvents/UpdateEventType?DayEventTypeId=${entity.DayEventTypeId}&DName1=${entity.DName1}&DName2=${entity.DName2}&Color=${entity.Color}&IsVisibleToClient=${entity.IsVisibleToClient}&IsNotesRequired=${entity.IsNotesRequired}&IsEmojiRequired=${entity.IsEmojiRequired}`, formData, { headers: new HttpHeaders() });
  }
  getAllEmojis(): Observable<Emoji[]> {
    return this.api.get('DayEvents/GetEmojis');
  }
  addEmoji(entity: Emoji): Observable<any> {
    return this.api.post('DayEvents/AddEmoji', entity);
  }
  deleteEmoji(Id: number): Observable<any> {
    return this.api.delete(`DayEvents/DeleteEmoji?id=${Id}`);
  }
  getAll(): Observable<AttachmentsTypesModel[]> {
    throw new Error('Method not implemented.');
  }
  insert(entity: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  get(id: number): Observable<any> {
    throw new Error('Method not implemented.');
  }
  getAllAttachmentsTypes(): Observable<any[]> {
    return this.api.get('Attachments/GetAllAttachmentsTypes');
  }
  delete(id: number): Observable<any>  {
    throw new Error('Method not implemented.');
  }
  update(entity: any):  Observable<any> {
    throw new Error('Method not implemented.');
  }
  addAttachmentType(entity: AttachmentsTypesModel): Observable<any> {
    return this.api.post('Attachments/AddAttachmentType', entity);
  }
  updateAttachmentType(entity: AttachmentsTypesModel): Observable<any> {
    return this.api.post('Attachments/UpdateAttachmentType', entity);
  }
  getEntityTypes(): Observable<EntityType[]> {
    return this.api.get('Attachments/GetEntityTypes');
  }
  constructor(private api: Api) { }

}
