import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClientModel } from '../../../../../models/client.model';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import { ClientsRepository } from '../../../../../data/repositories/clients.repository';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {
	LayoutUtilsService,
	MessageType
} from '../../../../layout/utils/layout-utils.service';
import { DataGridHelper } from '../../grid.helper';
import { AttachmentsRepository } from '../../../../../data/repositories/Attachments.repository';
import { AttachmentModel } from '../../../../../models/attachment.model';
import { QueryParamsModel } from '../../../../../models/query-models/query-params.model';
import { QueryResultsModel } from '../../../../../models/query-models/query-results.model';
import { mergeMap, map, startWith } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { BranchModel } from '../../../../../models/branch.model';
import { BranchesRepository } from '../../../../../data/repositories/branches.repository';
import { BaseComponent } from '../../../../../core/base/base.component';
import { TypesUtilsService } from '../../../../layout/utils/types-utils.service';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';

@Component({
	selector: 'm-client-details',
	templateUrl: './client-branch-details.component.html',
	styleUrls: ['./client-branch-details.component.scss'],
	providers: [ClientsRepository, BranchesRepository]
})
export class ClientBranchDetailsComponent extends BaseComponent
	implements OnInit {
	isNewOrEdit: boolean;
	clientForm: FormGroup;
	client: ClientModel = new ClientModel();
	branches: BranchModel[] = [];
	filteredClientsList: ClientModel[] = [];
	allClients: ClientModel[] = [];
	withClose: boolean;
	hasFormErrors: boolean = false;
	viewLoading: boolean = false;
	loadingAfterSubmit: boolean = false;
	errorMessage: string = '';
	constructor(
		private clientFB: FormBuilder,
		public subheaderService: SubheaderService,
		public clientsRepository: ClientsRepository,
		public branchesRepository: BranchesRepository,
		public layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<ClientBranchDetailsComponent>,
		public spinner: NgxSpinnerService,
		public typesUtilsService: TypesUtilsService,	
		public authService: AuthenticationService,
		public ref: ChangeDetectorRef,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		super(
			subheaderService,
			layoutUtilsService,
			ref,
			spinner,
			typesUtilsService,
			authService
		);
		this.createForm();
	}
	async ngOnInit() {
		this.allClients = await this.async(this.clientsRepository.getAll());
		this.filteredClientsList = this.allClients;
		this.branches =  await this.async(this.branchesRepository.getPrivateBranches());
		this.createForm();
	}
	createForm() {
		this.clientForm = this.clientFB.group({
			client: ['', Validators.required],
			branch: ['', Validators.required]
		});
		this.clientForm.controls.client.valueChanges.subscribe(a => {
			this.filteredClientsList = this.filterClient(a);
		});
	}
	filterClient(val: string): ClientModel[] {
		return this.allClients.filter(option => {
			const fullName = option.FirstName + ' ' + option.MidName + ' ' + option.LastName;
			return fullName.includes(val) || option.PhoneNumber.includes(val);
		});
	}
	displayFn(client?: ClientModel): string | undefined {
		if (!client) {
			return;
		}
		console.log(client.Id);
		if (client.Id) {
			return client.FirstName + ' ' + client.MidName;
		}
	}
	/** UI */
	getTitle(): string {
		return 'إضافة عميل لفرع';
	}

	isControlInvalid(controlName: string): boolean {
		const control = this.clientForm.controls[controlName];
		const result = control.invalid && control.touched;
		return result;
	}

	async onSubmit(withClose: boolean = false) {
		this.withClose = withClose;
		const controls = this.clientForm.controls;
		/** check form */
		if (this.clientForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
		let client = controls['client'].value;
		let branchId = controls['branch'].value;
		await this.async(this.clientsRepository.updateClientBranch(client.ClientId, branchId));
		if(this.hasErrors)return;
		this.saveCompleted();
	}

	saveCompleted() {
		if (!this.withClose) {
			this.layoutUtilsService.showActionNotification(
				'تم الحفظ بنجاح',
				MessageType.Success,
				4000,
				true,
				false
			);
			return;
		}
		this.dialogRef.close({
			isEdit: true
		});
	}
}
