import {
	Component,
	OnInit,
	Input,
	ChangeDetectionStrategy,
	ViewChild,
	ElementRef,
	ChangeDetectorRef
} from '@angular/core';
import { MessengerService } from '../../../../../core/services/messenger.service';
import { MessageData } from '../../../../../core/interfaces/message-data';
import { Observable } from 'rxjs';
import { BaseComponent } from '../../../../../core/base/base.component';
import { ChatsRepository } from '../../../../../data/repositories/chat.repository';
import { IClient, Chats, NewMessage, ChatMessage, UserChats } from '../../../../../models/models';
import { default as format } from 'date-fns/format';
import { tap, map } from 'rxjs/operators';
import PerfectScrollbar from 'perfect-scrollbar';
import { PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessagingService } from '../../../../../core/services/messaging.service';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
	selector: 'm-messenger',
	templateUrl: './messenger.component.html',
	styleUrls: ['./messenger.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ChatsRepository]
})
export class MessengerComponent implements OnInit {
	_chatSubscription;
	messages: Observable<any>;
	cachedChats: Chats[];
	public userChats: UserChats[] = [];
	public chats: Chats = {
		ChatId: null,
		Messages: [] = []
	};
	public chatId: number = null;
	public userId: string;
	public gender: string;
	public text: string = '';
	counter: number = 0;
	isLoaded: boolean = false;

	public newMessage: NewMessage = {
		ClientId: null,
		ChatId: null
	};
	constructor(
		public db: AngularFireDatabase,
		public chatsRepository: ChatsRepository,
		public spinner: NgxSpinnerService,
		private ref: ChangeDetectorRef,
	    private msgService: MessagingService ) {
		this.userId = JSON.parse(localStorage.getItem('user')).Employee.Id;
		this.gender = JSON.parse(localStorage.getItem('user')).Employee.Gender;

		this.msgService.messaging.onMessage(data => {
			console.log(data);
			console.log(this.chatId);
			if (data && data.Type === 'chat' && this.chatId) {
				this.showChat(this.chatId);
			}
		});


	}
	@ViewChild('scrollbar') private scrollbar: PerfectScrollbarComponent;
	ngOnInit() {
		this.getUserChats();
	// 	const chatSub = this.db.object('/chats').valueChanges().subscribe( data => {
	// 		console.log('rec');
	// 	this.getUserChats();
    //   });
	}

	scrollToBottom() {
		// try {
		// this.scroll.scrollToBottom(0, 1);
		if (!this.scrollbar) { return; }
		this.scrollbar.directiveRef.scrollToBottom(0, 0);
		// } catch (err) { }
	}

	load(lastId?: number) {
		console.log(this.chatId);
		console.log(lastId);
		return this.chatsRepository.getMessages(this.chatId, lastId).pipe(tap(response => {
			console.log(response);
			for (const msg of response.Chat.Messages as ChatMessage[]) {
				if (msg.UserId === this.userId) {
					msg.sentByUser = true;
				}
				msg.time = format(msg.SentDate, 'HH:mm');
				msg.date = format(msg.SentDate, 'DD-MM-YYYY');
				if (msg.Attachment) {
					msg.Attachment.type = this.getType(msg.Attachment.Extension);
					msg.Attachment.show = this.showAttachment(msg.Attachment.Extension);
					msg.Attachment.fullPath = response.AttachmentBaseUrl.concat(
						msg.Attachment.Path
					);
				}
			}
		}));
	}
	public getType(extension: string) {
		return this.getMime(extension);
	}
	public showAttachment(extension: string) {
		return this.getTypeFromExtension(extension);
	}

	getUserChats() {
		this.spinner.show();
		this.chatsRepository.getChats().subscribe(res => {
			this.spinner.hide();
			this.userChats = res;
			for (const msg of res) {
				if (msg.LastMessageDate) {
					msg.time = format(msg.LastMessageDate, 'HH:mm');
				}
			}
			this.ref.detectChanges();

		});

	}
	addFireMessage(type: string, messageId: number = 0) {
		this.db.list('/chats/' + this.chatId).push({
		  userId: this.userId,
		  type: type,
		  messageId: messageId
		}).then(() => {
		  console.log('push done');
		}).catch(() => {
		});
	  }
	showChat(chatId: number) {
		console.log('showChat');
		console.log(chatId);
		this.chatId = chatId;
		this.isLoaded = false;
		this.chats.Messages = [];
		// this.refreshChat();
		// this.ref.detectChanges();

		this._chatSubscription = this.db.object('/chats/' + chatId).valueChanges().subscribe(data => {
			console.log('fire message received');
			console.log(data);
			this.refreshChat();
			this.ref.detectChanges();
			if (data) {
			  const resultArray = Object.keys(data).map(function (index) {
				const row = data[index];
				return row;
			  });
			  const lastMessage = resultArray[resultArray.length - 1];
			  console.log(lastMessage);
			  if (lastMessage.type === 'delete' && this.chats.Messages && this.chats.Messages.find(a => a.MessageId === lastMessage.messageId)) {
				this.chats.Messages = [];
			  }

			}

			this.isLoaded = true;
		  }
		  );
	
	}

	closeChat() {
		this.chatId = null;
		this._chatSubscription.unsubscribe();
	 	this.getUserChats();
		this.ref.detectChanges();
	}


	refreshChat() {
		console.log('refreshChat');
		if (this.chats.Messages.length) {
		  const lastId = this.chats.Messages[this.chats.Messages.length - 1]
			.MessageId;
		  this.load(lastId).subscribe(result => {
			// check if the message is already added before
			for (let i = 0; i <= result.Chat.Messages.length - 1; i++) {
			  const element = result.Chat.Messages[i] as ChatMessage;
			  if (!this.chats.Messages.find(a => a.MessageId === element.MessageId)) {
				this.chats.Messages = this.chats.Messages.concat(element);
			  }
			}
			try {
			  this.ref.detectChanges();
			  this.scrollToBottom();
			} catch (err) { console.log(err); }
		  });
		} else {
		  this.load(null).subscribe(result => {
			this.chats = result.Chat;
			try {
			  this.ref.detectChanges();
			  this.scrollToBottom();
			} catch (err) { }
		  });
		}
	  }


	public send() {
		console.log('send');
		this.newMessage.ChatId = this.chatId;
		this.newMessage.Content = this.text;
		this.text = '';
		if (!this.newMessage.Content) { return; }
		this.chatsRepository.sendMessage(this.newMessage).subscribe(a => {
			//this.showChat(this.chatId);
			this.addFireMessage('add');
		});

	}

	public getMime(extension: string): string {
		switch (extension) {
			case '.jpg':
			case '.jpeg':
			case '.gif':
			case '.bmp':
			case '.png':
				return 'image';
			case '.mp3':
				return 'audio/mpeg';
			case '.wav':
				return 'audio/wav';
			case '.amr':
				return 'audio/amr';
			case '.3gp':
				return 'video/3gpp';
			case '.aac':
				return 'audio/x-aac';
			case '.mp4':
				return 'video/mp4';
			case '.webm':
				return 'video/webm';
			case '.flac':
				return 'audio/x-flac';
			case '.m4a':
				return 'audio/mp4';
		}
	}
	public getTypeFromExtension(extension: string): string {
		switch (extension) {
			case '.jpg':
			case '.jpeg':
			case '.gif':
			case '.bmp':
			case '.png':
				return 'image';
			case '.mp3': case '.wav': case '.amr': case '.flac': case '.m4a': case '.aac':
				return 'audio';
			case '.3gp': case '.mp4': case '.webm':
				return 'video';
		}
	}
}
