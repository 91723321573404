import { BaseModel } from './_base.model';
import { BranchModel } from './branch.model';
import { SubscriptionDayModel } from './subscription-day.model';
import { ChildModel } from './child.model';
import { ClientModel } from './client.model';
import { Service, PackageModel } from './package.model';

export class SubscriptionModel extends BaseModel {
	State: number;
	StateName: string;
	Child: ChildModel = new ChildModel();
	Client: ClientModel = new ClientModel();
	StartDate: any;
	PackageId: number;
	ChildId: number;
	PaymentMethod: number;
	EndDate?: Date;
	Amount: number;
	PaidAmount?: number;
	VatAmount: number;
	Branch: BranchModel = new BranchModel();
	SubscriptionDays: SubscriptionDayModel[];
	ServiceName: string;
	ServiceId: number;
	PackageName: string;
	CreatedAt: string;
	CreatedByName: string;
	QurrahFundAmount: number;
	IsQurrah: boolean;
	UnitsCount: number;
	TotalAmount: number;
	Discount: number;
	AvailableFrom: string;
	AvailableTo: string;
	RegistrationFees: number;
	IsTrial: boolean;
	CancelationDate: Date;
	CancelationReason: string;
	ChildSubscriptionId?: number;
	ParentSubscriptionId?: number;

}
export class CancelSubscriptionModel extends BaseModel {
	SubscriptionId: number;
	CancellationDate: string;
	CancellationReason: string;
}