import { Component, OnInit } from '@angular/core';
import { DataGridHelper } from '../../../crm/grid.helper';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import { MatDialog } from '@angular/material';
import {
  LayoutUtilsService,
  MessageType
} from '../../../../layout/utils/layout-utils.service';
import { Observable, of } from 'rxjs';
import { QueryParamsModel } from '../../../../../models/query-models/query-params.model';
import { QueryResultsModel } from '../../../../../models/query-models/query-results.model';
import { mergeMap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { SettingsRepository } from '../../../../../data/repositories/settings.repository';
import { EventsTypesDetailsComponent } from '../events-details/events-details.component';
import { EventType } from '../../../../../models/settings.model';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
@Component({
  templateUrl: './events-all.component.html',
  styleUrls: ['./events-all.component.scss'],
  providers: [SettingsRepository]
})
export class EventsTypesAllComponent extends DataGridHelper implements OnInit {
  displayedColumns = [
    'TypeId',
    'DName1',
    'DName2',
    'Image',
    'Color',
    'IsVisibleToClient',
    'IsNotesRequired',
    'IsEmojiRequired'
  ];
  constructor(
    public dialog: MatDialog,
    private subheaderService: SubheaderService,
    public settingsRepository: SettingsRepository,
    public spinner: NgxSpinnerService,
    public layoutUtilsService: LayoutUtilsService,
    public authService: AuthenticationService
  ) {
    super(spinner, layoutUtilsService, authService);
    this.dataGridSource = settingsRepository;
  }

  find(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    return this.settingsRepository.getAllEventsTypes().pipe(
      mergeMap(res => {
        const result = this.baseFilter(res, queryParams);
        return of(result);
      })
    );
  }
  ngOnInit() {
    this.initializeDataGrid();
    this.loadList();
    this.subheaderService.setBreadcrumbs([
      { title: 'أنواع الأحداث اليومية' },
      { title: 'كل أنواع الأحداث', page: '/settings/events/all' }
    ]);
  }

  /** FILTRATION */
  filterConfiguration(): any {
    const filter: any = {};
    const searchText: string = this.searchInput.nativeElement.value;
    filter.DName1 = searchText;
    filter.DName2 = searchText;
    return filter;
  }

  addType() {
    const type = new EventType();
    this.editType(type);
  }
  editType(type: EventType) {
    const dialogRef = this.dialog.open(EventsTypesDetailsComponent, {
      data: { type }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      console.log(res);
      this.saveCompleted();
      this.loadList();
    });
  }
  saveCompleted() {
    this.layoutUtilsService.showActionNotification(
      'تم الحفظ بنجاح',
      MessageType.Success,
      4000,
      true,
      false
    );
  }
}
