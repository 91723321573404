import { BaseContract } from './base.contract';
import { BranchModel } from '../../models/branch.model';
import { Observable } from 'rxjs';


export  abstract class BranchesProviderContract extends BaseContract<BranchModel>{
abstract getClientBranches(clientId: number): Observable<BranchModel[]>;
abstract getPrivateBranches(): Observable<BranchModel[]>;
abstract getBranchWorkDays(branchId: number): Observable<any>;
abstract removeWorkDayFromBranch(branchId: number, dayId: number): Observable<any>;
abstract getWeekdays(): Observable<any>;
abstract addWorkDayToBranch(branchId: number, dayId: any): Observable<any> ;
}
