import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BranchesAllComponent } from './branches/branches-all/branches-all.component';
import { BranchesDetailsComponent } from './branches/branches-details/branches-details.component';
import { PackagesDetailsComponent } from './packages/packages-details/packages-details.component';
import { PackagesAllComponent } from './packages/packages-all/packages-all.component';
import { CamerasAllComponent } from './cameras/cameras-all/cameras-all.component';
import { CamerasDetailsComponent } from './cameras/cameras-details/cameras-details.component';
import { RouterModule } from '@angular/router';
import { Routes } from '@angular/router';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material';
import {
	MatAutocompleteModule,
	MatNativeDateModule,
	MatFormFieldModule,
	MatInputModule,
	MatRadioModule,
	MatButtonModule,
	MatCardModule,
	MatChipsModule,
	MatSelectModule,
	MatProgressBarModule,
	MatProgressSpinnerModule,
	MatIconModule,
	MatSliderModule,
	MatPaginatorModule,
	MatSortModule,
	MatSidenavModule,
	MatSnackBarModule,
	MatStepperModule,
	MatToolbarModule,
	MatDividerModule,
	MatTabsModule,
	MatTableModule,
	MatTooltipModule,
	MatListModule,
	MatGridListModule,
	MatButtonToggleModule,
	MatBottomSheetModule,
	MatExpansionModule,
	_MatChipListMixinBase,
	MatMenuModule,
	MatTreeModule,
	MatCheckboxModule,
	MAT_BOTTOM_SHEET_DATA,
	MatBottomSheetRef,
	MatSlideToggleModule
} from '@angular/material';
import { ColorPickerModule } from 'ngx-color-picker';
import { CoreModule } from '../../../core/core.module';
import { PartialsModule } from '../../partials/partials.module';
import { MaterialPreviewModule } from '../../partials/content/general/material-preview/material-preivew.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { PermissionsAllComponent } from './permissions/permissions-all/permissions-all.component';
import { PermissionsDetailsComponent } from './permissions/permissions-details/permissions-details.component';
import { AttachmentsTypesAllComponent } from './attachment-types/types-all/types-all.component';
import { AttachmentsTypesDetailsComponent } from './attachment-types/types-details/types-details.component';
import { EventsTypesAllComponent } from './daily-events/events-all/events-all.component';
import { EventsTypesDetailsComponent } from './daily-events/events-details/events-details.component';
import { EmojisAllComponent } from './emojis/emojis-all/emojis-all.component';
import { EmojisDetailsComponent } from './emojis/emojis-details/emojis-details.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { UsersAllComponent } from './user-management/users/users-all/users-all.component';
import { RolesListComponent } from './user-management/roles/roles-list/roles-list.component';
import { RoleEditDialogComponent } from './user-management/roles/role-edit/role-edit.dialog.component';
import { UserDetailsComponent } from './user-management/users/user-edit/user-edit.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { UserRolesComponent } from './user-management/users/_subs/user-roles/user-roles.component';
import { ExpensesTypesAllComponent } from './expenses-types/types-all/types-all.component';
import { ExpensesTypesDetailsComponent } from './expenses-types/types-details/types-details.component';
import { BranchWorkdaysComponent } from './branches/branches-workdays/branches-workdays.component';
import { AgeCategoriesDetailsComponent } from './age-categories/age-categories-details/age-categories-details.component';
import { AgeCategoriesAllComponent } from './age-categories/age-categories-all/age-categories-all.component';

const routes: Routes = [
	{
		path: '',
		children: [
			{
				path: 'users/all',
				component: UsersAllComponent
			},
			{
				path: "users/edit/0/new",
				component: UserDetailsComponent
			},
			{
				path: "users/edit/:id",
				component: UserDetailsComponent
			}
		]
	},
	{
		path: '',
		children: [
			{
				path: 'packages/all',
				component: PackagesAllComponent
			}
		]
	},
	{
		path: '',
		children: [
			{
				path: 'packages/edit:id',
				component: PackagesDetailsComponent
			}
		]
	},
	{
		path: '',
		children: [
			{
				path: 'packages/edit/0/new',
				component: PackagesDetailsComponent
			}
		]
	},
	{
		path: '',
		children: [
			{
				path: 'roles/all',
				component: RolesListComponent
			}
		]
	},
	{
		path: '',
		children: [
			{
				path: 'roles/edit/0/new',
				component: RoleEditDialogComponent
			}
		]
	},
	{
		path: '',
		children: [
			{
				path: 'cameras/all',
				component: CamerasAllComponent
			}
		]
	},
	{
		path: '',
		children: [
			{
				path: 'cameras/edit/0/new',
				component: CamerasDetailsComponent
			}
		]
	},
	{
		path: '',
		children: [
			{
				path: 'branches/all',
				component: BranchesAllComponent
			}
		]
	},
	{
		path: '',
		children: [
			{
				path: 'branches/edit/0/new',
				component: BranchesDetailsComponent
			}
		]
	},
	{
		path: '',
		children: [
			{
				path: 'branches/workdays',
				component: BranchWorkdaysComponent
			}
		]
	},
	{
		path: '',
		children: [
			{
				path: 'types/all',
				component: AttachmentsTypesAllComponent
			}
		]
	},
	{
		path: '',
		children: [
			{
				path: 'types/edit/0/new',
				component: AttachmentsTypesDetailsComponent
			}
		]
	},
	{
		path: '',
		children: [
			{
				path: 'events/all',
				component: EventsTypesAllComponent
			}
		]
	},
	{
		path: '',
		children: [
			{
				path: 'events/edit/0/new',
				component: EventsTypesDetailsComponent
			}
		]
	},
	{
		path: '',
		children: [
			{
				path: 'emojis/all',
				component: EmojisAllComponent
			}
		]
	},
	{
		path: '',
		children: [
			{
				path: 'emojis/edit/0/new',
				component: EmojisDetailsComponent
			}
		]
	},
	{
		path: '',
		children: [
			{
				path: 'expensesTypes/all',
				component: ExpensesTypesAllComponent
			}
		]
	},
	{
		path: '',
		children: [
			{
				path: 'expensesTypes/edit/0/new',
				component: ExpensesTypesDetailsComponent
			}
		]
	},
	{
		path: '',
		children: [
			{
				path: 'ageCategories/all',
				component: AgeCategoriesAllComponent
			}
		]
	},
	{
		path: '',
		children: [
			{
				path: 'ageCategories/edit',
				component: AgeCategoriesDetailsComponent
			}
		]
	}
];
@NgModule({
	imports: [
		CommonModule,
		MatInputModule,
		MatFormFieldModule,
		MatAutocompleteModule,
		MatListModule,
		MatSliderModule,
		MatCheckboxModule,
		MatCardModule,
		MatSelectModule,
		MatButtonModule,
		MatIconModule,
		MatNativeDateModule,
		MatSlideToggleModule,
		MatMenuModule,
		MatTabsModule,
		MatTooltipModule,
		MatSidenavModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatTableModule,
		MatGridListModule,
		MatToolbarModule,
		MatBottomSheetModule,
		MatExpansionModule,
		MatDividerModule,
		MatSortModule,
		MatStepperModule,
		MatChipsModule,
		MatPaginatorModule,
		MatDialogModule,
		CoreModule,
		CommonModule,
		MatRadioModule,
		MatTreeModule,
		MatButtonToggleModule,
		PartialsModule,
		MaterialPreviewModule,
		FormsModule,
		ReactiveFormsModule,
		LayoutModule,
		ColorPickerModule,
		MaterialFileInputModule,
		NgxSpinnerModule,
		RouterModule.forChild(routes)
	],
	exports: [RouterModule],
	entryComponents: [],
	providers: [
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				panelClass: 'm-mat-dialog-container__wrapper',
				height: 'auto',
				width: '1000px'
			}
		}
	],
	declarations: [
		BranchesAllComponent,
		BranchesDetailsComponent,
		PackagesDetailsComponent,
		PackagesAllComponent,
		PermissionsAllComponent,
		PermissionsDetailsComponent,
		UsersAllComponent,
		CamerasAllComponent,
		CamerasDetailsComponent,
		AttachmentsTypesDetailsComponent,
		AttachmentsTypesAllComponent,
		EventsTypesAllComponent,
		EventsTypesDetailsComponent,
		EmojisAllComponent,
		EmojisDetailsComponent,
		RolesListComponent,
		RoleEditDialogComponent,
		UserDetailsComponent,
		UserRolesComponent,
		ExpensesTypesAllComponent,
		ExpensesTypesDetailsComponent,
		BranchWorkdaysComponent,
		AgeCategoriesDetailsComponent,
		AgeCategoriesAllComponent
	]
})
export class SettingsModule { }
