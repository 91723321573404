import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { EmployeesRepository } from '../../../../../../data/repositories/employees.repository';
import { BaseComponent } from '../../../../../../core/base/base.component';
import { BehaviorSubject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EmployeeModel } from '../../../../../../models/employee.model';
import { AttachmentModel } from '../../../../../../models/attachment.model';
import { AgeCategoryModel } from '../../../../../../models/age-category.model';
import { SubheaderService } from '../../../../../../core/services/layout/subheader.service';
import { ChildsRepository } from '../../../../../../data/repositories/childs.repository';
import { AttachmentsRepository } from '../../../../../../data/repositories/Attachments.repository';
import { LayoutUtilsService, MessageType } from '../../../../../layout/utils/layout-utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { EmployeesDetailsComponent } from '../../../../hcm/employees/employees-details/employees-details.component';
import { TypesUtilsService } from '../../../../../layout/utils/types-utils.service';
import { AuthenticationService } from '../../../../../../core/auth/authentication.service';
import { PermissionsRepository } from '../../../../../../data/repositories/permissions.repository';
@Component({
	selector: 'm-user-details',
	templateUrl: './user-edit.component.html',
	styleUrls: ['./user-edit.component.scss'],
	providers: [EmployeesRepository, PermissionsRepository]
})
export class UserDetailsComponent extends BaseComponent
	implements OnInit {
	loadingSubject = new BehaviorSubject<boolean>(false);
	loading$ = this.loadingSubject.asObservable();
	isNewOrEdit: boolean;
	employeeForm: FormGroup;
	passwordForm: FormGroup;
	employee: EmployeeModel = new EmployeeModel();
	employeeAttachments: AttachmentModel[] = [];
	withClose: boolean;
	hasFormErrors: boolean = false;
	viewLoading: boolean = false;
	loadingAfterSubmit: boolean = false;
	categories: AgeCategoryModel[] = [];
	errorMessage: string = '';
	newPassword: string;
	confirmNewPassword: string;
	displayedColumns = [
		'select',
		'AttachmentId',
		'AttachmentName',
		'PublishedAt',
		'PublishedBy',
		'Actions'
	];
	constructor(
		private formBuilder: FormBuilder,
		public subheaderService: SubheaderService,
		public childsRepository: ChildsRepository,
		public employeesRepository: EmployeesRepository,
		public attachmentsRepository: AttachmentsRepository,
		public layoutUtilsService: LayoutUtilsService,
		public spinner: NgxSpinnerService,
		public dialogRef: MatDialogRef<EmployeesDetailsComponent>,
		public typesUtilsService: TypesUtilsService,
		public authService: AuthenticationService,
		public ref: ChangeDetectorRef,
		public permissionsRepository: PermissionsRepository,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		super(
			subheaderService,
			layoutUtilsService,
			ref,
			spinner,
			typesUtilsService,
			authService
		);
	}

	ngOnInit() {
		this.employee = this.data.employee;
		if (this.employee.EmployeeId) {
			this.childsRepository.getCategories().subscribe(result => {
				this.categories = result;
			});
		}
		this.createForm();
	}
	createForm() {
		this.employeeForm = this.formBuilder.group({
			gender: [this.employee.Gender, Validators.required],
			firstName: [this.employee.FirstName, Validators.required],
			lastName: [this.employee.LastName, Validators.required],
			midName: [this.employee.MidName, Validators.required],
			identityNumber: [this.employee.IdentityNumber, Validators.required],
			phoneNumber: [this.employee.PhoneNumber, Validators.required],
			email: [this.employee.Email, Validators.required],
			category: [this.employee.AgeCategoryId]
		});

		this.passwordForm = this.formBuilder.group({
			newPassword: [this.newPassword, Validators.required],
			confirmNewPassword: [this.confirmNewPassword, Validators.required]
		});
	}

	/** UI */
	getTitle(): string {
		if (this.employee.EmployeeId > 0) {
			return `ملف مستخدم:  '${this.employee.FirstName} ${
				this.employee.LastName
			}'`;
		}

		return 'تسجيل مستخدم جديد';
	}

	isControlInvalid(controlName: string): boolean {
		const control = this.employeeForm.controls[controlName];
		const result = control.invalid && control.touched;
		return result;
	}

	/** ACTIONS */
	prepareClient(): EmployeeModel {
		const controls = this.employeeForm.controls;
		const _Employee = new EmployeeModel();
		_Employee.EmployeeId = this.employee.EmployeeId;
		_Employee.FirstName = controls['firstName'].value;
		_Employee.MidName = controls['midName'].value;
		_Employee.LastName = controls['lastName'].value;
		_Employee.Email = controls['email'].value;
		_Employee.Gender = controls['gender'].value;
		_Employee.IdentityNumber = controls['identityNumber'].value;
		_Employee.PhoneNumber = controls['phoneNumber'].value;
		_Employee.AgeCategoryId = controls['category'].value;
		_Employee.Language = '1';
		// console.log(_Employee);
		return _Employee;
	}

	onSubmit(withClose: boolean = false) {
		this.withClose = withClose;
		this.hasFormErrors = false;
		this.errorMessage = '';
		this.loadingAfterSubmit = false;
		const controls = this.employeeForm.controls;
		/** check form */
		if (this.employeeForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			return;
		}

		const editedEmployee = this.prepareClient();
		if (editedEmployee.EmployeeId) {
			this.updateEmployee(editedEmployee);
		} else {
			this.createEmployee(editedEmployee);
		}
	}

	async changePassword() {
		this.hasFormErrors = false;
		this.errorMessage = '';
		this.loadingAfterSubmit = false;
		const controls = this.passwordForm.controls;
		/** check form */
		if (this.passwordForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			return;
		}
		this.newPassword = controls['newPassword'].value;
		this.confirmNewPassword = controls['confirmNewPassword'].value;
		if (this.newPassword !== this.confirmNewPassword){
			this.layoutUtilsService.showActionNotification(
				'كلمة السر غير متطابقة',
				MessageType.Warning,
				3000,
				true,
				false
			);
			return;
		}

		console.log(this.newPassword);
		await this.async(this.permissionsRepository.resetUserPassword(this.employee.Id, this.newPassword));
		if (this.hasErrors) {
			return;
		}
		this.saveCompleted();
	}
	saveCompleted() {
		if (!this.withClose) {
			this.layoutUtilsService.showActionNotification(
				'تم الحفظ بنجاح',
				MessageType.Success,
				4000,
				true,
				false
			);
			return;
		}
		this.dialogRef.close({
			isEdit: true
		});
	}

	async updateEmployee(_Employee: EmployeeModel) {
		await this.async(this.employeesRepository.update(_Employee));
		if (this.hasErrors) {return; }
		this.saveCompleted();
	}

	async createEmployee(_Employee: EmployeeModel) {
		await this.async(this.employeesRepository.insert(_Employee));
		if (this.hasErrors) {return; }
		this.saveCompleted();
	}

	onAlertClose($event) {
		this.hasFormErrors = false;
	}
	async refreshDetails() {
		console.log('refresh');
		this.employee = await this.async(this.employeesRepository.get(this.employee.EmployeeId));
		}
}
