import { Component, OnInit } from '@angular/core';
import { DataGridHelper } from '../../../crm/grid.helper';
import { BranchesRepository } from '../../../../../data/repositories/branches.repository';
import { BranchModel } from '../../../../../models/branch.model';
import { BranchesDetailsComponent } from '../branches-details/branches-details.component';
import { MatDialog } from '@angular/material';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import {
	LayoutUtilsService,
	MessageType
} from '../../../../layout/utils/layout-utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { QueryParamsModel } from '../../../../../models/query-models/query-params.model';
import { Observable, of } from 'rxjs';
import { QueryResultsModel } from '../../../../../models/query-models/query-results.model';
import { mergeMap } from 'rxjs/operators';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';

@Component({
	selector: 'm-branches-all',
	templateUrl: './branches-all.component.html',
	styleUrls: ['./branches-all.component.scss'],
	providers: [BranchesRepository]
})
export class BranchesAllComponent extends DataGridHelper implements OnInit {
	displayedColumns = ['BranchId', 'DName1', 'DName2', 'IsPrivate'];
	constructor(
		public dialog: MatDialog,
		private subheaderService: SubheaderService,
		public branchesRepository: BranchesRepository,
		public layoutUtilsService: LayoutUtilsService,
		public spinner: NgxSpinnerService,
		public authService: AuthenticationService
	) {
		super(spinner, layoutUtilsService, authService);
		this.dataGridSource = branchesRepository;
	}

	find(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		return this.branchesRepository.getAll().pipe(
			mergeMap(res => {
				const result = this.baseFilter(res, queryParams);
				return of(result);
			})
		);
	}
	ngOnInit() {
		this.initializeDataGrid();
		this.loadList();
		this.subheaderService.setBreadcrumbs([
			{ title: 'الفروع' },
			{ title: 'كل الفروع', page: '/settings/branches/all' }
		]);
		this.dataGridSource = this.branchesRepository;
	}

	/** FILTRATION */
	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;
		filter.DName2 = searchText;
		filter.DName1 = searchText;
		return filter;
	}

	addBranch() {
		var branch = new BranchModel();
		this.editBranch(branch);
	}
	editBranch(branch: BranchModel) {
		let saveMessageTranslateParam =
			branch.Id > 0 ? 'UPDATE_MESSAGE' : 'ADD_MESSAGE';
		const _saveMessage = 'تم الحفظ بنجاح';
		const _messageType =
			branch.Id > 0 ? MessageType.Success : MessageType.Success;
		const dialogRef = this.dialog.open(BranchesDetailsComponent, {
			data: { branch }
		});
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}
			console.log(res);
			this.layoutUtilsService.showActionNotification(
				_saveMessage,
				_messageType,
				4000,
				true,
				false
			);

			this.loadList();
		});
	}
}
