import { Component, OnInit } from '@angular/core';
import { DataGridHelper } from '../../../crm/grid.helper';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import { MatDialog } from '@angular/material';
import {
	LayoutUtilsService,
	MessageType
} from '../../../../layout/utils/layout-utils.service';
import { Observable, of } from 'rxjs';
import { QueryParamsModel } from '../../../../../models/query-models/query-params.model';
import { QueryResultsModel } from '../../../../../models/query-models/query-results.model';
import { mergeMap, catchError } from 'rxjs/operators';
import { PermissionsRepository } from '../../../../../data/repositories/permissions.repository';
import { PermissionModel } from '../../../../../models/permission.model';
import { PermissionsDetailsComponent } from '../permissions-details/permissions-details.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';

@Component({
	selector: 'm-permissions-all',
	templateUrl: './permissions-all.component.html',
	styleUrls: ['./permissions-all.component.scss'],
	providers: [PermissionsRepository]

})
export class PermissionsAllComponent extends DataGridHelper implements OnInit {
	displayedColumns = [
		'FirstName',
		'DName1',
		'Name'
	];
	constructor(
		public dialog: MatDialog,
		private subheaderService: SubheaderService,
		public permissionsRepository: PermissionsRepository,
		public layoutUtilsService: LayoutUtilsService,
		public spinner: NgxSpinnerService,
		public authService: AuthenticationService

	) {
		super(spinner, layoutUtilsService, authService);
		this.dataGridSource = permissionsRepository;
	}

	find(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		return this.permissionsRepository.getAll().pipe(
			mergeMap(res => {
				console.log(125);
				const result = this.baseFilter(res, queryParams);
				return of(result);
			})
		);
	}
	ngOnInit() {
		this.initializeDataGrid();
		this.loadList();
		this.subheaderService.setBreadcrumbs([
			{ title: 'الصلاحيات' },
			{ title: 'كل الصلاحيات', page: '/settings/permissions/all' }
		]);
	}

	/** FILTRATION */
	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;

		filter.User = searchText;
		filter.Branch = searchText;
		filter.Role = searchText;
		return filter;
	}

	addPermission() {
		const p = new PermissionModel();
		this.editPermission(p);
	}
	editPermission(permission: PermissionModel) {
		const _saveMessage = 'تم الحفظ بنجاح';
		const dialogRef = this.dialog.open(PermissionsDetailsComponent, {
			data: { permission }
		});
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}
			console.log(res);
			this.layoutUtilsService.showActionNotification(
				_saveMessage,
				MessageType.Success,
				4000,
				true,
				false
			);
			this.loadList();
		});
	}
}
