import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PartialsModule } from "../../partials/partials.module";
import { CoreModule } from "../../../core/core.module";
import { MaterialPreviewModule } from "../../partials/content/general/material-preview/material-preivew.module";
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';

import {
	MatDialogModule,
	MAT_DIALOG_DEFAULT_OPTIONS
} from "@angular/material/dialog";
import { MatIconRegistry } from "@angular/material/icon";
import {
	MatAutocompleteModule,
	MatNativeDateModule,
	MatFormFieldModule,
	MatInputModule,
	MatRadioModule,
	MatButtonModule,
	MatCardModule,
	MatChipsModule,
	MatSelectModule,
	MatProgressBarModule,
	MatProgressSpinnerModule,
	MatIconModule,
	MatSliderModule,
	MatPaginatorModule,
	MatSortModule,
	MatSidenavModule,
	MatSnackBarModule,
	MatStepperModule,
	MatToolbarModule,
	MatDividerModule,
	MatTabsModule,
	MatTableModule,
	MatTooltipModule,
	MatListModule,
	MatGridListModule,
	MatButtonToggleModule,
	MatBottomSheetModule,
	MatExpansionModule,
	_MatChipListMixinBase,
	MatMenuModule,
	MatTreeModule,
	MatCheckboxModule,
	MAT_BOTTOM_SHEET_DATA,
	MatBottomSheetRef,
	MatSlideToggleModule
} from "@angular/material";
import { HcmComponent } from "./hcm.component";
import { EmployeesAllComponent } from "./employees/employees-all/employees-all.component";
import { Routes } from "@angular/router";
import { LayoutModule } from "../../layout/layout.module";
import { EmployeesDetailsComponent } from "./employees/employees-details/employees-details.component";

const routes: Routes = [
	{
		path: "",
		component: HcmComponent,
		children: [
			{
				path: "employees/all",
				component: EmployeesAllComponent
			},
			{
				path: "employees/edit/0/new",
				component: EmployeesDetailsComponent
			},
			{
				path: "employees/edit/:id",
				component: EmployeesDetailsComponent
			},
		]
	}
];

@NgModule({
	imports: [
		// material modules
		MatInputModule,
		MatFormFieldModule,
		MatAutocompleteModule,
		MatListModule,
		MatSliderModule,
		MatCheckboxModule,
		MatCardModule,
		MatSelectModule,
		MatButtonModule,
		MatIconModule,
		MatNativeDateModule,
		MatSlideToggleModule,
		MatMenuModule,
		MatTabsModule,
		MatTooltipModule,
		MatSidenavModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatTableModule,
		MatGridListModule,
		MatToolbarModule,
		MatBottomSheetModule,
		MatExpansionModule,
		MatDividerModule,
		MatSortModule,
		MatStepperModule,
		MatChipsModule,
		MatPaginatorModule,
		MatDialogModule,
		CoreModule,
		CommonModule,
		MatRadioModule,
		MatTreeModule,
		MatButtonToggleModule,
		PartialsModule,
		MaterialPreviewModule,
		FormsModule,
		ReactiveFormsModule,
		LayoutModule,
		NgxSpinnerModule,
		RouterModule.forChild(routes)
	],
	exports: [RouterModule],
	entryComponents: [],
	providers: [
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				panelClass: "m-mat-dialog-container__wrapper",
				height: "auto",
				width: "1000px"
			}
		}
	],
	declarations: [
		HcmComponent,
		EmployeesAllComponent,
		EmployeesDetailsComponent
	]
})
export class HcmModule {}
