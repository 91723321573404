import { Component, OnInit } from '@angular/core';
import { DataGridHelper } from '../../../crm/grid.helper';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import { MatDialog } from '@angular/material';
import {
	LayoutUtilsService,
	MessageType
} from '../../../../layout/utils/layout-utils.service';
import { Observable, of } from 'rxjs';
import { QueryParamsModel } from '../../../../../models/query-models/query-params.model';
import { QueryResultsModel } from '../../../../../models/query-models/query-results.model';
import { mergeMap } from 'rxjs/operators';
import { CamerasRepository } from '../../../../../data/repositories/cameras.repository';
import { CameraModel } from '../../../../../models/camera.model';
import { CamerasDetailsComponent } from '../cameras-details/cameras-details.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
@Component({
  selector: 'm-cameras-all',
  templateUrl: './cameras-all.component.html',
  styleUrls: ['./cameras-all.component.scss'],
  providers: [CamerasRepository]
})
export class CamerasAllComponent extends DataGridHelper implements OnInit {
  displayedColumns = [
		'CameraId',
		'DName1',
    'ThumbnailPath',
    'StreamingLink',
    'IsEnabled',
    'Branch'
	];
  constructor(
    public dialog: MatDialog,
		private subheaderService: SubheaderService,
    public camerasRepository: CamerasRepository,
		public spinner: NgxSpinnerService,
    public layoutUtilsService: LayoutUtilsService,
		public authService: AuthenticationService
    ) {
		super(spinner, layoutUtilsService, authService);
		this.dataGridSource = camerasRepository;
    }

    find(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
      return this.camerasRepository.getAll().pipe(
        mergeMap(res => {
          const result = this.baseFilter(res, queryParams);
          return of(result);
        })
      );
    }
    ngOnInit() {
      this.initializeDataGrid();
      this.loadList();
      this.subheaderService.setBreadcrumbs([
        { title: 'الكاميرات' },
        { title: 'كل الكاميرات', page: '/settings/cameras/all' }
      ]);
    }

    /** FILTRATION */
    filterConfiguration(): any {
      const filter: any = {};
      const searchText: string = this.searchInput.nativeElement.value;

      filter.DName1 = searchText;
      return filter;
    }

    addCamera() {
      const camera = new CameraModel();
      this.editCamera(camera);
    }
    editCamera(camera: CameraModel) {
      const _saveMessage = 'تم الحفظ بنجاح';
      const dialogRef = this.dialog.open(CamerasDetailsComponent, {
        data: { camera }
      });
      dialogRef.afterClosed().subscribe(res => {
        if (!res) {
          return;
        }
        console.log(res);
        this.layoutUtilsService.showActionNotification(
          _saveMessage,
          4000,
          null,
          true,
          false
        );

        this.loadList();
      });
    }

}
