import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { BehaviorSubject } from 'rxjs';
import { Api } from '../../data/providers/services/api';



@Injectable()
export class MessagingService {

    public messaging = firebase.messaging();
    public currentMessage = new BehaviorSubject(null);

    constructor(private api: Api) {
        console.log('MessagingService');
    }


    updateToken(token) {
        this.api.update("Register/Put", { Token: token }).subscribe(registrationId => {
            console.log(registrationId);
            localStorage.setItem('DEVICE_ID', registrationId);
        });
    }

    getPermission() {
        this.messaging.requestPermission()
            .then(() => {
                console.log('Notification permission granted.');
                return this.messaging.getToken();
            })
            .then(token => {
                console.log(token);
                this.updateToken(token);
            })
            .catch((err) => {
                console.log('Unable to get permission to notify.', err);
            });
    }

    receiveMessage() {
        this.messaging.onMessage((payload) => {
            console.log("Message received. ", payload);
            this.currentMessage.next(payload);
        });

    }
}
