// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatSnackBar, MatDialog } from '@angular/material';
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, take, delay, mergeMap } from 'rxjs/operators';
import { fromEvent, merge, Observable, of, Subscription } from 'rxjs';
// NGRX
import { Store } from '@ngrx/store';
// LODASH
import _ from 'lodash';
import { RoleModel } from '../../../../../../models/role.model';
import { LayoutUtilsService, MessageType } from '../../../../../layout/utils/layout-utils.service';
import { DataGridHelper } from '../../../../crm/grid.helper';
import { PermissionsRepository } from '../../../../../../data/repositories/permissions.repository';
import { SubheaderService } from '../../../../../../core/services/layout/subheader.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../../../../../../core/auth/authentication.service';
import { QueryParamsModel } from '../../../../../../models/query-models/query-params.model';
import { Role } from '../../../../../../models/employee.model';
import { QueryResultsModel } from '../../../../../../models/query-models/query-results.model';
import { RoleEditDialogComponent } from '../role-edit/role-edit.dialog.component';

@Component({
	selector: 'roles-list',
	templateUrl: './roles-list.component.html',
	providers: [PermissionsRepository]
})
export class RolesListComponent extends DataGridHelper implements OnInit {
	displayedColumns = [
		'RoleId',
		'DName1',
		'DName2',
		'Actions'
	];
	constructor(
		public dialog: MatDialog,
		private subheaderService: SubheaderService,
		public permissionsRepository: PermissionsRepository,
		public layoutUtilsService: LayoutUtilsService,
		public spinner: NgxSpinnerService,
		public authService: AuthenticationService,
		public snackBar: MatSnackBar) {
		super(spinner, layoutUtilsService, authService);
		this.dataGridSource = permissionsRepository;
	}
	find(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		return this.permissionsRepository.getRoles().pipe(
			mergeMap(res => {
				console.log(125);
				const result = this.baseFilter(res, queryParams);
				return of(result);
			})
		);
	}
	ngOnInit() {
		this.initializeDataGrid();
		this.loadList();
		this.subheaderService.setBreadcrumbs([
			{ title: 'المهام' },
			{ title: 'كل المهام', page: '/settings/roles/all' }
		]);
	}

	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;

		filter.DName1 = searchText;
		filter.DName2 = searchText;
		return filter;
	}

	deleteRole(_item: Role) {
		// const _title: string = 'User Role';
		// const _description: string = 'Are you sure to permanently delete this role?';
		// const _waitDesciption: string = 'Role is deleting...';
		// const _deleteMessage = `Role has been deleted`;

		// const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
		// dialogRef.afterClosed().subscribe(res => {
		// 	if (!res) {
		// 		return;
		// 	}

		// 	this.store.dispatch(new RoleDeleted({ id: _item.id }));
		// 	this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
		// 	this.loadRolesList();
		// });
	}

	addRole() {
		const newRole = new RoleModel();
		this.editRole(newRole);
	}

	editRole(role: RoleModel) {
		const _saveMessage = 'تم الحفظ بنجاح';
		const dialogRef = this.dialog.open(RoleEditDialogComponent, {
			data: { role }
		});
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}
			console.log(res);
			this.layoutUtilsService.showActionNotification(
				_saveMessage,
				MessageType.Success,
				4000,
				true,
				false
			);
			this.loadList();
		});
	}
}
