import { ChildsRepository } from '../../../../../data/repositories/childs.repository';
import { SupervisorModel } from '../../../../../models/supervisor.model';
import { ChildModel } from '../../../../../models/child.model';
import { BaseComponent } from '../../../../../core/base/base.component';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import { LayoutUtilsService } from '../../../../layout/utils/layout-utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder } from '@angular/forms';
import { TypesUtilsService } from '../../../../layout/utils/types-utils.service';
import { BranchesRepository } from '../../../../../data/repositories/branches.repository';
import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { BranchModel } from '../../../../../models/branch.model';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { DayEventModel } from '../../../../../models/dayevent.model';
@Component({
	selector: 'm-childs-checkin',
	templateUrl: './childs-check-in.component.html',
	styleUrls: ['./childs-check-in.component.scss'],
	providers: [ChildsRepository]
})
export class ChildsCheckInsComponent extends BaseComponent implements OnInit {

	branches: BranchModel[] = [];
	allCheckedInChildsList: ChildModel[] = [];
	filteredCheckedInChildsList: ChildModel[] = [];

	allCheckedOutChildsList: ChildModel[] = [];
	filteredCheckedOutChildsList: ChildModel[] = [];


	constructor(
		public subheaderService: SubheaderService,
		public layoutUtilsService: LayoutUtilsService,
		public ref: ChangeDetectorRef,
		public spinner: NgxSpinnerService,
		public formBuilder: FormBuilder,
		public typesUtilsService: TypesUtilsService,
		public authService: AuthenticationService,
		public childsRepository: ChildsRepository,
		public branchesRepository: BranchesRepository
	) {
		super(
			subheaderService,
			layoutUtilsService,
			ref,
			spinner,
			typesUtilsService,
			authService
		);
	}
	private _filterCheckOut: string = '';
	public get filterCheckOut(): string {
		return this._filterCheckOut;
	}
	public set filterCheckOut(v: string) {
		console.log(v);

		this._filterCheckOut = v;
		this.filterCheckOutChildsList();
	}

	private _filterCheckIn: string = '';
	public get filterCheckIn(): string {
		return this._filterCheckIn;
	}
	public set filterCheckIn(v: string) {
		this._filterCheckIn = v;
		console.log(v);
		this.filterCheckInChildsList();
	}
	filterCheckInChildsList() {
		if (this.filterCheckIn.length === 0) {
			this.filteredCheckedInChildsList = this.allCheckedInChildsList;
		} else {
			this.filteredCheckedInChildsList = this.allCheckedInChildsList.filter(a =>
				a.FirstName.startsWith(this.filterCheckIn)
			);
		}
		// clone array to prevent adding to the original array
		this.filteredCheckedInChildsList  = Object.assign([], this.filteredCheckedInChildsList);
		this.filteredCheckedInChildsList.push(new ChildModel());
	}
	filterCheckOutChildsList() {
		if (this.filterCheckOut.length === 0) {
			this.filteredCheckedOutChildsList = this.allCheckedOutChildsList;
		} else {
			console.log(this.allCheckedOutChildsList);
			this.filteredCheckedOutChildsList = this.allCheckedOutChildsList.filter(a =>
				a.FirstName.startsWith(this.filterCheckOut)
			);
		}
		// clone array to prevent adding to the original array
		this.filteredCheckedOutChildsList  = Object.assign([], this.filteredCheckedOutChildsList);
		this.filteredCheckedOutChildsList.push(new ChildModel());
	}
	async ngOnInit() {
		this.branches =  await this.async(this.branchesRepository.getAll());
		this.refresh();
	}
	async refresh() {
		this.allCheckedInChildsList = await this.async(
			this.childsRepository.getCheckedInChilds(null)
		);
		this.allCheckedOutChildsList = await this.async(
			this.childsRepository.getCheckedOutChilds(null)
		);
		console.log(this.allCheckedInChildsList);
		console.log(this.allCheckedOutChildsList);
		this.filterCheckOutChildsList();
		this.filterCheckInChildsList();


		this.refreshView();
	}

	async drop(event: CdkDragDrop<any>) {
		if (event.previousContainer === event.container) {
			// moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {
			console.log(event.previousContainer.data);
			console.log(event.container.data);

			const childId = event.previousContainer.data[event.previousIndex].Id;
			if (!childId) {
				return;
			}
			const newEvent = new DayEventModel();
			newEvent.ChildId = childId;
			if (event.container.id === 'checkoutChilds') {
				newEvent.EventTypeId = 2;
				await this.async(this.childsRepository.newEvent(newEvent));
			} else {
				newEvent.EventTypeId = 1;
				await this.async(this.childsRepository.newEvent(newEvent));
			}

			this.refresh();
		}
	}
}
