import { Component, OnInit, ViewChild } from '@angular/core';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import { DataGridHelper } from '../../grid.helper';
import { ChildsRepository } from '../../../../../data/repositories/childs.repository';
import { MatDialog, MatSnackBar, MatSort, MatTable } from '@angular/material';
import { LayoutUtilsService, MessageType } from '../../../../layout/utils/layout-utils.service';
import { ChildModel } from '../../../../../models/child.model';
import { ChildsDetailsComponent } from '../childs-details/childs-details.component';
import { QueryParamsModel } from '../../../../../models/query-models/query-params.model';
import { Observable, of } from 'rxjs';
import { QueryResultsModel } from '../../../../../models/query-models/query-results.model';
import { mergeMap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import {default as distance} from 'date-fns/distance_in_words_to_now';
import {default as arLocale} from 'date-fns/locale/ar';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { analyzeAndValidateNgModules } from '@angular/compiler';

@Component({
	selector: 'm-childs-all',
	templateUrl: './childs-all.component.html',
	styleUrls: ['./childs-all.component.scss'],
	providers: [ChildsRepository]
})
export class ChildsAllComponent extends DataGridHelper implements OnInit {
	displayedColumns = [
		'ChildId',
		'FirstName',
		'Picture',
		'Client.FullName',
		'BirthDate',
		'Gender',
		'IdentityNumber',
		// 'Supervisor',
		'Actions'
	];
	@ViewChild(MatSort) sort: MatSort;
	@ViewChild(MatTable) table;
  constructor(private subheaderService: SubheaderService,
    public childsRepository: ChildsRepository,
    public dialog: MatDialog,
		public snackBar: MatSnackBar,
		public spinner: NgxSpinnerService,
		public layoutUtilsService: LayoutUtilsService,
		public authService: AuthenticationService
		) {
		super(spinner, layoutUtilsService, authService);
    this.dataGridSource = childsRepository;
		this.subheaderService.setBreadcrumbs([
			{ title: ' الأطفال' },
			{ title: 'كل الأطفال', page: '/crm/childs/all' }
		]);
	}
	sortingDataAccessor(item, property) {
    if (property.includes('.')) {
      return property.split('.')
        .reduce((object, key) => object[key], item);
	}

    return item[property];
	}

	find(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		return this.childsRepository.getAll().pipe(
		  mergeMap(res => {
			const result = this.baseFilter(res, queryParams);
			return of(result);
		  })
		);
		}
	ngOnInit() {
		this.initializeDataGrid();
		this.loadList();
		this.table.sortingDataAccessor = this.sortingDataAccessor;
  }
  filterConfiguration(): any {
    const filter: any = {};
    const searchText: string = this.searchInput.nativeElement.value;
    filter.FirstName = searchText;
    filter.Supervisor = searchText;
	filter.ClientName = searchText;
	filter.IdentityNumber = searchText;

    return filter;
  }

addChild() {
	const c = new ChildModel();
	this.editChild(c);
}
  editChild(child: ChildModel) {
		const _saveMessage = 'تم الحفظ بنجاح';
		const dialogRef = this.dialog.open(ChildsDetailsComponent, { data: { child } });
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}

			this.layoutUtilsService.showActionNotification(_saveMessage, MessageType.Success, 10000, true, false);
			this.loadList();
		});
	}

		public calculateAge(date: Date): string {
		// 	console.log(date);
			const age = distance(date, {
				locale: arLocale
			});
			return age;
	}
	deleteChild(child: ChildModel) {
if (!child) { return; }
		// tslint:disable-next-line:max-line-length
		const dialogRef = this.layoutUtilsService.showConfirmationDialog('حذف طفل', `هل أنت متأكد من أنك تريد حذف الطفل ${child.FirstName + ' ' + child.LastName}؟`);
		dialogRef.afterClosed().subscribe(async res => {
			if (!res) {
				return;
			}

			var hasSubscribtions = await this.async( this.childsRepository.checkChildSubscribtions(child.Id));

			if(hasSubscribtions){
				const dialogRef2 = this.layoutUtilsService.showConfirmationDialog('حذف طفل', `الطفل يوجد لديه اشتراكات مسجلة هل تريد الحذف؟ ${child.FirstName + ' ' + child.LastName}`);
				dialogRef2.afterClosed().subscribe(async res => {
					if (!res) {
						return;
					}
					await this.excuteDeleteChild(child.Id);

				});
			}
			else{
				await this.excuteDeleteChild(child.Id);
			}			 
		});

	}


async excuteDeleteChild(childId){
		await this.async(
			this.childsRepository.deleteChild(childId)
		);
		if (this.hasErrors) { return; }
		this.layoutUtilsService.showActionNotification('تم الحذف بنجاح', MessageType.Success, 10000, true, false);
		this.loadList();
	}
}
