import { Component, OnInit } from '@angular/core';
import { DataGridHelper } from '../../../crm/grid.helper';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import { MatDialog } from '@angular/material';
import {
  LayoutUtilsService,
  MessageType
} from '../../../../layout/utils/layout-utils.service';
import { Observable, of } from 'rxjs';
import { QueryParamsModel } from '../../../../../models/query-models/query-params.model';
import { QueryResultsModel } from '../../../../../models/query-models/query-results.model';
import { mergeMap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { SettingsRepository } from '../../../../../data/repositories/settings.repository';
import { EventType } from '../../../../../models/settings.model';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { EventsTypesDetailsComponent } from '../../daily-events/events-details/events-details.component';
import { AgeCategoryModel } from '../../../../../models/age-category.model';
import { AgeCategoriesDetailsComponent } from '../age-categories-details/age-categories-details.component';
import { ChildsRepository } from '../../../../../data/repositories/childs.repository';
@Component({
  templateUrl: './age-categories-all.component.html',
  styleUrls: ['./age-categories-all.component.scss'],
  providers: [ChildsRepository]
})
export class AgeCategoriesAllComponent extends DataGridHelper implements OnInit {
  displayedColumns = [
    'CategoryId',
    'DName1',
    'DName2',
    'AgeFrom',
    'AgeTo',
    'Color',
    'Actions'
  ];
  constructor(
    public dialog: MatDialog,
    private subheaderService: SubheaderService,
    public childsRepository: ChildsRepository,
    public spinner: NgxSpinnerService,
    public layoutUtilsService: LayoutUtilsService,
    public authService: AuthenticationService
  ) {
    super(spinner, layoutUtilsService, authService);
    this.dataGridSource = childsRepository;
  }

  find(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    return this.childsRepository.getCategories().pipe(
      mergeMap(res => {
        const result = this.baseFilter(res, queryParams);
        return of(result);
      })
    );
  }
  ngOnInit() {
    this.initializeDataGrid();
    this.loadList();
    this.subheaderService.setBreadcrumbs([
      { title: 'تصنيفات الأعمار' },
      { title: 'كل تصنيفات الأعمار', page: '/settings/ageCategories/all' }
    ]);
  }

  /** FILTRATION */
  filterConfiguration(): any {
    const filter: any = {};
    const searchText: string = this.searchInput.nativeElement.value;
    filter.DName1 = searchText;
    filter.DName2 = searchText;
    return filter;
  }

  addCategory() {
    const category = new AgeCategoryModel();
    this.editCategory(category);
  }
  editCategory(category: AgeCategoryModel) {
    const dialogRef = this.dialog.open(AgeCategoriesDetailsComponent, {
      data: { category }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.saveCompleted();
      this.loadList();
    });
  }

  async deleteCategory(category: AgeCategoryModel) {
    const dialogRef = this.layoutUtilsService.showConfirmationDialog('حذف تصنيف', `هل أنت متأكد من أنك تريد حذف ${category.DName1}؟`);
    dialogRef.afterClosed().subscribe(async res => {
      if (!res) {
        return;
      }
      await this.async(this.childsRepository.deleteCategory(category.Id));
      if (this.hasErrors) { return; }
      this.saveCompleted();
      this.loadList();

    });
  }
	saveCompleted() {
			this.layoutUtilsService.showActionNotification(
				'تم الحفظ بنجاح',
				MessageType.Success,
				4000,
				true,
				false
      );
      }
}
