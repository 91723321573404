import { Observable, of, Subscription } from 'rxjs';
import { finalize, tap, catchError, mergeMap } from 'rxjs/operators';
import { BaseRepository } from './_base.repository';
import { QueryParamsModel } from '../../models/query-models/query-params.model';
import { SubscriptionsContract } from '../contracts/subscriptions.contract';
import { QueryResultsModel } from '../../models/query-models/query-results.model';
import { Injectable } from '@angular/core';
import { BaseContract } from '../contracts/base.contract';
import { SubscriptionModel, CancelSubscriptionModel } from '../../models/subscription.model';
import { DayEventModel } from '../../models/dayevent.model';
import { SubscriptionDayStateModel } from '../../models/subscription-state.model';
@Injectable({
	providedIn: 'root'
})
export class SubscriptionsRepository extends BaseRepository {




	constructor(private provider: SubscriptionsContract) {
		super();
		this.dataProvider = provider;
	}

	get(id: number): Observable<SubscriptionModel> {
		return this.provider.get(id);
	}
	getStates(): Observable<any> {
		return this.provider.getStates();
	}
	getAll(
		branchId?: number,
		stateId?: number,
		subscriptionId?: number,
		clientId?: number,
		childId?: number,
		packageId?: number,
		startDateFrom?: string,
		startDateTo?: string,
		endDateFrom?: string,
		endDateTo?: string,
		createdDateFrom?: string,
		createdDateTo?: string) {
		return this.provider.getAll(branchId,stateId,subscriptionId,clientId,
			childId,packageId,startDateFrom,startDateTo,endDateFrom,endDateTo,createdDateFrom,createdDateTo);
	}
	getdayVisits(
		subscriptionId?: number,
		branchId?: number,
		subscriptionState?: number,
		dayStateId?: number,
		clientId?: number,
		childId?: number,
		packageId?: number,
		dateFrom?: string,
		dateTo?: string,
		eventTypeId?: number) {
			return this.provider.getdayVisits(subscriptionId,branchId,subscriptionState
				,dayStateId,clientId,childId,packageId,dateFrom,dateTo,eventTypeId);
	}

	getQurrah(branchId?: number,
		stateId?: number,
		subscriptionId?: number,
		clientId?: number,
		childId?: number,
		packageId?: number,
		startDateFrom?: string,
		startDateTo?: string,
		endDateFrom?: string,
		endDateTo?: string,
		createdDateFrom?: string,
		createdDateTo?: string) {
		return this.provider.getQurrah(branchId,stateId,subscriptionId,clientId
			,childId,packageId,startDateFrom,startDateTo,endDateFrom,endDateTo,createdDateFrom,createdDateTo);
	}
	getActive(branchId?: number) {
		return this.provider.getSubscriptionsList(branchId, 4);
	}
	insert(subscription: SubscriptionModel): Observable<any> {
		return this.provider.insert(subscription);
	}
	addSubscription(
		subscription: SubscriptionModel,
		updateState: boolean
	): Observable<number> {
		return this.provider.addSubscription(subscription, updateState);
	}
	updatePaymentDetails(
		subscriptionId: number,
		paymentMethodId: number,
		discountAmount: number,
		qurrahFundAmount: number,
		withUpdateState: boolean
	): Observable<number> {
		return this.provider.updatePaymentDetails(
			subscriptionId,
			paymentMethodId,
			discountAmount,
			qurrahFundAmount,
			withUpdateState
		);
	}
	updateSubscription(
		subscription: SubscriptionModel,
		updateState: boolean
	): Observable<number> {
		return this.provider.updateSubscription(subscription, updateState);
	}
	getSubscriptionDays(id: number): Observable<any> {
		return this.provider.getSubscriptionDays(id);
	}
	downloadReceiptVoucher(subscriptionId: number): Observable<any> {
		return this.provider.downloadReceiptVoucher(subscriptionId);
	}
	getDayEvents(subscriptionDayId: number): Observable<DayEventModel[]> {
		return this.provider.getDayEvents(subscriptionDayId);
	}
	getDayStatuses(): Observable<SubscriptionDayStateModel[]> {
		return this.provider.getDayStatuses();
	}
	deleteSubscription(Id: number): Observable<any> {
		return this.provider.deleteSubscription(Id);
	}
	cancelSubscription(cancelSubscriptionModel: CancelSubscriptionModel): Observable<any> {
		return this.provider.cancelSubscription(cancelSubscriptionModel);
	}
	renewSubscription(Id: number): Observable<any> {
		return this.provider.renewSubscription(Id);
	}
}
