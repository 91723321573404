import { Injectable } from '@angular/core';
import { SubscriptionsContract } from '../../contracts/subscriptions.contract';
import { SubscriptionModel, CancelSubscriptionModel } from '../../../models/subscription.model';
import { Observable } from 'rxjs';
import { Api } from './api';
import { HttpHeaders } from '@angular/common/http';
import { IApiOptions } from './contracts';
import { DayEventModel } from '../../../models/dayevent.model';
import { SubscriptionDayStateModel } from '../../../models/subscription-state.model';

@Injectable({
	providedIn: 'root'
})
export class SubscriptionsServiceDataProvider implements SubscriptionsContract {

	getdayVisits(
		subscriptionId?: number,
		branchId?: number,
		subscriptionStateId?: number,
		dayStateId?: number,
		clientId?: number,
		childId?: number,
		packageId?: number,
		dateFrom?: string,
		dateTo?: string,
		eventTypeId?: number): Observable<any[]> {

		let params: string = '';
		if (subscriptionId) { params += 'subscriptionId=' + subscriptionId; }
		if (branchId) { if (params !== '') { params += '&'; } params += 'branchId=' + branchId; }
		if (subscriptionStateId) { if (params !== '') { params += '&'; } params += 'subscriptionStateId=' + subscriptionStateId; }
		if (dayStateId) { if (params !== '') { params += '&'; } params += 'dayStateId=' + dayStateId; }
		if (clientId) { if (params !== '') { params += '&'; } params += 'clientId=' + clientId; }
		if (childId) { if (params !== '') { params += '&'; } params += 'childId=' + childId; }
		if (packageId) { if (params !== '') { params += '&'; } params += 'packageId=' + packageId; }
		if (dateFrom) { if (params !== '') { params += '&'; } params += 'dateFrom=' + dateFrom; }
		if (dateTo) { if (params !== '') { params += '&'; } params += 'dateTo=' + dateTo; }
		if (eventTypeId) { if (params !== '') { params += '&'; } params += 'eventTypeId=' + eventTypeId; }
		return this.api.get('DayEvents/GetDaysVisits?' + params);


	}
	getStates(): Observable<any> {
		return this.api.get('Subscriptions/GetSubscriptionsStates');
	}
	downloadReceiptVoucher(subscriptionId: number): Observable<any> {
		const options: IApiOptions = {
			appendBaseUrl: true,
			handleError: true,
			handleLoading: true,
			headers: new HttpHeaders().set('Content-Type', 'application/octet-stream')
		};
		return this.api.get('Subscriptions/DownloadRecddeiptVoucher?subscriptionId=' + subscriptionId, options);
	}
	getSubscriptionDays(subscriptionId: number): Observable<any[]> {
		return this.api.get('Subscriptions/GetSubscriptionDays?subscriptionId=' + subscriptionId);
	}

	addSubscription(
		subscription: SubscriptionModel,
		updateState: boolean
	): Observable<number> {
		return this.api.post(
			`Subscriptions/Register?withUpdateState=${updateState}`,
			subscription
		);
	}
	updatePaymentDetails(
		subscriptionId: number,
		paymentMethodId: number,
		discountAmount: number, qurrahFundAmount: number,
		withUpdateState: boolean
	): Observable<number> {
		return this.api.post(
			// tslint:disable-next-line: max-line-length
			`Subscriptions/UpdatePaymentDetails?subscriptionId=${subscriptionId}&paymentMethodId=${paymentMethodId}&discountAmount=${discountAmount}&qurrahFundAmount=${qurrahFundAmount}&withUpdateState=${withUpdateState}`
		);
	}
	insert(): Observable<SubscriptionModel> {
		return;
	}
	get(id: number): Observable<SubscriptionModel> {
		return this.api.get('Subscriptions/Get?subscriptionId=' + id);
	}

	getAll(branchId?: number,
		stateId?: number,
		subscriptionId?: number,
		clientId?: number,
		childId?: number,
		packageId?: number,
		startDateFrom?: string,
		startDateTo?: string,
		endDateFrom?: string,
		endDateTo?: string,
		createdDateFrom?: string,
		createdDateTo?: string): Observable<SubscriptionModel[]> {
		let params: string = '';
		if (branchId) { params += 'branchId=' + branchId; }
		if (stateId) { if (params !== '') { params += '&'; } params += 'stateId=' + stateId; }
		if (subscriptionId) { if (params !== '') { params += '&'; } params += 'subscriptionId=' + subscriptionId; }
		if (clientId) { if (params !== '') { params += '&'; } params += 'clientId=' + clientId; }
		if (childId) { if (params !== '') { params += '&'; } params += 'childId=' + childId; }
		if (packageId) { if (params !== '') { params += '&'; } params += 'packageId=' + packageId; }
		if (startDateFrom) { if (params !== '') { params += '&'; } params += 'startDateFrom=' + startDateFrom; }
		if (startDateTo) { if (params !== '') { params += '&'; } params += 'startDateTo=' + startDateTo; }
		if (endDateFrom) { if (params !== '') { params += '&'; } params += 'endDateFrom=' + endDateFrom; }
		if (endDateTo) { params += 'endDateTo=' + endDateTo; }
		if (createdDateFrom) { if (params !== '') { params += '&'; } params += 'createdDateFrom=' + createdDateFrom; }
		if (createdDateTo) { if (params !== '') { params += '&'; } params += 'createdDateTo=' + createdDateTo; }
		return this.api.get('Subscriptions/GetAll?' + params);
	}

	getQurrah(branchId?: number,
		stateId?: number,
		subscriptionId?: number,
		clientId?: number,
		childId?: number,
		packageId?: number,
		startDateFrom?: string,
		startDateTo?: string,
		endDateFrom?: string,
		endDateTo?: string,
		createdDateFrom?: string,
		createdDateTo?: string): Observable<SubscriptionModel[]> {
		let params: string = '';
		if (branchId) { params += 'branchId=' + branchId; }
		if (stateId) { if (params !== '') { params += '&'; } params += 'stateId=' + stateId; }
		if (subscriptionId) { if (params !== '') { params += '&'; } params += 'subscriptionId=' + subscriptionId; }
		if (clientId) { if (params !== '') { params += '&'; } params += 'clientId=' + clientId; }
		if (childId) { if (params !== '') { params += '&'; } params += 'childId=' + childId; }
		if (packageId) { if (params !== '') { params += '&'; } params += 'packageId=' + packageId; }
		if (startDateFrom) { if (params !== '') { params += '&'; } params += 'startDateFrom=' + startDateFrom; }
		if (startDateTo) { if (params !== '') { params += '&'; } params += 'startDateTo=' + startDateTo; }
		if (endDateFrom) { if (params !== '') { params += '&'; } params += 'endDateFrom=' + endDateFrom; }
		if (endDateTo) { params += 'endDateTo=' + endDateTo; }
		if (createdDateFrom) { if (params !== '') { params += '&'; } params += 'createdDateFrom=' + createdDateFrom; }
		if (createdDateTo) { if (params !== '') { params += '&'; } params += 'createdDateTo=' + createdDateTo; }
		return this.api.get('Subscriptions/GetQurrahSubscriptions?' + params);
	}


	delete(id: number): Observable<any> {
		throw new Error('Method not implemented.');
	}
	update(entity: SubscriptionModel): Observable<any> {
		throw new Error('Method not implemented.');
	}
	constructor(private api: Api) { }
	getSubscriptionsList(branchId: number, stateId: number): Observable<any[]> {
		console.log('service called');
		let params: string = '';
		if (branchId) { params += 'branchId=' + branchId + '&'; }
		if (stateId) { params += 'stateId=' + stateId; }
		console.log(params);
		return this.api.get('Subscriptions/GetAll?' + params);
	}
	updateSubscription(subscription: SubscriptionModel, updateState: boolean): Observable<number> {
		return this.api.post(`Subscriptions/Update?withUpdateState=${updateState}`, subscription);
	}
	getDayEvents(subscriptionDayId: number): Observable<DayEventModel[]> {
		return this.api.get(`DayEvents/Get?subscriptionDayId=${subscriptionDayId}`);
	}
	getDayStatuses(): Observable<SubscriptionDayStateModel[]> {
		return this.api.get('DayEvents/GetDayStates');
	}
	cancelSubscription(cancelSubscriptionModel: CancelSubscriptionModel): Observable<any> {
		return this.api.post(`Subscriptions/CancelSubscription`, cancelSubscriptionModel);
	}
	deleteSubscription(subscriptionId: number): Observable<any> {
		return this.api.delete(`Subscriptions/DeleteSubscription?subscriptionId=${subscriptionId}`);
	}
	renewSubscription(subscriptionId: number): Observable<any> {
		return this.api.post(`Subscriptions/RenewSubscription?subscriptionId=${subscriptionId}`);
	}
}
