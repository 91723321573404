import { Injectable } from "@angular/core";
import { BaseRepository } from "./_base.repository";
import { Observable } from "rxjs";
import { BranchesProviderContract } from "../contracts/branches.contract";
import { BranchModel } from "../../models/branch.model";

@Injectable({
	providedIn: "root"
})
export class BranchesRepository extends BaseRepository {
	constructor(private provider: BranchesProviderContract) {
		super();
		this.dataProvider = provider;
	}
	getAll() {
		return this.provider.getAll();
	}
	update(branch: BranchModel): Observable<any> {
		return this.provider.update(branch);
	}

	insert(branch: BranchModel): Observable<any> {
		return this.provider.insert(branch);
	}
	getClientBranches(clientId: number): Observable<BranchModel[]> {
		return this.provider.getClientBranches(clientId);
	}
	getPrivateBranches(): Observable<BranchModel[]> {
		return this.provider.getPrivateBranches();
	}
	getBranchWorkDays(branchId: number): Observable<any> {
		return this.provider.getBranchWorkDays(branchId);
	}
	removeWorkDayFromBranch(branchId: number, dayId: any): Observable<any> {
		return this.provider.removeWorkDayFromBranch(branchId, dayId);
	}
	getWeekdays(): Observable<any> {
		return this.provider.getWeekdays();
	}
	addWorkDayToBranch(branchId: number, dayId: any): Observable<any> {
		return this.provider.addWorkDayToBranch(branchId, dayId);
	}

}
