import { BaseModel } from './_base.model';
import { EventType } from './settings.model';

export class DayEventModel extends BaseModel {
    ChildId: number;
    EventTypeId: number;
    Time: string;
    Notes: string;
    Date: string;
    Emoji: string;
    DayEventType: EventType;
    DayEventDetails: DayEventDetailsModel;
}
export class DayEventDetailsModel extends BaseModel {
    DayEventId?: number;
    WasteTypeId?: number;
    Temperature?: number;
    EatingAmount?: number;
    DrinkAmount?: number;
}

