import { Injectable } from '@angular/core';
import { Api } from './api';
import { Observable } from 'rxjs';
import { EmployeesProviderContract } from '../../contracts/employees.contract';
import { EmployeeModel } from '../../../models/employee.model';

@Injectable({
  providedIn: 'root'
})
export class EmployeesServiceDataProvider implements EmployeesProviderContract  {
  insert(entity: EmployeeModel): Observable<any> {
    return this.api.post('Employees/Register', entity);
  }
  get(id: number): Observable<EmployeeModel> {
    return this.api.get(`Employees/Get?EmployeeId=${id}`);
  }
  getAll(): Observable<EmployeeModel[]> {
    return this.api.get('Employees/GetAll');
  }
  delete(id: number): Observable<any>  {
    return this.api.delete(`Employees/Delete?EmployeeId=${id}`);
  }
  update(entity: EmployeeModel): Observable<any> {
   return this.api.post('Employees/Update', entity);
  }


  constructor(private api: Api) { }

}
