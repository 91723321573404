import { BaseModel } from "./_base.model";
import { Role, EmployeeModel } from "./employee.model";
import { ChildModel } from './child.model';
import { BranchModel } from './branch.model';

export class SupervisorModel extends BaseModel {
	Employee: EmployeeModel = new EmployeeModel();
	BranchId: number;
	Branch: BranchModel;
	Childs: ChildModel[];
	// local
	selected: boolean;
}
export class User {
	Id: string;
	FirstName: string;
	MidName: string;
	LastName: string;
	Email: string;
	Gender: string;
	PhoneNumber: string;
	IdentityNumber: string;
	Language: string;
	UserName: string;
	IsLockoutEnabled: boolean;
	LockoutEnabled: boolean;
	CreatedBy: string;
	CreatedAt: string;
	ProfilePicturePath: string;
}
