import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import { LayoutUtilsService, MessageType } from '../../../../layout/utils/layout-utils.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AttachmentsTypesModel, EntityType, Emoji } from '../../../../../models/settings.model';
import { SettingsRepository } from '../../../../../data/repositories/settings.repository';
import { BaseComponent } from '../../../../../core/base/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { TypesUtilsService } from '../../../../layout/utils/types-utils.service';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';

@Component({
  templateUrl: './emojis-details.component.html',
  styleUrls: ['./emojis-details.component.scss'],
  providers: [SettingsRepository]
})
export class EmojisDetailsComponent extends BaseComponent implements OnInit {
	loadingSubject = new BehaviorSubject<boolean>(false);
	loading$ = this.loadingSubject.asObservable();
	isNewOrEdit: boolean;
	emojiForm: FormGroup;
	emoji: Emoji = new Emoji();
	withClose: boolean;
	hasFormErrors: boolean = false;
	viewLoading: boolean = false;
	loadingAfterSubmit: boolean = false;
    entityTypes: EntityType[] = [];
	errorMessage: string = '';
  constructor(
    private typeFB: FormBuilder,
		public subheaderService: SubheaderService,
		public settingsRepository: SettingsRepository,
		public layoutUtilsService: LayoutUtilsService,
		public ref: ChangeDetectorRef,
		public spinner: NgxSpinnerService,
		public typesUtilsService: TypesUtilsService,

		public authService: AuthenticationService,
		public dialogRef: MatDialogRef<EmojisDetailsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
  ) {
	super(
		subheaderService,
		layoutUtilsService,
		ref,
		spinner,
		typesUtilsService,
		authService
	);
	this.createForm();
  }

  async	ngOnInit() {
		this.loadingSubject.next(true);
		this.emoji = this.data.emoji;
		this.entityTypes = await this.async(
			this.settingsRepository.getEntityTypes()
		);
		this.createForm();
		this.loadingSubject.next(false);
	}
	createForm() {
		this.emojiForm = this.typeFB.group({
	    emojiNameAr: [this.emoji.DName1, Validators.required],
      emojiNameEn: [this.emoji.DName2, Validators.required],
			content: [this.emoji.Content, Validators.required]
		});
	}

	isControlInvalid(controlName: string): boolean {
		const control = this.emojiForm.controls[controlName];
		const result = control.invalid && control.touched;
		return result;
	}

	/** ACTIONS */
	prepareEmoji(): Emoji {
		const controls = this.emojiForm.controls;
		const emoji = new Emoji();
		emoji.DName1 = controls['emojiNameAr'].value;
		emoji.DName2 = controls['emojiNameEn'].value;
		emoji.Content = controls['content'].value;
		return emoji;
	}

	onSubmit(withClose: boolean = false) {
		this.withClose = withClose;
		this.hasFormErrors = false;
		this.errorMessage = '';
		this.loadingAfterSubmit = false;
		const controls = this.emojiForm.controls;
		/** check form */
		if (this.emojiForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			return;
		}

		const editedEmoji = this.prepareEmoji();
		console.log(editedEmoji);
			this.createEmoji(editedEmoji);
		}

	saveCompleted() {
		if (!this.withClose) {
			this.layoutUtilsService.showActionNotification(
				'تم الحفظ بنجاح',
				MessageType.Success,
				4000,
				true,
				false
			);
			return;
		}
		this.dialogRef.close({
			isEdit: true
		});
	}
	async createEmoji(emoji: Emoji) {
		await this.async(this.settingsRepository.addEmoji(emoji));
		if (this.hasErrors) { return; }
		this.saveCompleted();
	}

	onAlertClose($event) {
		this.hasFormErrors = false;
	}
}
