import { Injectable } from '@angular/core';
import { Api } from './api';
import { Observable } from 'rxjs';
import { DashboardProviderContract } from '../../contracts/dashboard.contract';
import { SubscriptionsDashboardModel } from '../../../models/dashboard.model';

@Injectable({
	providedIn: 'root'
})
export class DashboardServiceDataProvider implements DashboardProviderContract {
    getSubscriptionsSummary(): Observable<SubscriptionsDashboardModel> {
        return this.api.get('Dashboard/SubscriptionsSummary');
    }
    getsubscriptionsVisits(): Observable<any> {
        return this.api.get('Dashboard/SubscriptionsVisitsInCurrentMonth');
    }

    
    getFinancialStatus(): Observable<any> {
        return this.api.get('Dashboard/FinancialStatus');
    }
    getExpensesPerTypes(): Observable<any> {
        return this.api.get('Dashboard/ExpensesPerTypes');
    }

    getSubscriptionsPerPackages(): Observable<any> {
        return this.api.get('Dashboard/SubscriptionsPerPackages');
    }
    get(id: number): Observable<any> {
        throw new Error('Method not implemented.');
    }
    getAll(): Observable<any[]> {
        throw new Error('Method not implemented.');
    }
    delete(id: number): Observable<any> {
        throw new Error('Method not implemented.');
    }
    update(entity: any): Observable<any> {
        throw new Error('Method not implemented.');
    }
    insert(entity: any): Observable<any> {
        throw new Error('Method not implemented.');
    }

	constructor(private api: Api) {}
}
