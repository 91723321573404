import { Injectable } from "@angular/core";
import { BaseRepository } from "./_base.repository";
import { Observable } from "rxjs";
import { PackagesProviderContract } from '../contracts/packages.contract';
import { PackageModel, Service } from '../../models/package.model';

@Injectable({
	providedIn: "root"
})
export class PackagesRepository extends BaseRepository {
	constructor(private provider:PackagesProviderContract) {
		super();
		this.dataProvider = provider;
	}
	getAll() {
		return this.provider.getAll();
	}
	update(packageModel: PackageModel): Observable<any> {
		return this.provider.update(packageModel);
	}

	insert(packageModel: PackageModel): Observable<any> {
		return this.provider.insert(packageModel);
	}
	getServices():Observable<Service[]>{
		return this.provider.getServices();
	}
	getChildPackages(branchId: number, childId: number): Observable<Service[]> {
		return this.provider.getChildPackages(branchId, childId);
	}
}
