import { Injectable } from '@angular/core';
import { BaseRepository } from './_base.repository';
import { Observable } from 'rxjs';
import { EmployeesProviderContract } from '../contracts/employees.contract';
import { EmployeeModel } from '../../models/employee.model';

@Injectable({
	providedIn: 'root'
})
export class EmployeesRepository extends BaseRepository {
	constructor(private provider: EmployeesProviderContract) {
		super();
		this.dataProvider = provider;
	}
	getAll() {
		return this.provider.getAll();
	}
	update(employee: EmployeeModel): Observable<any> {
		return this.provider.update(employee);
	}

	insert(employee: EmployeeModel): Observable<any> {
		return this.provider.insert(employee);
	}
	get(Id: number): Observable<EmployeeModel> {
		return this.provider.get(Id);
	}
	delete(Id: number): Observable<EmployeeModel> {
		return this.provider.delete(Id);
	}
}
