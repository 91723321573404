import { BaseModel } from "./_base.model";

export class SubscriptionsDashboardModel extends BaseModel {
	ActiveSubscriptions: number;
	ClientsCount: number;
	ChildsCount: number;
	EndingSubscriptions: number;
}

export class SubscriptionsPerPackages {
	Name: string;
	Value: number;
}
export class BarChartItemModel extends BaseModel {
	Name: string;
	Value1: number;
	Value2: number;
}
export class PieChartItemModel {
	Name: string;
	Value: number;
}