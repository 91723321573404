import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import { LayoutUtilsService, MessageType } from '../../../../layout/utils/layout-utils.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SettingsRepository } from '../../../../../data/repositories/settings.repository';
import { BaseComponent } from '../../../../../core/base/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { TypesUtilsService } from '../../../../layout/utils/types-utils.service';
import { EventType } from '../../../../../models/settings.model';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';

@Component({
  templateUrl: './events-details.component.html',
  styleUrls: ['./events-details.component.scss'],
  providers: [SettingsRepository]
})
export class EventsTypesDetailsComponent extends BaseComponent implements OnInit {
	loadingSubject = new BehaviorSubject<boolean>(false);
	loading$ = this.loadingSubject.asObservable();
	isNewOrEdit: boolean;
	eventTypeForm: FormGroup;
	eventType: EventType = new EventType();
	withClose: boolean;
	hasFormErrors: boolean = false;
	viewLoading: boolean = false;
	loadingAfterSubmit: boolean = false;
	errorMessage: string = '';
  constructor(
    private typeFB: FormBuilder,
		public subheaderService: SubheaderService,
		public settingsRepository: SettingsRepository,
		public layoutUtilsService: LayoutUtilsService,
		public ref: ChangeDetectorRef,
		public spinner: NgxSpinnerService,
		public typesUtilsService: TypesUtilsService,
		public authService: AuthenticationService,
		public dialogRef: MatDialogRef<EventsTypesDetailsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
  ) {
	super(
		subheaderService,
		layoutUtilsService,
		ref,
		spinner,
		typesUtilsService,
		authService
	);
	this.createForm();

  }

  async	ngOnInit() {
		this.loadingSubject.next(true);
		console.log(this.data.type.DayEventTypeId);
		this.eventType.Color = '#ffffff';
		this.eventType.IsEmojiRequired = false;
		this.eventType.IsNotesRequired = false;
		this.eventType.IsVisibleToClient = false;
		if (this.data.type.DayEventTypeId) {

			this.eventType =  Object.assign([], this.data.type);

		}
		this.createForm();
		this.loadingSubject.next(false);
		this.refreshView();
	}
	createForm() {
		this.eventTypeForm = this.typeFB.group({
	    typeNameAr: [this.eventType.DName1, Validators.required],
      typeNameEn: [this.eventType.DName2, Validators.required],
			image: [this.eventType.Image, Validators.required],
			color: [this.eventType.Color, Validators.required],
			visible: [this.eventType.IsVisibleToClient, Validators.required],
			notes: [this.eventType.IsNotesRequired, Validators.required],
			emoji: [this.eventType.IsEmojiRequired, Validators.required]
		});
	}

	isControlInvalid(controlName: string): boolean {
		const control = this.eventTypeForm.controls[controlName];
		const result = control.invalid && control.touched;
		return result;
	}

	/** ACTIONS */
	prepareType(): EventType {
		const controls = this.eventTypeForm.controls;
		const type = new EventType();
		type.DayEventTypeId = this.eventType.DayEventTypeId;
		type.DName1 = controls['typeNameAr'].value;
		type.DName2 = controls['typeNameEn'].value;
		type.Image = controls['image'].value;
		type.Color = this.eventType.Color.slice(1);
		type.IsVisibleToClient = controls['visible'].value;
		type.IsNotesRequired = controls['notes'].value;
		type.IsEmojiRequired = controls['emoji'].value;
		type.IsVisibleToClient = !type.IsVisibleToClient ? false : type.IsVisibleToClient;
		type.IsNotesRequired = !type.IsNotesRequired ? false : type.IsNotesRequired;
		type.IsEmojiRequired = !type.IsEmojiRequired ? false : type.IsEmojiRequired;
		console.log(type);
		return type;
	}

	onSubmit(withClose: boolean = false) {
		this.withClose = withClose;
		this.hasFormErrors = false;
		this.errorMessage = '';
		this.loadingAfterSubmit = false;
		const controls = this.eventTypeForm.controls;
		/** check form */
		if (this.eventTypeForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			console.log('invalid');

			this.hasFormErrors = true;
			return;
		}

		const editedType = this.prepareType();
		console.log(editedType);
		const formData = new FormData();

		let file = null;
if (this.eventTypeForm.controls['image'].value.files){
	file = this.eventTypeForm.controls['image'].value
	.files[0] ;
	formData.append('myFile', file, file.name);

}
		console.log('file blob', file);
		formData.set('enctype', 'multipart/form-data');
		if (editedType.DayEventTypeId) {
			this.updateType(editedType, formData);
		} else {
			this.createType(editedType, formData);
		}
	}

	saveCompleted() {
		if (!this.withClose) {
			this.layoutUtilsService.showActionNotification(
				'تم الحفظ بنجاح',
				MessageType.Success,
				4000,
				true,
				false
			);
			return;
		}
		this.dialogRef.close({
			isEdit: true
		});
	}

async	updateType(type: EventType, formData: FormData) {

		await this.async(this.settingsRepository.updateEventType(type, formData));
		if (this.hasErrors) { return; }
		this.saveCompleted();
	}

	async createType(type: EventType, formData: FormData) {

		await this.async(this.settingsRepository.addEventType(type, formData));
		if (this.hasErrors) { return; }
		this.saveCompleted();
	}

	onAlertClose($event) {
		this.hasFormErrors = false;
	}
	getTitle(): string {
		if (this.eventType.DayEventTypeId) { return 'تعديل نوع الحدث'; } else { return 'إضافة نوع حدث'; }
	}
}
