import { BaseContract } from './base.contract';
import { PermissionModel } from '../../models/permission.model';
import { Observable } from 'rxjs';
import { Role } from '../../models/employee.model';
import { RoleModel } from '../../models/role.model';


export  abstract class PermissionsProviderContract extends BaseContract<PermissionModel>{
abstract getRoles(): Observable<RoleModel[]>;
abstract getRole(roleId?: number): Observable<RoleModel>;
abstract insertUpdateRole(role: RoleModel, permissions: string): Observable<any>;
abstract getUserRoles(userId: string): Observable<any>;
abstract resetUserPassword(Id: string, newPassword: string): Observable<any>;
abstract addRoleToUser(userId: string, roleId: number, branchId: number): Observable<any>;
abstract deleteRoleFromUser(userId: string, roleId: number, branchId: number): Observable<any>;
}
