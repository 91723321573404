import { Injectable } from "@angular/core";
import { BaseRepository } from "./_base.repository";
import { PermissionsProviderContract } from "../contracts/permissions.contract";
import { Observable } from "rxjs";
import { PermissionModel } from "../../models/permission.model";
import { Role } from "../../models/employee.model";
import { RoleModel } from '../../models/role.model';

@Injectable({
	providedIn: "root"
})
export class PermissionsRepository extends BaseRepository {

	constructor(private provider: PermissionsProviderContract) {
		super();
		this.dataProvider = provider;
	}

	getAll() {
		return this.provider.getAll();
	}

	insert(model: PermissionModel): Observable<any> {
		return this.provider.insert(model);
	}
	getRoles(): Observable<RoleModel[]> {
		return this.provider.getRoles();
	}
	getRole(roleId?: number): Observable<RoleModel> {
		return this.provider.getRole(roleId);
	}
	insertUpdateRole(role: RoleModel, permissions: string): Observable<any> {
		return this.provider.insertUpdateRole(role, permissions);
	}
	getUserRoles(userId: string) {
		return this.provider.getUserRoles(userId);
	}
	resetUserPassword(Id: string, newPassword: string) {
		return this.provider.resetUserPassword(Id, newPassword);
	}

	addRoleToUser(userId: string, roleId: number, branchId?: number): Observable<any> {
		return this.provider.addRoleToUser(userId, roleId, branchId);
	}
	deleteRoleFromUser(userId: string, roleId: number, branchId: number): Observable<any> {
		return this.provider.deleteRoleFromUser(userId, roleId, branchId);
	}
}
