import { Injectable } from '@angular/core';
import { BaseRepository } from './_base.repository';
import { Observable } from 'rxjs';
import { SettingsProviderContract } from '../contracts/settings.contract';
import { AttachmentsTypesModel, EntityType, EventType, Emoji } from '../../models/settings.model';

@Injectable({
	providedIn: 'root'
})
export class SettingsRepository extends BaseRepository {
	constructor(private provider: SettingsProviderContract) {
		super();
		this.dataProvider = provider;
	}
	getAllAttachmentsTypes(): Observable<AttachmentsTypesModel[]> {
		return this.provider.getAllAttachmentsTypes();
	}
	addAttachmentType(entity: AttachmentsTypesModel): Observable<any> {
		return this.provider.addAttachmentType(entity);
	}
	updateAttachmentType(entity: AttachmentsTypesModel): Observable<any> {
		return this.provider.updateAttachmentType(entity);
	}
	getEntityTypes(): Observable<EntityType[]> {
		return this.provider.getEntityTypes();
	}
	getAllEventsTypes(): Observable<EventType[]> {
		return this.provider.getAllEventsTypes();
	}
	addEventType(entity: EventType, formData: FormData): Observable<any> {
		return this.provider.addEventType(entity, formData);
	}
	updateEventType(entity: EventType, formData: FormData): Observable<any> {
		return this.provider.updateEventType(entity, formData);
	}
	getAllEmojis(): Observable<Emoji[]> {
		return this.provider.getAllEmojis();
	}
	addEmoji(entity: Emoji): Observable<any> {
		return this.provider.addEmoji(entity);
	}
	deleteEmoji(Id: number): Observable<any> {
		return this.provider.deleteEmoji(Id);
	}
}
