import { Injectable } from '@angular/core';
import { Api } from './api';
import { Observable, Subscription, of } from 'rxjs';
import { HttpUtilsService } from '../../../core/services/http-utils.service';
import { QueryParamsModel } from '../../../models/query-models/query-params.model';
import { QueryResultsModel } from '../../../models/query-models/query-results.model';
import { mergeMap } from 'rxjs/operators';
import { ClientModel } from '../../../models/client.model';
import { ClientsProviderContract } from '../../contracts/clients.contract';

@Injectable({
	providedIn: 'root'
})
export class ClientsServiceDataProvider implements ClientsProviderContract {
	getAllInPrivateBranches(): Observable<ClientModel[]> {
		return this.api.get('Clients/getAllInPrivateBranches');
	}
	insert(entity: ClientModel): Observable<any> {
		return this.api.post('Clients/Register?generateToken=false', entity);
	}
	get(id: number): Observable<ClientModel> {
		return this.api.get(`Clients/Get?clientId=${id}`);
	}
	getAll(): Observable<ClientModel[]> {
		return this.api.get('Clients/GetAll');
	}
	delete(id: number): Observable<any>  {
		throw new Error('Method not implemented.');
	}
	update(entity: ClientModel): Observable<any> {
		return this.api.post('Clients/Update', entity);
	}

	updateClientBranch(
		clientId: number,
		branchId?: number
	): Observable<ClientModel> {
		if (branchId == null) {
			return this.api.post(`Clients/UpdateClientBranch?clientId=${clientId}`);
		}

		return this.api.post(`Clients/UpdateClientBranch?clientId=${clientId}&branchId=${branchId}`);
	}
	constructor(private api: Api) {}
}
