import { BaseContract } from './base.contract';
import { AttachmentsTypesModel, EntityType, EventType, Emoji } from '../../models/settings.model';
import { Observable } from 'rxjs';


export  abstract class SettingsProviderContract extends BaseContract<AttachmentsTypesModel> {
abstract getAllAttachmentsTypes(): Observable<AttachmentsTypesModel[]>;
abstract addAttachmentType(entity: AttachmentsTypesModel): Observable<any>;
abstract updateAttachmentType(entity: AttachmentsTypesModel): Observable<any>;
abstract getEntityTypes(): Observable<EntityType[]>;
abstract getAllEventsTypes(): Observable<EventType[]>;
abstract addEventType(entity: EventType, formData: FormData): Observable<any>;
abstract updateEventType(entity: EventType, formData: FormData): Observable<any>;
abstract getAllEmojis(): Observable<Emoji[]>;
abstract addEmoji(entity: Emoji): Observable<any>;
abstract deleteEmoji(Id: number): Observable<any>;
}
