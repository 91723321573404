import { BaseContract } from './base.contract';

import { ClientModel } from '../../models/client.model';

import { Observable } from 'rxjs';
import { ChatModel } from '../../models/chat.model';
import { NewMessage, UserChats } from '../../models/models';

export  abstract class ChatsProviderContract extends BaseContract<ChatModel>{
    abstract getChats(): Observable<UserChats[]>;
    abstract getMessages(chatId: number, lastId?: number);
    abstract sendMessage(message: NewMessage): Observable<NewMessage>;
}
