import { Component, OnInit, Inject, ChangeDetectorRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { FeedModel } from "../../../../../../models/feed.model";
import { SubheaderService } from "../../../../../../core/services/layout/subheader.service";
import { MAT_DIALOG_DATA } from "@angular/material";
import { BaseComponent } from '../../../../../../core/base/base.component';
import { LayoutUtilsService, MessageType } from '../../../../../layout/utils/layout-utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TypesUtilsService } from '../../../../../layout/utils/types-utils.service';
import { AuthenticationService } from '../../../../../../core/auth/authentication.service';
import { FeedsRepository } from '../../../../../../data/repositories/feeds.repository';

@Component({
	selector: "m-feeds-details",
	templateUrl: "./feeds-details.component.html",
	styleUrls: ["./feeds-details.component.scss"],
	providers:[FeedsRepository]
})
export class FeedsDetailsComponent  extends BaseComponent implements OnInit {
	loadingSubject = new BehaviorSubject<boolean>(false);
	loading$ = this.loadingSubject.asObservable();
	isNewOrEdit: boolean;
	feedForm: FormGroup;
	feed: FeedModel = new FeedModel();
	feedId: number;
	withClose: boolean;
	loadingAfterSubmit: boolean = false;
	constructor(
		public subheaderService: SubheaderService,
		public layoutUtilsService: LayoutUtilsService,
		public ref: ChangeDetectorRef,
		public spinner: NgxSpinnerService,
		public formBuilder: FormBuilder,
		public typesUtilsService: TypesUtilsService,
		public authService: AuthenticationService,
		public feedsRepository: FeedsRepository,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		super(
			subheaderService,
			layoutUtilsService,
			ref,
			spinner,
			typesUtilsService,
			authService
		);
		this.feed.IsEnabled = false;
		 this.createForm();
	}

	async ngOnInit() {
		this.loadingSubject.next(true);
		this.feedId = this.data.feedId;
		await this.initialization();
		this.loadingSubject.next(false);
	}
	async initialization() {
		if (this.feedId) {
			this.isNewOrEdit = false;
			this.feed = await this.async(
				this.feedsRepository.get(this.feedId)
			);
			console.log('get', this.feed);

			this.feed.PublishedAt = new Date(this.feed.PublishedAt);
			this.createForm();

		}
		else {
			this.isNewOrEdit = true;
		}
	}
	onSubmit(withClose: boolean = false) {
		const controls = this.feedForm.controls;
		if (this.feedForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
		this.withClose = withClose;
		this.loadingAfterSubmit = false;
		const editedFeed = this.prepareFeed();
		if (editedFeed.FeedId > 0) {
			this.updateFeed(editedFeed);
		} else {
			this.createFeed(editedFeed);
		}
	}
	createForm() {
		this.feedForm = this.formBuilder.group({
			dName1: [this.feed.DName1, Validators.required],
			dName2: [this.feed.DName2, Validators.required],
			publishedAt: [this.feed.PublishedAt, Validators.required],
			isEnabled: [this.feed.IsEnabled, Validators.required],
		});
	}
	prepareFeed(): FeedModel {
		const controls = this.feedForm.controls;
		const _feed = new FeedModel();
		_feed.FeedId = this.feed.FeedId;
		const _date = controls['publishedAt'].value;
		if (_date) {
			_feed.PublishedAt = this.typesUtilsService.dateFormat(_date);
		} else {
			_feed.PublishedAt = '';
		}
		_feed.DName1 = controls['dName1'].value;
		_feed.DName2 = controls['dName2'].value;
		_feed.IsEnabled = controls['isEnabled'].value;
		console.log('prepare', _feed);

		return _feed;
	}
	async createFeed(feed: FeedModel) {
		console.log('create', feed);
		const id = await this.async(
			this.feedsRepository.addFeed(feed)
		);
		if (id) {
			this.saveCompleted();
			this.feedId = id;
			this.initialization();
		}
	}
	async updateFeed(feed: FeedModel) {
		console.log('update', feed);

		 await this.async(
			this.feedsRepository.updateFeed(feed)
		);
		if (!this.hasErrors) {
			this.saveCompleted();
			this.initialization();
		}
	}

	getTitle(): string {
		if (this.feed.FeedId) return "تعديل بيانات مشاركة";
		else return "إضافة مشاركة جديدة";
	}

	saveCompleted() {
		this.layoutUtilsService.showActionNotification(
			'تم الحفظ بنجاح',
			MessageType.Success,
			4000,
			true,
			false
		);
	}
}
