import { Injectable } from '@angular/core';
import { BaseRepository } from './_base.repository';
import { ClientsProviderContract } from '../contracts/clients.contract';
import { QueryParamsModel } from '../../models/query-models/query-params.model';
import { tap, catchError, finalize, mergeMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { QueryResultsModel } from '../../models/query-models/query-results.model';
import { BaseContract } from '../contracts/base.contract';
import { ClientModel } from '../../models/client.model';

@Injectable({
	providedIn: 'root'
})
export class ClientsRepository extends BaseRepository {
	constructor(private provider: ClientsProviderContract) {
		super();
		this.dataProvider = provider;
	}
	getAll() {
		return this.provider.getAll();
	}
	getAllInPrivateBranches() {
		return this.provider.getAllInPrivateBranches();
	}
	update(client: ClientModel): Observable<any> {
		return this.provider.update(client);
	}

	insert(client: ClientModel): Observable<any> {
		return this.provider.insert(client);
	}
	updateClientBranch(
		clientId: number,
		branchId?: number
	): Observable<ClientModel> {
		return this.provider.updateClientBranch(clientId, branchId);
	}
	get(clientId: number): Observable<ClientModel> {
		return this.provider.get(clientId);
	}
}
