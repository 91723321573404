import {
	Component,
	OnInit,
	ElementRef,
	ViewChild,
	ChangeDetectionStrategy,
	AfterViewInit,
	AfterContentInit,
	ChangeDetectorRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatPaginator, MatSort, MatDialog, MatTable } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// RXJS
import {
	debounceTime,
	distinctUntilChanged,
	tap,
	mergeMap
} from 'rxjs/operators';
import { fromEvent, merge, Observable, of } from 'rxjs';
// Services
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import { SubscriptionsRepository } from '../../../../../data/repositories/subscriptions.repository';
import { SubscriptionsList } from '../../../../../models/models';
import { QueryParamsModel } from '../../../../../models/query-models/query-params.model';
import { DataGridHelper } from '../../grid.helper';
import { ClientsRepository } from '../../../../../data/repositories/clients.repository';
import { QueryResultsModel } from '../../../../../models/query-models/query-results.model';
import { SubscriptionModel } from '../../../../../models/subscription.model';
import {
	MessageType,
	LayoutUtilsService
} from '../../../../layout/utils/layout-utils.service';
import { SubscriptionsDetailsComponent } from '../subscriptions-details/subscriptions-details.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { BranchModel } from '../../../../../models/branch.model';
import { BranchesRepository } from '../../../../../data/repositories/branches.repository';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { ClientModel } from '../../../../../models/client.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ChildsRepository } from '../../../../../data/repositories/childs.repository';
import { PackagesRepository } from '../../../../../data/repositories/packages.repository';
import { SubscriptionStateModel, SubscriptionDayStateModel } from '../../../../../models/subscription-state.model';
import { PackageModel } from '../../../../../models/package.model';
import { TypesUtilsService } from '../../../../layout/utils/types-utils.service';
import { async } from '@angular/core/testing';
import { default as distance } from 'date-fns/distance_in_words_to_now';
import { default as arLocale } from 'date-fns/locale/ar';
import { DayEventsComponent } from '../day-events/day-events.component';
import { SettingsRepository } from '../../../../../data/repositories/settings.repository';
import { EventType } from '../../../../../models/settings.model';
import { ChildModel } from '../../../../../models/child.model';
// Models
@Component({
	selector: 'm-visits-all',
	templateUrl: './visits-all.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		SubscriptionsRepository,
		ClientsRepository,
		ChildsRepository,
		BranchesRepository,
		PackagesRepository,
		SettingsRepository
	]
})
export class VisitsAllComponent extends DataGridHelper
	implements OnInit {
	subscription: SubscriptionModel;
	displayedColumns = [
		'subscriptionId',
		'date',
		'BranchName',
		'Child.FirstName',
		//	'ChildPicture',
		'BirthDate',
		'Gender',
		'Client.FirstName',
		'SubscriptionState.DName1',
		'SubscriptionDayState.DName1',
		'EventsCount'
	];
	// search BEGIN
	filteredClientsList: ClientModel[] = [];
	filteredChildsList: ChildModel[] = [];
	searchForm: FormGroup;
	allClients: ClientModel[] = [];
	allChilds: ChildModel[] = [];
	branchesList: BranchModel[] = [];
	statesList: SubscriptionStateModel[] = [];
	dayStatesList: SubscriptionDayStateModel[] = [];
	eventTypes: EventType[] = [];
	packagesList: PackageModel[] = [];
	// search END
	constructor(
		public dialog: MatDialog,
		private subheaderService: SubheaderService,
		public subscriptionsRepository: SubscriptionsRepository,
		public clientsRepository: ClientsRepository,
		public childsRepository: ChildsRepository,
		public branchesRepository: BranchesRepository,
		public packagesRepository: PackagesRepository,
		public settingsRepository: SettingsRepository,
		public layoutUtilsService: LayoutUtilsService,
		public spinner: NgxSpinnerService,
		public typesUtilsService: TypesUtilsService,
		private router: Router,
		public formBuilder: FormBuilder,
		public authService: AuthenticationService


	) {
		super(spinner, layoutUtilsService, authService);
		this.dataGridSource = subscriptionsRepository;
		this.createForm();
	}
	// search BEGIN
	createForm() {
		this.searchForm = this.formBuilder.group({
			branch: [null],
			client: [null],
			child: [null],
			dateFrom: [null],
			dateTo: [null],
			subscriptionId: [null],
			subscriptionState: [null],
			dayState: [null],
			package: [null],
			eventType: [null]
		});

		this.searchForm.controls.dateFrom.setValue(new Date());
		this.searchForm.controls.dateTo.setValue(new Date());
		this.searchForm.controls.subscriptionState.setValue(4);
		this.searchForm.controls.client.valueChanges.subscribe(a => {
			this.filteredClientsList = this.filterClient(a);
		});

		this.searchForm.controls.child.valueChanges.subscribe(a => {
			this.filteredChildsList = this.filterChild(a);
		});
	}

	async loadStates() {
		const allStatesFilter = new SubscriptionStateModel();
		allStatesFilter.DName1 = 'كل الحالات';
		this.statesList = await this.async(this.subscriptionsRepository.getStates());
		this.statesList.unshift(allStatesFilter);
	}
	async loadClients() {
		this.allClients = await this.async(this.clientsRepository.getAll());
		this.filteredClientsList = this.allClients;
	}
	async loadChilds() {
		this.allChilds = await this.async(this.childsRepository.getAll());
		this.filteredChildsList = this.allChilds;
	} 

	async loadDayStates() {
		const allStatesFilter = new SubscriptionDayStateModel();
		allStatesFilter.DName1 = 'كل الحالات';
		this.dayStatesList = await this.async(this.subscriptionsRepository.getDayStatuses());
		this.dayStatesList.unshift(allStatesFilter);
	}
	async loadDayEvents() {
		const allEventsFilter = new EventType();
		allEventsFilter.DName1 = 'كل الأحداث';
		this.eventTypes = await this.async(this.settingsRepository.getAllEventsTypes());
		this.eventTypes.unshift(allEventsFilter);
	}
	filterClient(val: string): ClientModel[] {
		return this.allClients.filter(option => {
			const fullName = option.FirstName + ' ' + option.MidName + ' ' + option.LastName;
			return fullName.includes(val) || option.PhoneNumber.includes(val);
		});
	}

	filterChild(val: string): ChildModel[] {
		return this.allChilds.filter(option => {
			return option.FirstName.includes(val);
		});
	}


	displayFn(client?: ClientModel): string | undefined {
		if (!client || !client.Id) {
			return;
		}
		return client.FirstName + ' ' + client.MidName + ' ' + client.LastName;
	}

	displayFnChild(child?: ChildModel): string | undefined {
		if (!child || !child.Id) {
			return;
		}
		return child.FirstName + ' ' + child.MidName + ' ' + child.LastName;
	}

	resetSearch() {
		this.createForm();
	}
	find(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		const controls = this.searchForm.controls;
		const subscriptionState = controls['subscriptionState'].value;
		const subscriptionId = controls['subscriptionId'].value;
		const client = controls['client'].value;
		const child = controls['child'].value;
		const branchId = controls['branch'].value;
		const packageId = controls['package'].value;
		const eventTypeId = controls['eventType'].value;
		const dayStateId = controls['dayState'].value;
		let clientId = null;
		if (client) {
			clientId = client.ClientId;
		}
		let childId = null;
		if (child) {
			childId = child.Id;
		}
		let dateFrom = controls['dateFrom'].value;
		if (dateFrom) {
			dateFrom = this.typesUtilsService.dateFormat(dateFrom);
		} else {
			dateFrom = null;
		}

		let dateTo = controls['dateTo'].value;
		if (dateTo) {
			dateTo = this.typesUtilsService.dateFormat(dateTo);
		} else {
			dateTo = null;
		}


		// tslint:disable-next-line: max-line-length
		return this.subscriptionsRepository.getdayVisits(subscriptionId, branchId, subscriptionState, dayStateId, clientId, childId, packageId, dateFrom, dateTo, eventTypeId).pipe(
			mergeMap(res => {
				const result = this.baseFilter(res, queryParams);
				return of(result);
			})
		);
	}
	// search END
	async ngOnInit() {
		this.loadBranches();
		this.initializeDataGrid();
		this.loadList();
		this.loadClients();
		this.loadChilds();
		this.loadStates();
		this.loadDayStates();
		this.loadDayEvents();
		this.loadPackages();
		this.subheaderService.setBreadcrumbs([
			{ title: ' الزيارات اليومية' },
			{ title: 'كل الزيارات', page: '/crm/subscriptions/visits' }
		]);
		this.dataGridSource = this.subscriptionsRepository;
	}

	async loadPackages() {
		this.packagesList = await this.async(this.packagesRepository.getAll());
	}
	async loadBranches() {
		const permissions = JSON.parse(localStorage.getItem('userPermissions'));
		if (permissions.find(a => a.RoleId === 1)) {
			this.branchesList = await this.async(this.branchesRepository.getAll());
		} else {
			for (let i = 0; i < permissions.length; i++) {
				const element = permissions[i];
				if (element.Code === 'subscriptions_view' && !this.branchesList.find(a => a.Id === element.branchId)) {
					this.branchesList.push(element.Branch);
				}
			}
		}

	}
	public calculateAge(date: Date): string {
		// 	console.log(date);
		const age = distance(date, {
			locale: arLocale
		});
		return age;
	}

	getItemCssClassByStatus(status: number = 0): string {
		switch (status) {
			case 1:
				return 'subscription-editing';
			case 2:
				return 'subscription-sadad';
			case 3:
				return 'subscription-paid';
			case 4:
				return 'subscription-active';
			case 5:
				return 'subscription-completed';
			case 200:
				return 'subscription-cancel';
		}
		return '';
	}

	getVisitCssClassByStatus(status: number = 0): string {
		switch (status) {
			case 1:
				return 'visit-pending';
			case 2:
				return 'visit-visited';
			case 3:
				return 'visit-postponed';
			case 4:
				return 'visit-canceled';
			case 5:
				return 'visit-absent';
		}
		return '';
	}



	selectRow(row: any) {
		console.log(row);

		const id = row.SubscriptionDayId;
		const dialogRef = this.dialog.open(DayEventsComponent, { data: { id } });
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}
		});
	}
}
