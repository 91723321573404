import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ChildModel } from '../../../../../models/child.model';
import { startWith, map } from 'rxjs/operators';
import { ClientModel } from '../../../../../models/client.model';
import { ClientsRepository } from '../../../../../data/repositories/clients.repository';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import {
	LayoutUtilsService,
	MessageType
} from '../../../../layout/utils/layout-utils.service';
import { ChildsRepository } from '../../../../../data/repositories/childs.repository';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TypesUtilsService } from '../../../../layout/utils/types-utils.service';
import { validateConfig } from '@angular/router/src/config';
import { DataGridHelper } from '../../grid.helper';
import { AttachmentsRepository } from '../../../../../data/repositories/Attachments.repository';
import { AttachmentModel } from '../../../../../models/attachment.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseComponent } from '../../../../../core/base/base.component';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';

@Component({
	selector: 'm-childs-details',
	templateUrl: './childs-details.component.html',
	styleUrls: ['./childs-details.component.scss'],
	providers: [ChildsRepository, ClientsRepository]
})
export class ChildsDetailsComponent extends BaseComponent implements OnInit {
	loadingSubject = new BehaviorSubject<boolean>(false);
	loading$ = this.loadingSubject.asObservable();
	isNewOrEdit: boolean;
	childForm: FormGroup;
	child: ChildModel = new ChildModel();
	childsAttachments: AttachmentModel[] = [];
	withClose: boolean;
	hasFormErrors: boolean = false;
	viewLoading: boolean = false;
	loadingAfterSubmit: boolean = false;
	BirthDate: any;
	errorMessage: string = '';
	allClients: ClientModel[] = [];
	filteredClientsList: ClientModel[] = [];
	displayedColumns = [
		'select',
		'AttachmentId',
		'AttachmentName',
		'PublishedAt',
		'PublishedBy',
		'Actions'
	];
	constructor(
		private router: Router,
		private childFB: FormBuilder,
		private route: ActivatedRoute,
		public layoutUtilsService: LayoutUtilsService,
		public clientsRepository: ClientsRepository,
		public childsRepository: ChildsRepository,
		public attachmentsRepository: AttachmentsRepository,
		public spinner: NgxSpinnerService,
		public subheaderService: SubheaderService,
		public typesUtilsService: TypesUtilsService,
		public authService: AuthenticationService,
		public ref: ChangeDetectorRef,
		public dialogRef: MatDialogRef<ChildsDetailsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		super(
			subheaderService,
			layoutUtilsService,
			ref,
			spinner,
			typesUtilsService,
			authService
		);
		this.createForm();
	}

	async ngOnInit() {
		this.allClients = await this.async(this.clientsRepository.getAll());
		this.filteredClientsList = this.allClients;
		this.child = this.data.child;
		console.log(this.child);
		if (this.child.Id) {
			this.child.BirthDate = new Date(this.child.BirthDate);
			if (this.child.QurrahSubscriptionDate) {
				this.child.QurrahSubscriptionDate = new Date(
					this.child.QurrahSubscriptionDate
				);
			}
			// 	const index = this.allClients.findIndex(
			// 		a => a.ClientId === this.child.ClientId
			// 	);
			// 	const client = this.allClients[index];
			// 	this.child.clientName = client.FirstName + ' ' + client.MidName;
		}

		this.createForm();
	}
	createForm() {
		this.childForm = this.childFB.group({
			firstName: [this.child.FirstName, Validators.required],
			midName: [this.child.MidName, Validators.required],
			lastName: [this.child.LastName, Validators.required],
			nickName: [this.child.NickName, Validators.required],
			client: [this.child.Client, Validators.required],
			birthDate: [this.child.BirthDate, Validators.required],
			gender: [this.child.Gender, Validators.required],
			qurrahNo: [this.child.QurrahSubscriptionNo],
			qurrahDate: [this.child.QurrahSubscriptionDate],
			identityNumber: [this.child.IdentityNumber, Validators.required]

		});
		this.childForm.controls.client.valueChanges.subscribe(a => {
			this.filteredClientsList = this.filterClient(a);
		});
	}
	filterClient(val: string): ClientModel[] {
		return this.allClients.filter(option => {
			const fullName = option.FirstName + ' ' + option.MidName + ' ' + option.LastName;
			return fullName.includes(val) || option.PhoneNumber.includes(val);
		});
	}
	displayFn(client?: ClientModel): string | undefined {
		if (!client) {
			return;
		}
		console.log(client);

		if (client.Id) {
			return client.FirstName + ' ' + client.MidName + ' ' + client.LastName;
		}
	}
	async updateChild(_Child: ChildModel) {
		await this.async(this.childsRepository.update(_Child));
		if (this.hasErrors) {
			return;
		}
		this.saveCompleted();
	}

	async createChild(_Child: ChildModel) {
		await this.async(this.childsRepository.insert(_Child));
		if (this.hasErrors) {
			return;
		}
		this.saveCompleted();
	}
	/** UI */
	getTitle(): string {
		if (this.child.Id > 0) {
			return `ملف طفل:  '${this.child.FirstName} ${this.child.LastName}'`;
		}

		return 'تسجيل طفل جديد';
	}
	isControlInvalid(controlName: string): boolean {
		const control = this.childForm.controls[controlName];
		const result = control.invalid && control.touched;
		return result;
	}

	/** ACTIONS */
	prepareChild(): ChildModel {
		const controls = this.childForm.controls;
		const _Child = new ChildModel();
		_Child.Id = this.child.Id;
		const _date = controls['birthDate'].value;
		if (_date) {
			_Child.BirthDate = this.typesUtilsService.dateFormat(_date);
		} else {
			_Child.BirthDate = '';
		}
		const qurrahDate = controls['qurrahDate'].value;
		if (qurrahDate) {
			_Child.QurrahSubscriptionDate = this.typesUtilsService.dateFormat(
				qurrahDate
			);
		} else {
			_Child.QurrahSubscriptionDate = '';
		}
		_Child.FirstName = controls['firstName'].value;
		_Child.MidName = controls['midName'].value;
		_Child.LastName = controls['lastName'].value;
		_Child.NickName = controls['nickName'].value;
		_Child.Gender = controls['gender'].value;
		_Child.ClientId = controls['client'].value.ClientId;
		_Child.QurrahSubscriptionNo = controls['qurrahNo'].value;
		_Child.IdentityNumber = controls['identityNumber'].value;
		console.log(_Child);
		return _Child;
	}

	onSubmit(withClose: boolean = false) {
		this.withClose = withClose;
		this.hasFormErrors = false;
		this.errorMessage = '';
		this.loadingAfterSubmit = false;
		const controls = this.childForm.controls;
		/** check form */
		if (this.childForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			return;
		}

		const editedChild = this.prepareChild();
		if (editedChild.Id > 0) {
			this.updateChild(editedChild);
		} else {
			this.createChild(editedChild);
		}
	}
	saveCompleted() {
		if (!this.withClose) {
			this.layoutUtilsService.showActionNotification(
				'تم الحفظ بنجاح',
				MessageType.Success,
				4000,
				true,
				false
			);
			return;
		}
		this.dialogRef.close({
			isEdit: true
		});
	}
	async refreshDetails() {
		this.child = await this.async(this.childsRepository.get(this.child.Id));
	}
}
