import { Injectable } from '@angular/core';

import { ChatsProviderContract } from '../../contracts/chats.contract';
import { ChatModel } from '../../../models/chat.model';
import { Observable } from 'rxjs';
import { NewMessage, UserChats } from '../../../models/models';
import { IApiOptions } from './contracts';
import { Api } from './api';

@Injectable({
	providedIn: 'root'
})
export class ChatsServiceDataProvider implements ChatsProviderContract {
    get(id: number): import("rxjs").Observable<ChatModel> {
        throw new Error("Method not implemented.");
    }
    getAll(): Observable<ChatModel[]> {
        throw new Error("Method not implemented.");
    }
    delete(id: number): Observable<any> {
        throw new Error("Method not implemented.");
    }
    update(entity: ChatModel): Observable<any> {
        throw new Error("Method not implemented.");
    }
    insert(entity: ChatModel): Observable<any> {
        throw new Error("Method not implemented.");
    }


    public getMessages(chatId: number, lastId?: number): Observable<any> {
        let url = `Chats/Get?chatId=${chatId}`;
        if (lastId != null) {
          url = url.concat(`&lastMessageId=${lastId}`);
        }
      console.log(url);

        return this.api.get(url);
      }

      public sendMessage(message: NewMessage): Observable<NewMessage> {
        return this.api.post(`chats/postMessage?ChatId=${message.ChatId}&Content=${message.Content}`);
      }
      public getChats(): Observable<UserChats[]> {
        return this.api.get('chats/GetUserChats');
      }
      constructor(private api: Api) { }
}
