import { Injectable } from '@angular/core';
import { BaseRepository } from './_base.repository';
import { AttachmentsProviderContract } from '../contracts/attachments.contract';
import { Observable } from 'rxjs';
import { AttachmentModel } from '../../models/attachment.model';

@Injectable({
	providedIn: 'root'
  })
export class AttachmentsRepository extends BaseRepository {


	constructor(private provider: AttachmentsProviderContract) {
        super();
        this.dataProvider = provider;
    }
    getAttachments(entityId:number, entityType: number){
			return this.provider.getAttachments(entityId, entityType);
		}
    
    upload(formData: FormData, EntityId:number, Description:string, AttachmentTypeId:number, EntityType:number): Observable<any>{
      return this.provider.uploadAttachment(formData, EntityId, Description, AttachmentTypeId, EntityType);
    }

    getAttachmentTypes(EntityType:number): Observable<any>{
      return this.provider.getAttachmentTypes(EntityType);
    }
    delete(attachmentId: number){
      return this.provider.delete(attachmentId);
    }

}
