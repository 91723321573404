import { BaseModel } from './_base.model';
import { BranchModel } from './branch.model';

export class ClientModel extends BaseModel {
    Id: number;
    ClientId: number;
    FirstName: string;
    MidName:string;
    LastName:string;
    Picture:string;
    Gender: string;
    QurrahSubscriptionNumber: number;
    Email: string;
    PhoneNumber: string;
    IdentityNumber: string;
    language:string;
    Branch: BranchModel = new BranchModel();
   FullName: string = this.FirstName + ' ' + this.MidName + ' ' + this.LastName;
}