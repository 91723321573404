import { BaseModel } from "./_base.model";
import { AgeCategoryModel } from './age-category.model';
import { BranchModel } from './branch.model';

export class EmployeeModel {
	Id: string;
	EmployeeId: number;
	Picture: string;
	Permissions: Permissions[];
	FirstName: string;
	MidName: string;
	LastName: string;
	Email: boolean;
	Gender: string;
	PhoneNumber: string;
	IdentityNumber: string;
	UserName: string;
	Language: string;	
	AgeCategoryId: number;
    AgeCategory: AgeCategoryModel = new AgeCategoryModel();
}
export class Permissions {
	Id: number;
	Branch: BranchModel;
	Role: Role;
	CreatedBy: string;
	CreatedAt: string;
	RoleId: string;
	UserId: string;
	BranchId: number;
}
export class Role {
	Id: number;
	Name: string;
}

