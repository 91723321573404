import { Injectable, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { LayoutUtilsService, MessageType } from '../../content/layout/utils/layout-utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TypesUtilsService } from '../../content/layout/utils/types-utils.service';
import { Observable } from 'rxjs';
import { SubheaderService } from '../services/layout/subheader.service';
import { AuthenticationService } from '../auth/authentication.service';

@Injectable()
export abstract class BaseComponent implements OnInit {
	hasErrors: boolean;
	constructor(
		public subheaderService: SubheaderService,
		public layoutUtilsService: LayoutUtilsService,
		public ref: ChangeDetectorRef,
		public spinner: NgxSpinnerService,
		public typesUtilsService: TypesUtilsService,
		public authService: AuthenticationService,
		// 	public dialogRef: MatDialogRef<any>,
		// @Inject(MAT_DIALOG_DATA) public data: any
	) { }

	ngOnInit() { }

	async async(observable: Observable<any>): Promise<any> {
		this.hasErrors = false;
		try {
			this.spinner.show();
			this.refreshView();
			const dd = await observable.toPromise();
			// console.log(dd);
			this.spinner.hide();
			this.refreshView();
			return dd;
		} catch (err) {
			this.handleException(err);
			this.refreshView();
		}
	}
	refreshView() {
		console.log('refreshView');
		if (!this.ref['destroyed']) {
			this.ref.detectChanges();
		}
	}


	public calculateAge(date: Date): string {
		// console.log(date);
		const today = new Date();
		const dt2 = new Date(date);
		let diff = (today.getTime() - dt2.getTime()) / 1000;
		diff /= (60 * 60 * 24 * 7 * 4);
		diff = Math.abs(Math.round(diff));
		return diff.toString();
		// console.log(diff);
		// const age = distance(date, {
		// 	locale: arLocale
		// });
		// return age;
	}


	handleException(err: any) {
		this.hasErrors = true;
		this.spinner.hide();
		console.log(err);
		let msg = '';
		if (err === 'net::ERR_CONNECTION_REFUSED') {
			msg = 'مشكلة في الإتصال ..الرجاء التحقق من الإتصال بالإنترنت';
		}
		if (err.status === 401) {
			this.authService.logout();
			return;
		}
		if ((err.status === 500)) {
			msg = 'حدث خطأ في النظام';
		} else if ((err.status === 400)) {
			const modelState = err.error.ModelState;
			if (modelState) {
				msg = '<ul>';
				for (const key in modelState) {
					if (modelState.hasOwnProperty(key)) {
						for (let i = 0; i < modelState[key].length; i++) {
							msg += '<li>' + modelState[key][i] + '</li>';
						}
					}
				}
				msg += '</ul>';
			} else if (err.error.Message) {
				msg = err.error.Message;
			}
		}
		//else { msg = err; }

		console.log(msg);
		this.layoutUtilsService.showActionNotification(
			msg,
			MessageType.Error,
			8000,
			true,
			false
		);
	}

}
