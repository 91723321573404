import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import {
	LayoutUtilsService,
	MessageType
} from '../../../../layout/utils/layout-utils.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BranchesRepository } from '../../../../../data/repositories/branches.repository';
import { BranchModel } from '../../../../../models/branch.model';
import { PermissionsRepository } from '../../../../../data/repositories/permissions.repository';
import { EmployeesRepository } from '../../../../../data/repositories/employees.repository';
import { EmployeeModel, Role } from '../../../../../models/employee.model';
import { PermissionModel } from '../../../../../models/permission.model';
import { map, startWith } from 'rxjs/operators';
import { BaseComponent } from '../../../../../core/base/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { TypesUtilsService } from '../../../../layout/utils/types-utils.service';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { RoleModel } from '../../../../../models/role.model';

@Component({
	selector: 'm-permissions-details',
	templateUrl: './permissions-details.component.html',
	styleUrls: ['./permissions-details.component.scss'],
	providers: [PermissionsRepository]
})
export class PermissionsDetailsComponent  extends BaseComponent implements OnInit {
	loadingSubject = new BehaviorSubject<boolean>(false);
	loading$ = this.loadingSubject.asObservable();
	isNewOrEdit: boolean;
	permissionForm: FormGroup;
	allBranches: BranchModel[] = [];
	allEmployees: EmployeeModel[] = [];
	filteredEmployeesList: EmployeeModel[] = [];
	allRoles: RoleModel[] = [];
	withClose: boolean;
	hasFormErrors: boolean = false;
	viewLoading: boolean = false;
	loadingAfterSubmit: boolean = false;
	errorMessage: string = '';
	permission: PermissionModel = new PermissionModel();
	constructor(
		private permissionFB: FormBuilder,
		public subheaderService: SubheaderService,
		public permissionsRepository: PermissionsRepository,
		public employeesRepository: EmployeesRepository,
		public branchesRepository: BranchesRepository,
		public layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<PermissionsDetailsComponent>,
		public ref: ChangeDetectorRef,
		public spinner: NgxSpinnerService,
		public typesUtilsService: TypesUtilsService,

		public authService: AuthenticationService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		super(
			subheaderService,
			layoutUtilsService,
			ref,
			spinner,
			typesUtilsService,
			authService
		);
	}

	ngOnInit() {
		this.loadingSubject.next(true);
		this.branchesRepository.getAll().subscribe(result => {
			this.allBranches = result;
		});
		this.employeesRepository.getAll().subscribe(result => {
			this.allEmployees = result;
		});
		this.permissionsRepository.getRoles().subscribe(result => {
			this.allRoles = result;
		});
		this.filteredEmployeesList = this.allEmployees;
		this.createForm();
		this.loadingSubject.next(false);
	}
	createForm() {
		this.permissionForm = this.permissionFB.group({
			user: [this.permission.UserId, Validators.required],
			branch: [this.permission.BranchId, Validators.required],
			role: [this.permission.RoleId, Validators.required]
		});
		this.permissionForm.controls.user.valueChanges.subscribe(a => {
			this.filteredEmployeesList = this.filterEmployee(a);
		});
	}
	filterEmployee(val: string): EmployeeModel[] {
		return this.allEmployees.filter(option => {
			return option.FirstName.includes(val);
		});
	}
	displayFn(employee?: EmployeeModel): string | undefined {
		if (!employee) { return; }
		console.log(employee);

		if (employee.Id) { return employee.FirstName + ' ' + employee.MidName; }
	}
	isControlInvalid(controlName: string): boolean {
		const control = this.permissionForm.controls[controlName];
		const result = control.invalid && control.touched;
		return result;
	}

	/** ACTIONS */
	preparePermission(): PermissionModel {
		const controls = this.permissionForm.controls;
		const _Permission = new PermissionModel();
		_Permission.UserId = controls['user'].value;
		_Permission.BranchId = controls['branch'].value;
		_Permission.RoleId = controls['role'].value;
		console.log(_Permission);
		return _Permission;
	}

	onSubmit(withClose: boolean = false) {
		this.withClose = withClose;
		this.hasFormErrors = false;
		this.errorMessage = '';
		this.loadingAfterSubmit = false;
		const controls = this.permissionForm.controls;
		/** check form */
		if (this.permissionForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			return;
		}

		const permission = this.preparePermission();
		this.createPermission(permission);
	}

	saveCompleted() {
		if (!this.withClose) {
			this.layoutUtilsService.showActionNotification(
				'تم الحفظ بنجاح',
				MessageType.Success,
				4000,
				true,
				false
			);
			return;
		}
		this.dialogRef.close({
			isEdit: true
		});
	}

	async createPermission(_Permission: PermissionModel) {
		await this.async(this.permissionsRepository.insert(_Permission));
		if (this.hasErrors) {return; }
		this.saveCompleted();
	}

	onAlertClose($event) {
		this.hasFormErrors = false;
	}
}
