import { Component, OnInit, Input } from '@angular/core';
import { PermissionsRepository } from '../../../../../../../data/repositories/permissions.repository';
import { DataGridHelper } from '../../../../../crm/grid.helper';
import { MatDialog } from '@angular/material';
import { SubheaderService } from '../../../../../../../core/services/layout/subheader.service';
import { LayoutUtilsService, MessageType } from '../../../../../../layout/utils/layout-utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../../../../../../../core/auth/authentication.service';
import { QueryParamsModel } from '../../../../../../../models/query-models/query-params.model';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { QueryResultsModel } from '../../../../../../../models/query-models/query-results.model';
import { mergeMap } from 'rxjs/operators';
import { PermissionModel } from '../../../../../../../models/permission.model';
import { PermissionsDetailsComponent } from '../../../../permissions/permissions-details/permissions-details.component';
import { BranchModel } from '../../../../../../../models/branch.model';
import { RoleModel } from '../../../../../../../models/role.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BranchesRepository } from '../../../../../../../data/repositories/branches.repository';


@Component({
	selector: 'm-user-roles',
	templateUrl: './user-roles.component.html',
	styleUrls: ['./user-roles.component.scss'],
	providers: [PermissionsRepository, BranchesRepository]

})
export class UserRolesComponent extends DataGridHelper implements OnInit {
	// Incoming data
	@Input() loadingSubject: BehaviorSubject<boolean>;
	@Input() userId: string;
	branchesList: BranchModel[];
	rolesList: RoleModel[];
	newRoleForm: FormGroup;
	displayedColumns = [
		'roleName',
		'branchName',
		'Actions'
	];
	constructor(
		public dialog: MatDialog,
		public permissionsRepository: PermissionsRepository,
		public branchesRepository: BranchesRepository,
		public layoutUtilsService: LayoutUtilsService,
		public spinner: NgxSpinnerService,
		public authService: AuthenticationService,
		public formBuilder: FormBuilder,

	) {
		super(spinner, layoutUtilsService, authService);
		this.dataGridSource = permissionsRepository;
	}

	find(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		return this.permissionsRepository.getUserRoles(this.userId).pipe(
			mergeMap(res => {
				console.log(125);
				const result = this.baseFilter(res, queryParams);
				return of(result);
			})
		);
	}
	ngOnInit() {
		this.initializeDataGrid();
		this.loadList();
		this.createForm();
		this.getBranches();
		this.getRoles();
	}
	createForm() {
		this.newRoleForm = this.formBuilder.group({
			role: [null, Validators.required],
			branch: [null]
		});
	}
	/** FILTRATION */
	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;

		filter.User = searchText;
		filter.Branch = searchText;
		filter.Role = searchText;
		return filter;
	}

	getRoles() {
		this.permissionsRepository.getRoles().subscribe(res => {
			this.rolesList = res;
		});
	}

	getBranches() {
		this.branchesRepository.getAll().subscribe(res => {
			this.branchesList = res;
			console.log(res);
		});
	}
	async deleteRole(role: any) {
		console.log(role);
		const dialogRef = this.layoutUtilsService.showConfirmationDialog('حذف مهمة', 'سيتم حذف المهمة من المستخدم..هل ترغب في المواصلة؟');
		dialogRef.afterClosed().subscribe(async res => {
			if (!res) {
				return;
			}

			await this.async(this.permissionsRepository.deleteRoleFromUser(this.userId, role.RoleId, role.BranchId));
			if (this.hasErrors) {
				return;
			}
			this.loadList();
			this.saveCompleted();

		});
	}
	async onSubmit() {
		/** check form */
		const controls = this.newRoleForm.controls;
		if (this.newRoleForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		const roleId = controls['role'].value;
		const branchId = controls['branch'].value;
		if (!branchId) {
			const dialogRef = this.layoutUtilsService.showConfirmationDialog('اضافة مهمة',
				'لم يتم تحديد فرع سيتم إضافة المهمة للمستخدم على كل الفروع..هل ترغب في المواصلة؟');
			dialogRef.afterClosed().subscribe(async res => {
				if (!res) {
					return;
				}
				await this.addRoleToUser(this.userId, roleId, branchId);

			});
		} else {
			await this.addRoleToUser(this.userId, roleId, branchId);
		}
	}

	async addRoleToUser(userId: string, roleId: number, branchId: number) {
		await this.async(this.permissionsRepository.addRoleToUser(this.userId, roleId, branchId));
		if (this.hasErrors) {
			return;
		}
		this.loadList();
		this.saveCompleted();
	}
	saveCompleted() {
		this.layoutUtilsService.showActionNotification(
			'تم الحفظ بنجاح',
			MessageType.Success,
			4000,
			true,
			false
		);
		return;
	}
}
