import { Injectable } from '@angular/core';
import { Api } from './api';
import { Observable } from 'rxjs';
import { BranchModel } from '../../../models/branch.model';
import { BranchesProviderContract } from '../../contracts/branches.contract';

@Injectable({
	providedIn: 'root'
})
export class BranchesServiceDataProvider implements BranchesProviderContract {
	getBranchWorkDays(branchId: number): Observable<any> {
		return this.api.get(`Branches/GetBranchWorkDays?branchId=${branchId}`);
	}
	removeWorkDayFromBranch(branchId: number, dayId: number): Observable<any> {
		return this.api.delete(`Branches/RemoveWorkDayFromBranch?branchId=${branchId}&dayId=${dayId}`);
	}
	getWeekdays(): Observable<any> {
		return this.api.get(`Branches/GetWeekdays`);
	}
	addWorkDayToBranch(branchId: number, dayId: any): Observable<any> {
		return this.api.post(`Branches/AddWorkDayToBranch?branchId=${branchId}&dayId=${dayId}`);
	}
	insert(entity: BranchModel): Observable<any> {
		return this.api.post('Branches/Add', entity);
	}
	get(id: number): Observable<BranchModel> {
		throw new Error('Method not implemented.');
	}
	getAll(): Observable<BranchModel[]> {
		return this.api.get('Branches/GetAll');
	}
	delete(id: number): Observable<any>  {
		throw new Error('Method not implemented.');
	}
	update(entity: BranchModel): Observable<any> {
		return this.api.post('Branches/Update', entity);
	}
	getClientBranches(clientId: number): Observable<BranchModel[]> {
		return this.api.get(
			`Branches/GetAvailableBranchesForClient?clientId=${clientId}`
		);
	}
	getPrivateBranches(): Observable<BranchModel[]> {
		return this.api.get('Branches/GetPrivateBranches');
	}

	constructor(private api: Api) {}
}
