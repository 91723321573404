import { BaseContract } from './base.contract';
import { ChildModel } from '../../models/child.model';
import { Observable } from 'rxjs';
import { SupervisorModel } from '../../models/supervisor.model';
import { AgeCategoryModel } from '../../models/age-category.model';
import { DayEventModel } from '../../models/dayevent.model';


export abstract class ChildsProviderContract extends BaseContract<ChildModel> {
    abstract addCategory(category: AgeCategoryModel): Observable<any>;
    abstract updateCategory(category: AgeCategoryModel): Observable<any>;
    abstract deleteCategory(categoryId: number): Observable<any>;
    abstract getCheckedInChilds(date?: string): Observable<ChildModel[]>;
    abstract getCheckedOutChilds(date?: string): Observable<ChildModel[]>;
    abstract getChildsWithoutSupervisors(): Observable<ChildModel[]>;
    abstract getClientChilds(clientId: number): Observable<ChildModel[]>;
    abstract checkChildSubscribtions(childId :number): Observable<any>;
    abstract getSupervisors(branchId?: number): Observable<SupervisorModel[]>;
    abstract getSupervisorChilds(supervisorId: number): Observable<ChildModel[]>;
    abstract addSupervisor(childId: number, supervisorId: number): Observable<any>;
    abstract removeSupervisor(childId: number): Observable<any>;
    abstract getCategories(): Observable<AgeCategoryModel[]>;
    abstract newEvent(event: DayEventModel): Observable<DayEventModel>;
}
