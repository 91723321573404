import { Component, OnInit } from "@angular/core";

@Component({
	selector: "m-hcm",
	templateUrl: "./hcm.component.html"
})
export class HcmComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
