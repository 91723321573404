import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import {
	LayoutUtilsService,
	MessageType
} from '../../../../layout/utils/layout-utils.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TypesUtilsService } from '../../../../layout/utils/types-utils.service';
import { validateConfig } from '@angular/router/src/config';
import { DataGridHelper } from '../../grid.helper';
import { AttachmentsRepository } from '../../../../../data/repositories/Attachments.repository';
import { AttachmentModel } from '../../../../../models/attachment.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseComponent } from '../../../../../core/base/base.component';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { SubscriptionsRepository } from '../../../../../data/repositories/subscriptions.repository';
import { CancelSubscriptionModel, SubscriptionModel } from '../../../../../models/subscription.model';

@Component({
	selector: 'm-subscriptions-cancel',
	templateUrl: './subscriptions-cancel.component.html',
	styleUrls: ['./subscriptions-cancel.component.scss'],
	providers: [SubscriptionsRepository]
})
export class SubscriptionsCancelComponent extends BaseComponent implements OnInit {
	loadingSubject = new BehaviorSubject<boolean>(false);
	loading$ = this.loadingSubject.asObservable();
	cancelForm: FormGroup;
	hasFormErrors: boolean = false;
	errorMessage: string = '';
	viewLoading: boolean = false;
	loadingAfterSubmit: boolean = false;
subscriptionId: number;
	cancelSubscriptionModel: CancelSubscriptionModel = new CancelSubscriptionModel();
	constructor(
		private router: Router,
		private fb: FormBuilder,
		private route: ActivatedRoute,
		public layoutUtilsService: LayoutUtilsService,
		public spinner: NgxSpinnerService,
		public subheaderService: SubheaderService,
		public typesUtilsService: TypesUtilsService,
		public authService: AuthenticationService,
		public ref: ChangeDetectorRef,
		public subscriptionRepository: SubscriptionsRepository,
		public dialogRef: MatDialogRef<SubscriptionsCancelComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		super(
			subheaderService,
			layoutUtilsService,
			ref,
			spinner,
			typesUtilsService,
			authService
		);
		this.createForm();
	}

	async ngOnInit() {
		this.createForm();
		console.log(this.data);
		this.cancelSubscriptionModel.SubscriptionId = this.data.data.Id;
	}
	createForm() {
		this.cancelForm = this.fb.group({
			cancelDate: [null, Validators.required],
			cancelReason: [null, Validators.required]
		});
	}

	getTitle(): string {
		return 'إلغاء إشتراك';
	}
	cancel() {
		this.dialogRef.close();
	}
	async submit() {
		console.log('submit');
		this.hasFormErrors = false;
		this.errorMessage = '';
		this.loadingAfterSubmit = false;

		const controls = this.cancelForm.controls;
		/** check form */
		if (this.cancelForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			this.hasFormErrors = true;
			return;
		}
		const _date = controls['cancelDate'].value;
		if (_date) {
			this.cancelSubscriptionModel.CancellationDate = this.typesUtilsService.dateFormat(_date);
		} else {
			this.cancelSubscriptionModel.CancellationDate = '';
		}
		this.cancelSubscriptionModel.CancellationReason = controls['cancelReason'].value;

		console.log(this.cancelSubscriptionModel);

		await this.async(this.subscriptionRepository.cancelSubscription(this.cancelSubscriptionModel));
		if (this.hasErrors) { return; }

		this.dialogRef.close('1');
	}
}

