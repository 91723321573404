import { Injectable } from '@angular/core';
import { Api } from './api';
import { Observable } from 'rxjs';
import { PermissionsProviderContract } from '../../contracts/permissions.contract';
import { PermissionModel } from '../../../models/permission.model';
import { Role } from '../../../models/employee.model';
import { RoleModel } from '../../../models/role.model';

@Injectable({
	providedIn: 'root'
})
export class PermissionsServiceDataProvider
	implements PermissionsProviderContract {
	deleteRoleFromUser(userId: string, roleId: number, branchId: number): Observable<any> {
		if (branchId)
		{
			return this.api.delete(`Permissions/deleteRoleFromUser?userId=${userId}&roleId=${roleId}&branchId=${branchId}`);
		}
		return this.api.delete(`Permissions/deleteRoleFromUser?userId=${userId}&roleId=${roleId}`);
	}
	addRoleToUser(userId: string, roleId: number, branchId: number): Observable<any> {
		if (branchId)
		{
			return this.api.post(`Permissions/addRoleToUser?userId=${userId}&roleId=${roleId}&branchId=${branchId}`);
		}
		return this.api.post(`Permissions/addRoleToUser?userId=${userId}&roleId=${roleId}`);
	}
	resetUserPassword(Id: string, newPassword: string): Observable<any> {
		return this.api.post(`Users/ResetPassword?userId=${Id}&newPassword=${newPassword}`);
	}
	getUserRoles(userId: string): Observable<any> {
		return this.api.get(`Permissions/GetUserRoles?userId=${userId}`);
	}
	insert(entity: PermissionModel): Observable<any> {
		return this.api.post('Permissions/Add', entity);
	}
	get(id: number): Observable<PermissionModel> {
		throw new Error('Method not implemented.');
	}
	getAll(): Observable<PermissionModel[]> {
		return this.api.get('Permissions/GetAll');
	}
	delete(id: number): Observable<any> {
		throw new Error('Method not implemented.');
	}
	update(entity: PermissionModel): Observable<any> {
		throw new Error('Method not implemented.');
	}
	getRoles(): Observable<RoleModel[]> {
		return this.api.get('Permissions/GetRoles');
	}
	getRole(roleId?: number): Observable<RoleModel> {
		let params: string = '';
		if (roleId) { params += 'roleId=' + roleId; }
		console.log(params);
		return this.api.get('Permissions/GetRole?' + params);
	}
	insertUpdateRole(role: RoleModel, permissions: string): Observable<any> {
		return this.api.post(`Permissions/InsertUpdateRole?` + permissions, role);
	}
	constructor(private api: Api) { }
}
