import { BaseContract } from './base.contract';
import { Observable } from 'rxjs';
import { SubscriptionsDashboardModel } from '../../models/dashboard.model';


export  abstract class DashboardProviderContract extends BaseContract<any> {
    abstract getSubscriptionsSummary(): Observable<SubscriptionsDashboardModel>;
    abstract getFinancialStatus(): Observable<any>;
    abstract getSubscriptionsPerPackages(): Observable<any>;
    abstract getExpensesPerTypes(): Observable<any>;
    abstract getsubscriptionsVisits(): Observable<any>;
}
