import { BaseContract } from './base.contract';
import { Observable } from 'rxjs';
import { InvoiceModel, ExpensesModel, ExpenseType } from '../../models/accounts.model';


export  abstract class AccountsProviderContract extends BaseContract<ExpensesModel>{
    abstract getAllInvoices(): Observable<InvoiceModel[]>;
    abstract getExpensesTypes(): Observable<ExpenseType[]>;
    abstract addExpenseType(model: ExpenseType): Observable<any>;
    abstract updateExpenseType(model: ExpenseType): Observable<any>;
    abstract deleteExpense(expenseId: number): Observable<any>;
}
