import { Injectable } from '@angular/core';
import { BaseRepository } from './_base.repository';
import { ChildsProviderContract } from '../contracts/childs.contract';
import { ChildModel } from '../../models/child.model';
import { Observable } from 'rxjs';
import { SupervisorModel } from '../../models/supervisor.model';
import { AgeCategoryModel } from '../../models/age-category.model';
import { DayEventModel } from '../../models/dayevent.model';

@Injectable({
	providedIn: 'root'
})
export class ChildsRepository extends BaseRepository {
	constructor(private provider: ChildsProviderContract) {
		super();
		this.dataProvider = provider;
	}
	getAll() {
		return this.provider.getAll();
	}
	update(child: ChildModel): Observable<any> {
		return this.provider.update(child);
	}
	deleteChild(Id: number): Observable<any> {
		return this.provider.delete(Id);
	}
	checkChildSubscribtions(childId:number): Observable<any>{
		return this.provider.checkChildSubscribtions(childId);
	}
	insert(child: ChildModel): Observable<any> {
		return this.provider.insert(child);
	}
	getChildsWithoutSupervisors(): Observable<ChildModel[]> {
		return this.provider.getChildsWithoutSupervisors();
	}
	getClientChilds(clientId: number): Observable<ChildModel[]> {
		return this.provider.getClientChilds(clientId);
	}
	getSupervisors(branchId?: number): Observable<SupervisorModel[]> {
		return this.provider.getSupervisors(branchId);
	}
	getSupervisorChilds(supervisorId: number): Observable<ChildModel[]> {
		return this.provider.getSupervisorChilds(supervisorId);
	}
	addSupervisor(childId: number, supervisorId: number): Observable<any> {
		return this.provider.addSupervisor(childId, supervisorId);
	}
	removeSupervisor(childId: number): Observable<any> {
		return this.provider.removeSupervisor(childId);
	}
	getCheckedOutChilds(date: string): Observable<any> {
		return this.provider.getCheckedOutChilds(date);
	}
	getCheckedInChilds(date?: string): Observable<any> {
		return this.provider.getCheckedInChilds(date);
	}
	newEvent(event: DayEventModel): Observable<any> {
		return this.provider.newEvent(event);
	}
	getCategories(): Observable<AgeCategoryModel[]> {
		return this.provider.getCategories();
	}
	get(childId: number): Observable<ChildModel> {
		return this.provider.get(childId);
	}
	addCategory(category: AgeCategoryModel): Observable<any> {
		return this.provider.addCategory(category);
	}
	updateCategory(category: AgeCategoryModel): Observable<any> {
		return this.provider.updateCategory(category);
	}
	deleteCategory(categoryId: number): Observable<any> {
		return this.provider.deleteCategory(categoryId);
	}
}
