import { Injectable } from '@angular/core';
import { Api } from './api';
import { Observable } from 'rxjs';
import { CamerasProviderContract } from '../../contracts/camera.contract';
import { CameraModel } from '../../../models/camera.model';


@Injectable({
  providedIn: 'root'
})
export class CamerasServiceDataProvider implements CamerasProviderContract  {
  insert(entity: CameraModel): Observable<any> {
    return this.api.post('Cameras/AddCamera', entity);
  }
  get(id: number): Observable<CameraModel> {
    throw new Error('Method not implemented.');
  }
  getAll(): Observable<CameraModel[]> {
    return this.api.get('Cameras/GetAll');
  }
  delete(id: number): Observable<any>  {
    throw new Error('Method not implemented.');
  }
  update(entity: CameraModel): Observable<any> {
    return this.api.post('Cameras/UpdateCamera', entity);
  }


  constructor(private api: Api) { }

}
