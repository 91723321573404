import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataComponent } from './data.component';
import { SubscriptionsContract } from './contracts/subscriptions.contract';
import { ClientsProviderContract } from './contracts/clients.contract';
import { ChildsProviderContract } from './contracts/childs.contract';
import { Api } from './providers/services/api';
import { SubscriptionsServiceDataProvider } from './providers/services/subscriptions.service';
import { ClientsServiceDataProvider } from './providers/services/clients.service';
import { ChildsServiceDataProvider } from './providers/services/childs.service';
import { FeedsServiceDataProvider } from './providers/services/feeds.service';
import { FeedsProviderContract } from './contracts/feeds.contract';
import { AttachmentsProviderContract } from './contracts/attachments.contract';
import { AttachmentsServiceDataProvider } from './providers/services/attachments.service';
import { EmployeesProviderContract } from './contracts/employees.contract';
import { EmployeesServiceDataProvider } from './providers/services/employees.service';
import { PackagesProviderContract } from './contracts/packages.contract';
import { PackagesServiceDataProvider } from './providers/services/packages.service';
import { BranchesProviderContract } from './contracts/branches.contract';
import { BranchesServiceDataProvider } from './providers/services/branches.service';
import { PermissionsProviderContract } from './contracts/permissions.contract';
import { PermissionsServiceDataProvider } from './providers/services/permissions.service';
import { CamerasProviderContract } from './contracts/camera.contract';
import { CamerasServiceDataProvider } from './providers/services/cameras.service';
import { UsersServiceDataProvider } from './providers/services/users.service';
import { UsersProviderContract } from './contracts/users.contract';
import { HttpClientModule } from '@angular/common/http';
import { SettingsProviderContract } from './contracts/settings.contract';
import { SettingsServiceDataProvider } from './providers/services/settings.service';
import { AccountsServiceDataProvider } from './providers/services/accounts.service';
import { AccountsProviderContract } from './contracts/accounts.contract';
import { DashboardProviderContract } from './contracts/dashboard.contract';
import { DashboardServiceDataProvider } from './providers/services/dashboard.service';
import { ChatsProviderContract } from './contracts/chats.contract';
import { ChatsServiceDataProvider } from './providers/services/chats.service';

@NgModule({
  declarations: [
    DataComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    Api,
    {provide: SubscriptionsContract, useClass: SubscriptionsServiceDataProvider},
    {provide: ClientsProviderContract, useClass: ClientsServiceDataProvider},
    {provide: ChildsProviderContract, useClass: ChildsServiceDataProvider},
    {provide: FeedsProviderContract, useClass: FeedsServiceDataProvider},
    {provide: AttachmentsProviderContract, useClass: AttachmentsServiceDataProvider},
    {provide: EmployeesProviderContract, useClass: EmployeesServiceDataProvider},
    {provide: PackagesProviderContract, useClass: PackagesServiceDataProvider},
    {provide: BranchesProviderContract, useClass: BranchesServiceDataProvider},
    {provide: PermissionsProviderContract, useClass: PermissionsServiceDataProvider},
    {provide: CamerasProviderContract, useClass: CamerasServiceDataProvider},
    {provide: UsersProviderContract, useClass: UsersServiceDataProvider},
    {provide: SettingsProviderContract, useClass: SettingsServiceDataProvider},
    {provide: AccountsProviderContract, useClass: AccountsServiceDataProvider},
    {provide: DashboardProviderContract, useClass: DashboardServiceDataProvider},
    {provide: ChatsProviderContract, useClass: ChatsServiceDataProvider}
  ]
})
export class DataModule { }
