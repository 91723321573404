import { BaseModel } from './_base.model';

export class RoleModel extends BaseModel {
	RoleId: number;
	DName1: string;
	DName2: string;
	Modules?: Modules[] = [];
	isCoreRole?: boolean;
}
export class Modules {
	ModuleId: number;
	DName1: string;
	DName2: string;
	Permissions: Permissions[] = [];
	isSelected?: boolean;
}
export class Permissions {
	PermissionId: number;
	Code: string;
	DName1: string;
	DName2: string;
	ModuleId: number;
    IsInRole: boolean;
}
