import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessengerComponent } from './messenger.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CoreModule } from '../../../../../core/core.module';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatButtonModule } from '@angular/material';
// import { AngularFireDatabaseModule } from 'angularfire2/database';

@NgModule({
	imports: [CommonModule, CoreModule, PerfectScrollbarModule, FormsModule, NgxSpinnerModule, MatButtonModule, /* AngularFireDatabaseModule */],
	declarations: [
		MessengerComponent
	],
	exports: [MessengerComponent]
})
export class MessengerModule {}
