import { BaseModel } from "./_base.model";
import { BranchModel } from "./branch.model";
import { Role, EmployeeModel } from './employee.model';

export class PermissionModel extends BaseModel {
	Id: number;
	User: EmployeeModel = new EmployeeModel();
	Role: Role = new Role();
	Branch: BranchModel = new BranchModel();
	UserId: string;
	RoleId: number;
	BranchId: number;
	isSelected?: boolean;
}
