import { Component, OnInit } from '@angular/core';
import { DataGridHelper } from '../../../crm/grid.helper';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import { MatDialog } from '@angular/material';
import {
	LayoutUtilsService,
	MessageType
} from '../../../../layout/utils/layout-utils.service';
import { Observable, of } from 'rxjs';
import { QueryParamsModel } from '../../../../../models/query-models/query-params.model';
import { QueryResultsModel } from '../../../../../models/query-models/query-results.model';
import { mergeMap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { SettingsRepository } from '../../../../../data/repositories/settings.repository';
import {
	AttachmentsTypesModel,
	Emoji
} from '../../../../../models/settings.model';
import { EmojisDetailsComponent } from '../emojis-details/emojis-details.component';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
@Component({
	templateUrl: './emojis-all.component.html',
	styleUrls: ['./emojis-all.component.scss'],
	providers: [SettingsRepository]
})
export class EmojisAllComponent extends DataGridHelper implements OnInit {
	displayedColumns = ['EmojiId', 'DName1', 'DName2', 'Content', 'Delete'];
	constructor(
		public dialog: MatDialog,
		private subheaderService: SubheaderService,
		public settingsRepository: SettingsRepository,
		public spinner: NgxSpinnerService,
		public layoutUtilsService: LayoutUtilsService,
		public authService: AuthenticationService

	) {
		super(spinner, layoutUtilsService,authService);
		this.dataGridSource = settingsRepository;
	}

	find(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		return this.settingsRepository.getAllEmojis().pipe(
			mergeMap(res => {
				const result = this.baseFilter(res, queryParams);
				return of(result);
			})
		);
	}
	ngOnInit() {
		this.initializeDataGrid();
		this.loadList();
		this.subheaderService.setBreadcrumbs([
			{ title: 'أنواع الرموز التعبيرية' },
			{ title: 'كل أنواع الرموز', page: '/settings/emojis/all' }
		]);
	}

	/** FILTRATION */
	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;
		filter.DName1 = searchText;
		filter.DName2 = searchText;
		return filter;
	}

	addEmoji() {
		const emoji = new Emoji();
		this.editEmoji(emoji);
	}
	editEmoji(emoji: Emoji) {
		const _saveMessage = 'تم الحفظ بنجاح';
		const dialogRef = this.dialog.open(EmojisDetailsComponent, {
			data: { emoji }
		});
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}
			console.log(res);
			this.layoutUtilsService.showActionNotification(
				_saveMessage,
				4000,
				null,
				true,
				false
			);

			this.loadList();
		});
	}
	deleteEmoji(Id: number) {
    this.settingsRepository.deleteEmoji(Id).subscribe(result => this.loadList());
	}
}
