import { Injectable } from '@angular/core';
import { Api } from './api';
import { Observable, Subscription, of } from 'rxjs';
import { HttpUtilsService } from '../../../core/services/http-utils.service';
import { QueryParamsModel } from '../../../models/query-models/query-params.model';
import { QueryResultsModel } from '../../../models/query-models/query-results.model';
import { mergeMap } from 'rxjs/operators';
import { FeedModel } from '../../../models/feed.model';
import { FeedsProviderContract } from '../../contracts/feeds.contract';

@Injectable({
  providedIn: 'root'
})
export class FeedsServiceDataProvider implements FeedsProviderContract  {
  getFeedInteractions(feedId: number, interactionType: number): Observable<any> {
    return this.api.get(`Feeds/GetInteractions?feedId=${feedId}&type=${interactionType}`);
  }
  insert(entity: FeedModel): Observable<any> {
    return this.api.post('Feeds/Add', entity);
  }
  get(id: number): Observable<FeedModel> {
    return this.api.get(`Feeds/GetFeed?feedId=${id}`);
  }
  getAll(): Observable<FeedModel[]> {
    return this.api.get('Feeds/GetAll');
  }
  delete(id: number): Observable<any>  {
    throw new Error("Method not implemented.");
  }
  update(entity: FeedModel):  Observable<any> {
    return this.api.post('Feeds/Update', entity);
  }


  constructor(private api: Api) { }

}
