import { Injectable } from "@angular/core";
import { Api } from "./api";
import { Observable } from "rxjs";
import { AttachmentsProviderContract } from '../../contracts/attachments.contract';
import { AttachmentModel, AttachmentType } from '../../../models/attachment.model';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
	providedIn: "root"
})
export class AttachmentsServiceDataProvider implements AttachmentsProviderContract {


	getAttachmentTypes(entityType: number): Observable<AttachmentType[]> {
		return this.api.get(`Attachments/GetAttachmentTypes?entityType=${entityType}`);
	}
	uploadAttachment(file: FormData, entityId:number, description:string, attachmentTypeId:number, entityType:number): Observable<any> {
		return this.api.post(`Attachments/Upload?EntityId=${entityId}&Description=${description}&AttachmentTypeId=${attachmentTypeId}&EntityType=${entityType}`, file,{ headers: new HttpHeaders() });
    }
    getAttachments(entityId:number, entityType:number): Observable<AttachmentModel[]>{
        return this.api.get(`Attachments/Get?entityId=${entityId}&entityType=${entityType}`);
    }
	get(id: number): Observable<any> {
		throw new Error("Method not implemented.");
	}
	getAll(): Observable<AttachmentModel[]> {
		return;
	}
	delete(id: number): Observable<any> {
		return this.api.delete(`Attachments/DeleteAttachment?attachmentId=${id}`);
	}
	update(entity): Observable<any> {
		return;
    }
    insert(entity): Observable<any>{
        return;
	}

	constructor(private api: Api) {}
}
