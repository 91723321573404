import { Component, OnInit } from "@angular/core";
import { SubheaderService } from "../../../../../../core/services/layout/subheader.service";
import { FeedsRepository } from "../../../../../../data/repositories/feeds.repository";
import { DataGridHelper } from "../../../grid.helper";
import { QueryParamsModel } from '../../../../../../models/query-models/query-params.model';
import { Observable, of } from 'rxjs';
import { QueryResultsModel } from '../../../../../../models/query-models/query-results.model';
import { mergeMap } from 'rxjs/operators';
import { FeedModel } from '../../../../../../models/feed.model';
import { MessageType, LayoutUtilsService } from '../../../../../layout/utils/layout-utils.service';
import { FeedsDetailsComponent } from '../feeds-details/feeds-details.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../../../../../../core/auth/authentication.service';

@Component({
	selector: "m-feeds-all",
	templateUrl: "./feeds-all.component.html",
	styleUrls: ["./feeds-all.component.scss"],
	providers: [FeedsRepository]
})
export class FeedsAllComponent extends DataGridHelper implements OnInit {
	displayedColumns = [
		"FeedId",
		"DName1",
		"DName2",
		"NumberOfLikes",
		"NumberOfComments",
		"PublishedByUserName",
		"PublishedAt"
	];
	constructor(
		private subheaderService: SubheaderService,
		public feedsRepository: FeedsRepository,
		public dialog: MatDialog,
		public snackBar: MatSnackBar,
		public layoutUtilsService: LayoutUtilsService,
		public spinner: NgxSpinnerService,
		public authService: AuthenticationService


	) {
		super(spinner,layoutUtilsService,authService);
		this.dataGridSource = feedsRepository;
		this.subheaderService.setBreadcrumbs([
			{ title: " الإعلانات" },
			{ title: "صفحة المشاركات", page: "/crm/ads/feeds/all" }
		]);
	}
	find(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		return this.feedsRepository.getAll().pipe(
		  mergeMap(res => {
			const result = this.baseFilter(res, queryParams);
			return of(result);
		  })
		);
		}
	ngOnInit() {
		this.initializeDataGrid();
		this.loadList();
  }
  
  filterConfiguration(): any {
    const filter: any = {};
    const searchText: string = this.searchInput.nativeElement.value;
  
    filter.Content = searchText;
    filter.PublishedByUserName = searchText;
    return filter;
  }
  addFeed(){
		var c = new FeedModel();
		this.editFeed(c);
	}
	editFeed(feed: FeedModel) {
		const feedId = feed.FeedId;
		const dialogRef = this.dialog.open(FeedsDetailsComponent, { data: { feedId } });
		dialogRef.afterClosed().subscribe(res => {
			// if (!res) {
			// 	return;
			// }
			this.loadList();
		});
	}
}
