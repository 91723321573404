import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DashboardRepository } from '../../../../../../data/repositories/dashboard.repository';
import { SubscriptionsDashboardModel } from '../../../../../../models/dashboard.model';
import { BaseComponent } from '../../../../../../core/base/base.component';
import { SubheaderService } from '../../../../../../core/services/layout/subheader.service';
import { LayoutUtilsService } from '../../../../../layout/utils/layout-utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../../../../../../core/auth/authentication.service';
import { TypesUtilsService } from '../../../../../layout/utils/types-utils.service';

@Component({
  selector: 'm-stat',
  templateUrl: './stat.component.html',
  styleUrls: ['./stat.component.scss'],
  providers: [DashboardRepository]
})
export class StatComponent extends BaseComponent implements OnInit {
  summary: SubscriptionsDashboardModel = new SubscriptionsDashboardModel();
  constructor(
    public dashboardRepository: DashboardRepository,
    public subheaderService: SubheaderService,
    public layoutUtilsService: LayoutUtilsService,
    public ref: ChangeDetectorRef,
    public spinner: NgxSpinnerService,
    public typesUtilsService: TypesUtilsService,
    public authService: AuthenticationService,
  ) {
    super(
      subheaderService,
      layoutUtilsService,
      ref,
      spinner,
      typesUtilsService,
      authService
    );
  }

  async ngOnInit() {
    this.summary = await this.async(
      this.dashboardRepository.getSubscriptionsSummary()
    );
    this.refreshView();
    console.log(this.summary);
  }

}
