import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'm-crm',
	templateUrl: './crm.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CrmComponent { }


