import { Component, OnInit } from '@angular/core';
import {
	LayoutUtilsService,
	MessageType
} from '../../../../layout/utils/layout-utils.service';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import { QueryParamsModel } from '../../../../../models/query-models/query-params.model';
import { QueryResultsModel } from '../../../../../models/query-models/query-results.model';
import { MatDialog } from '@angular/material';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { DataGridHelper } from '../../../crm/grid.helper';
import { EmployeesRepository } from '../../../../../data/repositories/employees.repository';
import { EmployeeModel } from '../../../../../models/employee.model';
import { EmployeesDetailsComponent } from '../employees-details/employees-details.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';

@Component({
	selector: 'm-employees-all',
	templateUrl: './employees-all.component.html',
	styleUrls: ['./employees-all.component.scss'],
	providers: [EmployeesRepository]
})
export class EmployeesAllComponent extends DataGridHelper implements OnInit {
	displayedColumns = [
		'EmployeeId',
		'Picture',
		'FirstName',
		'PhoneNumber',
		'IdentityNumber',
		'Gender',
		'Category',
		'Email'
	];
	dataGridSource: EmployeesRepository;
	searchInput: any;

	constructor(
		public dialog: MatDialog,
		private subheaderService: SubheaderService,
		public employeesRepository: EmployeesRepository,
		public layoutUtilsService: LayoutUtilsService,
		public spinner: NgxSpinnerService,
		public authService: AuthenticationService


	) {
		super(spinner, layoutUtilsService,authService);
		this.dataGridSource = employeesRepository;
	}
	find(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		return this.employeesRepository.getAll().pipe(
			mergeMap(res => {
				const result = this.baseFilter(res, queryParams);
				return of(result);
			})
		);
	}
	ngOnInit() {
		this.initializeDataGrid();
		this.loadList();
		console.log('init');
		this.subheaderService.setBreadcrumbs([
			{ title: 'شؤون الموظفين' },
			{ title: 'كل الموظفين', page: '/hcm/employees/all' }
		]);

		console.log(this.employeesRepository);
	}

	/** FILTRATION */
	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;

		filter.FirstName = searchText;
		filter.PhoneNumber = searchText;
		filter.IdentityNumber = searchText;
		return filter;
	}

	addEmployee() {
    const c = new EmployeeModel();
    this.editEmployee(c);
  }
	editEmployee(employee: EmployeeModel) {
		let saveMessageTranslateParam =
		employee.EmployeeId > 0 ? 'UPDATE_MESSAGE' : 'ADD_MESSAGE';
		const _saveMessage = 'تم الحفظ بنجاح';
		const _messageType =
		employee.EmployeeId > 0 ? MessageType.Success : MessageType.Success;
		const dialogRef = this.dialog.open(EmployeesDetailsComponent, {
			data: { employee }
		});
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}
			console.log(res);
			this.layoutUtilsService.showActionNotification(
				_saveMessage,
				_messageType,
				4000,
				true,
				false
			);

			this.loadList();
		});
	}
}
