import { Component, OnInit } from '@angular/core';
import { DataGridHelper } from '../../../crm/grid.helper';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import { MatDialog } from '@angular/material';
import {
	LayoutUtilsService,
	MessageType
} from '../../../../layout/utils/layout-utils.service';
import { Observable, of } from 'rxjs';
import { QueryParamsModel } from '../../../../../models/query-models/query-params.model';
import { QueryResultsModel } from '../../../../../models/query-models/query-results.model';
import { mergeMap } from 'rxjs/operators';
import { PackagesRepository } from '../../../../../data/repositories/packages.repository';
import { PackageModel } from '../../../../../models/package.model';
import { PackagesDetailsComponent } from '../packages-details/packages-details.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';

@Component({
	selector: 'm-packages-all',
	templateUrl: './packages-all.component.html',
	styleUrls: ['./packages-all.component.scss'],
	providers: [PackagesRepository]
})
export class PackagesAllComponent extends DataGridHelper implements OnInit {
	displayedColumns = [
		'PackageId',
		'DName1',
		'Description',
		'AvailableFrom',
		'AvailableTo',
		'Amount',
		'Branch.DName1',
		'ServiceName',
		'IsQurrah'
	];
	constructor(
		public dialog: MatDialog,
		private subheaderService: SubheaderService,
		public packagesRepository: PackagesRepository,
		public layoutUtilsService: LayoutUtilsService,
		public spinner: NgxSpinnerService,
		public authService: AuthenticationService

	) {
		super(spinner, layoutUtilsService, authService);
		this.dataGridSource = packagesRepository;
	}

	find(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		return this.packagesRepository.getAll().pipe(
			mergeMap(res => {
				const result = this.baseFilter(res, queryParams);
				return of(result);
			})
		);
	}
	ngOnInit() {
		this.initializeDataGrid();
		this.loadList();
		this.subheaderService.setBreadcrumbs([
			{ title: 'الباقات' },
			{ title: 'كل الباقات', page: '/settings/packages/all' }
		]);
	}

	/** FILTRATION */
	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;

		filter.ServiceName = searchText;
		filter.BranchName = searchText;
		filter.DName1 = searchText;
		return filter;
	}

	addPakage() {
		const p = new PackageModel();
		this.editPakage(p);
	}
	editPakage(packageModel: PackageModel) {
		const saveMessageTranslateParam =
			packageModel.Id > 0 ? 'UPDATE_MESSAGE' : 'ADD_MESSAGE';
		const _saveMessage = 'تم الحفظ بنجاح';
		const _messageType =
			packageModel.Id > 0 ? MessageType.Success : MessageType.Success;
		const dialogRef = this.dialog.open(PackagesDetailsComponent, {
			data: { packageModel }
		});
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}
			console.log(res);
			this.layoutUtilsService.showActionNotification(
				_saveMessage,
				_messageType,
				4000,
				true,
				false
			);

			this.loadList();
		});
	}
}
