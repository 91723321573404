import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {
	LayoutUtilsService,
	MessageType
} from '../../../../layout/utils/layout-utils.service';
import { AttachmentsRepository } from '../../../../../data/repositories/Attachments.repository';
import { AttachmentModel } from '../../../../../models/attachment.model';
import { QueryParamsModel } from '../../../../../models/query-models/query-params.model';
import { QueryResultsModel } from '../../../../../models/query-models/query-results.model';
import { mergeMap } from 'rxjs/operators';
import { EmployeeModel } from '../../../../../models/employee.model';
import { EmployeesRepository } from '../../../../../data/repositories/employees.repository';
import { DataGridHelper } from '../../../crm/grid.helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChildsRepository } from '../../../../../data/repositories/childs.repository';
import { AgeCategoryModel } from '../../../../../models/age-category.model';
import { BaseComponent } from '../../../../../core/base/base.component';
import { TypesUtilsService } from '../../../../layout/utils/types-utils.service';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';

@Component({
	selector: 'm-employee-details',
	templateUrl: './employees-details.component.html',
	styleUrls: ['./employees-details.component.scss'],
	providers: [EmployeesRepository]
})
export class EmployeesDetailsComponent extends BaseComponent
	implements OnInit {
	loadingSubject = new BehaviorSubject<boolean>(false);
	loading$ = this.loadingSubject.asObservable();
	isNewOrEdit: boolean;
	employeeForm: FormGroup;
	employee: EmployeeModel = new EmployeeModel();
	employeeAttachments: AttachmentModel[] = [];
	withClose: boolean;
	hasFormErrors: boolean = false;
	viewLoading: boolean = false;
	loadingAfterSubmit: boolean = false;
	categories: AgeCategoryModel[] = [];
	errorMessage: string = '';
	displayedColumns = [
		'select',
		'AttachmentId',
		'AttachmentName',
		'PublishedAt',
		'PublishedBy',
		'Actions'
	];
	constructor(
		private clientFB: FormBuilder,
		public subheaderService: SubheaderService,
		public childsRepository: ChildsRepository,
		public employeesRepository: EmployeesRepository,
		public attachmentsRepository: AttachmentsRepository,
		public layoutUtilsService: LayoutUtilsService,
		public spinner: NgxSpinnerService,
		public dialogRef: MatDialogRef<EmployeesDetailsComponent>,
		public typesUtilsService: TypesUtilsService,
		public authService: AuthenticationService,
		public ref: ChangeDetectorRef,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		super(
			subheaderService,
			layoutUtilsService,
			ref,
			spinner,
			typesUtilsService,
			authService
		);
	}

	ngOnInit() {
		this.employee = this.data.employee;
		if (this.employee.EmployeeId) {
			this.childsRepository.getCategories().subscribe(result => {
				this.categories = result;
			});
			// this.attachmentsRepository.getAttachments(15, 5).subscribe(res => {
			// 	this.employeeAttachments = res;
			// });
		}
		this.createForm();
	}
	createForm() {
		this.employeeForm = this.clientFB.group({
			gender: [this.employee.Gender, Validators.required],
			firstName: [this.employee.FirstName, Validators.required],
			lastName: [this.employee.LastName, Validators.required],
			midName: [this.employee.MidName, Validators.required],
			identityNumber: [this.employee.IdentityNumber, Validators.required],
			phoneNumber: [this.employee.PhoneNumber, Validators.required],
			email: [this.employee.Email, Validators.required],
			category: [this.employee.AgeCategoryId]
		});
	}

	/** UI */
	getTitle(): string {
		if (this.employee.EmployeeId > 0) {
			return `ملف موظف:  '${this.employee.FirstName} ${
				this.employee.LastName
			}'`;
		}

		return 'تسجيل موظف جديد';
	}

	isControlInvalid(controlName: string): boolean {
		const control = this.employeeForm.controls[controlName];
		const result = control.invalid && control.touched;
		return result;
	}

	/** ACTIONS */
	prepareClient(): EmployeeModel {
		const controls = this.employeeForm.controls;
		const _Employee = new EmployeeModel();
		_Employee.EmployeeId = this.employee.EmployeeId;
		_Employee.FirstName = controls['firstName'].value;
		_Employee.MidName = controls['midName'].value;
		_Employee.LastName = controls['lastName'].value;
		_Employee.Email = controls['email'].value;
		_Employee.Gender = controls['gender'].value;
		_Employee.IdentityNumber = controls['identityNumber'].value;
		_Employee.PhoneNumber = controls['phoneNumber'].value;
		_Employee.AgeCategoryId = controls['category'].value;
		_Employee.Language = '1';
		// console.log(_Employee);
		return _Employee;
	}

	onSubmit(withClose: boolean = false) {
		this.withClose = withClose;
		this.hasFormErrors = false;
		this.errorMessage = '';
		this.loadingAfterSubmit = false;
		const controls = this.employeeForm.controls;
		/** check form */
		if (this.employeeForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			return;
		}

		const editedEmployee = this.prepareClient();
		if (editedEmployee.EmployeeId) {
			this.updateEmployee(editedEmployee);
		} else {
			this.createEmployee(editedEmployee);
		}
	}

	saveCompleted() {
		if (!this.withClose) {
			this.layoutUtilsService.showActionNotification(
				'تم الحفظ بنجاح',
				MessageType.Success,
				4000,
				true,
				false
			);
			return;
		}
		this.dialogRef.close({
			isEdit: true
		});
	}

	async updateEmployee(_Employee: EmployeeModel) {
		await this.async(this.employeesRepository.update(_Employee));
		if (this.hasErrors) {return; }
		this.saveCompleted();
	}

	async createEmployee(_Employee: EmployeeModel) {
		await this.async(this.employeesRepository.insert(_Employee));
		if (this.hasErrors) {return; }
		this.saveCompleted();
	}

	onAlertClose($event) {
		this.hasFormErrors = false;
	}
	async refreshDetails() {
		console.log('refresh');
		this.employee = await this.async(this.employeesRepository.get(this.employee.EmployeeId));
		}
}
