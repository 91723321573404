import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';

@Component({
  selector: 'm-clients-reviews',
  templateUrl: './clients-reviews.component.html',
  styleUrls: ['./clients-reviews.component.scss']
})
export class ClientsReviewsComponent implements OnInit {
constructor(
  public dialog: MatDialog,
  private route: ActivatedRoute,
  private subheaderService: SubheaderService
  ) { }

/** LOAD DATA */
ngOnInit() {

  // Set title to page breadCrumbs
  this.subheaderService.setBreadcrumbs([
    { title: ' العملاء' },
    { title: 'تقييمات العملاء',  page: '/crm/clients/reviews' }
  ]);

}

}
