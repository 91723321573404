import { Component, OnInit } from '@angular/core';
import { EmployeesRepository } from '../../../../../../data/repositories/employees.repository';
import { DataGridHelper } from '../../../../crm/grid.helper';
import { MatDialog } from '@angular/material';
import { SubheaderService } from '../../../../../../core/services/layout/subheader.service';
import { LayoutUtilsService, MessageType } from '../../../../../layout/utils/layout-utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../../../../../../core/auth/authentication.service';
import { QueryParamsModel } from '../../../../../../models/query-models/query-params.model';
import { Observable, of } from 'rxjs';
import { QueryResultsModel } from '../../../../../../models/query-models/query-results.model';
import { mergeMap } from 'rxjs/operators';
import { EmployeeModel } from '../../../../../../models/employee.model';
import { EmployeesDetailsComponent } from '../../../../hcm/employees/employees-details/employees-details.component';
import { UserDetailsComponent } from '../user-edit/user-edit.component';

@Component({
	selector: 'm-users-all',
	templateUrl: './users-all.component.html',
	providers: [EmployeesRepository]
})
export class UsersAllComponent extends DataGridHelper implements OnInit {
	displayedColumns = [
		'EmployeeId',
		'Picture',
		'FirstName',
		'PhoneNumber',
		'IdentityNumber',
		'Gender',
		'Category',
		'Email',
		'Actions'
	];
	dataGridSource: EmployeesRepository;
	searchInput: any;

	constructor(
		public dialog: MatDialog,
		private subheaderService: SubheaderService,
		public employeesRepository: EmployeesRepository,
		public layoutUtilsService: LayoutUtilsService,
		public spinner: NgxSpinnerService,
		public authService: AuthenticationService


	) {
		super(spinner, layoutUtilsService,authService);
		this.dataGridSource = employeesRepository;
	}
	find(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		return this.employeesRepository.getAll().pipe(
			mergeMap(res => {
				const result = this.baseFilter(res, queryParams);
				return of(result);
			})
		);
	}
	ngOnInit() {
		this.initializeDataGrid();
		this.loadList();
		console.log('init');
		this.subheaderService.setBreadcrumbs([
			{ title: 'المستخدمين' },
			{ title: 'كل المستخدمين', page: '/settings/users/all' }
		]);

		console.log(this.employeesRepository);
	}

	/** FILTRATION */
	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;

		filter.FirstName = searchText;
		filter.PhoneNumber = searchText;
		filter.IdentityNumber = searchText;
		return filter;
	}

	addEmployee() {
    const c = new EmployeeModel();
    this.editEmployee(c);
  }
	editEmployee(employee: EmployeeModel) {
		const dialogRef = this.dialog.open(UserDetailsComponent, {
			data: { employee }
		});
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}
			console.log(res);
			this.layoutUtilsService.showActionNotification(
				'تم الحفظ بنجاح',
				MessageType.Success,
				4000,
				true,
				false
			);

			this.loadList();
		});
	}

	async deleteEmployee(employeeId: number) {
		const dialogRef = this.layoutUtilsService.showConfirmationDialog('حذف مستخدم', 'هل أنت متأكد من أنك تريد حذف المستخدم؟');
		dialogRef.afterClosed().subscribe(async res => {
			if (!res) {
				return;
			}
			await this.async(this.employeesRepository.delete(employeeId));
			if (this.hasErrors) { return; }
			this.loadList();

		});
	}	
}
