import {
	Component,
	OnInit,
	ElementRef,
	ViewChild,
	ChangeDetectionStrategy,
	OnDestroy
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// Material
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// RXJS
import {
	debounceTime,
	distinctUntilChanged,
	tap,
	mergeMap
} from 'rxjs/operators';
import {
	fromEvent,
	merge,
	observable,
	Observable,
	Subscription,
	of
} from 'rxjs';
// Services
import {
	LayoutUtilsService,
	MessageType
} from '../../../../layout/utils/layout-utils.service';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import { ClientsRepository } from '../../../../../data/repositories/clients.repository';
import { DataGridHelper } from '../../grid.helper';
import { SubscriptionsRepository } from '../../../../../data/repositories/subscriptions.repository';
import { ClientModel } from '../../../../../models/client.model';
import { ClientsDetailsComponent } from '../clients-details/clients-details.component';
import { QueryParamsModel } from '../../../../../models/query-models/query-params.model';
import { QueryResultsModel } from '../../../../../models/query-models/query-results.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
// Models

@Component({
	selector: 'm-clients-list',
	templateUrl: './clients-all.component.html',
	styleUrls: ['./clients-all.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [SubscriptionsRepository, ClientsRepository]
})
export class ClientsAllComponent extends DataGridHelper implements OnInit, OnDestroy {

	constructor(
		public dialog: MatDialog,
		private subheaderService: SubheaderService,
		public clientsRepository: ClientsRepository,
		public layoutUtilsService: LayoutUtilsService,
		public spinner: NgxSpinnerService,		
		public authService: AuthenticationService
	) {
		super(spinner, layoutUtilsService,authService);
		this.dataGridSource = clientsRepository;
	}
	displayedColumns = [
		'ClientId',
		'Picture',
		'FirstName',
		'PhoneNumber',
		'IdentityNumber',
		'Gender',
		'ChildrensCount'
	];
	ngOnDestroy(): void {
	}
	find(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		return this.clientsRepository.getAll().pipe(
			mergeMap(res => {
				const result = this.baseFilter(res, queryParams);
				return of(result);
			})
		);
	}
	ngOnInit() {
		this.initializeDataGrid();
		this.loadList();
		console.log('init');
		this.subheaderService.setBreadcrumbs([
			{ title: ' العملاء' },
			{ title: 'كل العملاء', page: '/crm/clients/all' }
		]);

		console.log(this.clientsRepository);
	}

	/** FILTRATION */
	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;
		filter.FullName = searchText;
		filter.PhoneNumber = searchText;
		filter.IdentityNumber = searchText;
		return filter;
	}

	addClient() {
		var c = new ClientModel();
		this.editClient(c);
	}
	editClient(client: ClientModel) {
		const _saveMessage = 'تم الحفظ بنجاح';
		const dialogRef = this.dialog.open(ClientsDetailsComponent, {
			data: { client }
		});
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}
			console.log(res);
			this.layoutUtilsService.showActionNotification(
				_saveMessage,
				MessageType.Success,
				4000,
				true,
				false
			);

			this.loadList();
		});
	}
}
