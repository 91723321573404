import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {
	LayoutUtilsService,
	MessageType
} from '../../../../layout/utils/layout-utils.service';
import { QueryParamsModel } from '../../../../../models/query-models/query-params.model';
import { QueryResultsModel } from '../../../../../models/query-models/query-results.model';
import { mergeMap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseComponent } from '../../../../../core/base/base.component';
import { TypesUtilsService } from '../../../../layout/utils/types-utils.service';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { AccountsRepository } from '../../../../../data/repositories/accounts.repository';
import { ExpensesModel, ExpenseType } from '../../../../../models/accounts.model';
import { BranchModel } from '../../../../../models/branch.model';
import { BranchesRepository } from '../../../../../data/repositories/branches.repository';

@Component({
	selector: 'm-expenses-details',
	templateUrl: './expenses-details.component.html',
	styleUrls: ['./expenses-details.component.scss'],
	providers: [AccountsRepository]
})
export class ExpensesDetailsComponent extends BaseComponent
	implements OnInit {
	hasFormErrors: boolean = false;
	viewLoading: boolean = false;
	loadingAfterSubmit: boolean = false;
	withClose: boolean;
	errorMessage: string = '';
	expense: ExpensesModel = new ExpensesModel();
	expenseForm: FormGroup;
	allExpensesTypes: ExpenseType[] = [];
	allBranches: BranchModel[] = [];
	constructor(
		private expenseFB: FormBuilder,
		public subheaderService: SubheaderService,
		public accountsRepository: AccountsRepository,
		public branchesRepository: BranchesRepository,
		public layoutUtilsService: LayoutUtilsService,
		public spinner: NgxSpinnerService,
		public dialogRef: MatDialogRef<ExpensesDetailsComponent>,
		public typesUtilsService: TypesUtilsService,
		public authService: AuthenticationService,
		public ref: ChangeDetectorRef,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		super(
			subheaderService,
			layoutUtilsService,
			ref,
			spinner,
			typesUtilsService,
			authService
		);
	}

	ngOnInit() {
		this.expense = this.data.expense;
		this.branchesRepository.getAll().subscribe(result => {
			this.allBranches = result;
		});
		this.accountsRepository.getExpensesTypes().subscribe(result => {
			this.allExpensesTypes = result;
		});
		this.createForm();
	}
	createForm() {
		this.expenseForm = this.expenseFB.group({
			date: [this.expense.Date, Validators.required],
			notes: [this.expense.Notes, Validators.required],
			amount: [this.expense.Amount, Validators.required],
			paymentMethod: [this.expense.PaymentMethodId, Validators.required],
			branch: [this.expense.BranchId, Validators.required],
			expenseType: [this.expense.ExpenseTypeId, Validators.required]
		});
	}

	/** UI */
	getTitle(): string {
		if (this.expense.ExpenseId > 0) {
			return 'تفاصيل المصروف';
		}

		return 'إضافة مصروف';
	}

	isControlInvalid(controlName: string): boolean {
		const control = this.expenseForm.controls[controlName];
		const result = control.invalid && control.touched;
		return result;
		return true;
	}

	/** ACTIONS */
	prepareExpense(): ExpensesModel {
		const controls = this.expenseForm.controls;
		const expense = new ExpensesModel();
		expense.ExpenseId = this.expense.ExpenseId;
		expense.Date = controls['date'].value;
		expense.Notes = controls['notes'].value;
		expense.Amount = controls['amount'].value;
		expense.PaymentMethodId = controls['paymentMethod'].value;
		expense.BranchId = controls['branch'].value;
		expense.ExpenseTypeId = controls['expenseType'].value;
		console.log(expense);
		return expense;
	}

	onSubmit(withClose: boolean = false) {
		this.withClose = withClose;
		this.hasFormErrors = false;
		this.errorMessage = '';
		this.loadingAfterSubmit = false;
		const controls = this.expenseForm.controls;
		/** check form */
		if (this.expenseForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			return;
		}

		const editedExpense = this.prepareExpense();
		if (editedExpense.ExpenseId) {
			this.updateExpense(editedExpense);
		} else {
			this.createExpense(editedExpense);
		}
	}

	saveCompleted() {
		if (!this.withClose) {
			this.layoutUtilsService.showActionNotification(
				'تم الحفظ بنجاح',
				MessageType.Success,
				4000,
				true,
				false
			);
			return;
		}
		this.dialogRef.close({
			isEdit: true
		});
	}

	async updateExpense(expense: ExpensesModel) {
		await this.async(this.accountsRepository.update(expense));
		if (this.hasErrors) { return; }
		this.saveCompleted();
	}

	async createExpense(expense: ExpensesModel) {
		await this.async(this.accountsRepository.insert(expense));
		if (this.hasErrors) { return; }
		this.saveCompleted();
	}

	onAlertClose($event) {
		this.hasFormErrors = false;
	}
}
