import { Injectable } from "@angular/core";
import { Api } from "./api";
import { Observable } from "rxjs";
import { PackagesProviderContract } from "../../contracts/packages.contract";
import { PackageModel, Service } from "../../../models/package.model";

@Injectable({
	providedIn: "root"
})
export class PackagesServiceDataProvider implements PackagesProviderContract {
	insert(entity: PackageModel): Observable<any> {
		return this.api.post("Packages/Add", entity);
	}
	get(id: number): Observable<PackageModel> {
		throw new Error("Method not implemented.");
	}
	getAll(): Observable<PackageModel[]> {
		return this.api.get("Packages/GetAll");
	}
	delete(id: number): Observable<any>  {
		throw new Error("Method not implemented.");
	}
	update(entity: PackageModel): Observable<any> {
		return this.api.post("Packages/Update", entity);
	}
	getServices(): Observable<Service[]> {
		return this.api.get("Packages/GetAllServices");
	}
	getChildPackages(branchId: number, childId: number): Observable<Service[]> {
    return this.api.get(`Packages/GetAvailablePackagesForChild?branchId=${branchId}&childId=${childId}`);
  }
	constructor(private api: Api) {}
}
