import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PartialsModule } from '../../partials/partials.module';
import { CoreModule } from '../../../core/core.module';
import { MaterialPreviewModule } from '../../partials/content/general/material-preview/material-preivew.module';

import {
	MatDialogModule,
	MAT_DIALOG_DEFAULT_OPTIONS
} from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';

import {
	MatAutocompleteModule,
	MatNativeDateModule,
	MatFormFieldModule,
	MatInputModule,
	MatRadioModule,
	MatButtonModule,
	MatBadgeModule,
	MatCardModule,
	MatChipsModule,
	MatSelectModule,
	MatProgressBarModule,
	MatProgressSpinnerModule,
	MatIconModule,
	MatSliderModule,
	MatPaginatorModule,
	MatSortModule,
	MatSidenavModule,
	MatSnackBarModule,
	MatStepperModule,
	MatToolbarModule,
	MatDividerModule,
	MatTabsModule,
	MatTableModule,
	MatTooltipModule,
	MatListModule,
	MatGridListModule,
	MatButtonToggleModule,
	MatBottomSheetModule,
	MatExpansionModule,
	_MatChipListMixinBase,
	MatMenuModule,
	MatTreeModule,
	MAT_BOTTOM_SHEET_DATA,
	MatBottomSheetRef,
	MatSlideToggleModule,
	MatCheckboxModule,
	MatDatepickerModule
} from '@angular/material';

import { CrmComponent } from './crm.component';
import { SubscriptionsAllComponent } from './subscriptions/subscriptions-all/subscriptions-all.component';
import { QurrahSubscriptionsComponent } from './subscriptions/subscriptions-qurrah/subscriptions-qurrah.component';
import { ClientsAllComponent } from './clients/clients-all/clients-all.component';
import { ClientsReviewsComponent } from './clients/clients-reviews/clients-reviews.component';
import { ChildsAllComponent } from './childs/childs-all/childs-all.component';
import { ChildsSupervisorsComponent } from './childs/childs-supervisors/childs-supervisors.component';
import { ChildsDetailsComponent } from './childs/childs-details/childs-details.component';
import { SubscriptionsDetailsComponent } from './subscriptions/subscriptions-details/subscriptions-details.component';
import { SubscriptionsActiveComponent } from './subscriptions/subscriptions-active/subscriptions-active.component';
import { FeedsAllComponent } from './ads/feeds/feeds-all/feeds-all.component';
import { FeedsDetailsComponent } from './ads/feeds/feeds-details/feeds-details.component';
import { SmsNewComponent } from './ads/sms/sms-new/sms-new.component';
import { SmsAllComponent } from './ads/sms/sms-all/sms-all.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClientsDetailsComponent } from './clients/clients-details/clients-details.component';
import { LayoutModule } from '../../layout/layout.module';
import { ClientsBranchesComponent } from './clients/clients-branches/clients-branches.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ClientBranchDetailsComponent } from './clients/client-branch-details/client-branch-details.component';
import { SubscriptionDaysComponent } from './subscriptions/subscription-days/subscription-days.component';
import { ChildsCheckInsComponent } from './childs/childs-check-in/childs-check-in.component';
import { FeedsInteractionsComponent } from './ads/feeds/feeds-interactions/feeds-interactions.component';
import { VisitsAllComponent } from './subscriptions/visits-all/visits-all.component';
import { DayEventsComponent } from './subscriptions/day-events/day-events.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { SubscriptionsCancelComponent } from './subscriptions/subscriptions-cancel/subscriptions-cancel.component';
export const MY_FORMATS = {
	parse: {
		dateInput: 'DD/MM/YYYY',
	},
	display: {
		dateInput: 'YYYY/MM/DD',
		monthYearLabel: 'YYYY MMM',
		dateA11yLabel: 'YYYY/MM/DD',
		monthYearA11yLabel: 'YYYY MMM',
	},
};
const routes: Routes = [
	{
		path: '',
		component: CrmComponent,
		children: [
			{
				path: 'subscriptions/list',
				component: SubscriptionsAllComponent
			},
			{
				path: 'subscriptions/active',
				component: SubscriptionsActiveComponent
			},
			{
				path: 'subscriptions/edit/:id',
				component: SubscriptionsDetailsComponent
			},
			{
				path: 'subscriptions/edit/0/new',
				component: SubscriptionsDetailsComponent
			},
			{
				path: 'subscriptions/qurrah',
				component: QurrahSubscriptionsComponent
			},
			{
				path: 'subscriptions/visits',
				component: VisitsAllComponent
			},
			{
				path: 'subscriptions/day-events',
				component: DayEventsComponent
			},
			{
				path: 'subscriptions/cancel-subscription',
				component: SubscriptionsCancelComponent
			},
			{
				path: 'clients/all',
				component: ClientsAllComponent
			},
			{
				path: 'clients/branches',
				component: ClientsBranchesComponent
			},
			{
				path: 'clients/branches/edit',
				component: ClientBranchDetailsComponent
			},
			{
				path: 'clients/reviews',
				component: ClientsReviewsComponent
			},
			{
				path: 'clients/edit/0/new',
				component: ClientsDetailsComponent
			},
			{
				path: 'clients/edit/:id',
				component: ClientsDetailsComponent
			},
			{
				path: 'childs/all',
				component: ChildsAllComponent
			},
			{
				path: 'childs/edit/0/new',
				component: ChildsDetailsComponent
			},
			{
				path: 'childs/edit/:id',
				component: ChildsDetailsComponent
			},
			{
				path: 'childs/childs-supervisors',
				component: ChildsSupervisorsComponent
			},
			{
				path: 'childs/childs-checkins',
				component: ChildsCheckInsComponent
			},
			{
				path: 'ads/feeds/all',
				component: FeedsAllComponent
			},
			{
				path: 'ads/feeds/edit/:id',
				component: FeedsDetailsComponent
			},
			{
				path: 'feeds/edit/0/new',
				component: FeedsDetailsComponent
			},
			{
				path: 'ads/sms/all',
				component: SmsAllComponent
			},
			{
				path: 'ads/sms/new',
				component: SmsNewComponent
			}
		]
	}
];

@NgModule({
	imports: [
		// material modules
		MatInputModule,
		MatFormFieldModule,
		MatAutocompleteModule,
		MatListModule,
		MatSliderModule,
		MatCardModule,
		MatSelectModule,
		MatButtonModule,
		MatBadgeModule,
		MatIconModule,
		MatCheckboxModule,
		MatNativeDateModule,
		MatSlideToggleModule,
		MatMenuModule,
		MatTabsModule,
		MatTooltipModule,
		MatSidenavModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatTableModule,
		MatGridListModule,
		MatToolbarModule,
		MatBottomSheetModule,
		MatExpansionModule,
		MatDividerModule,
		MatSortModule,
		MatStepperModule,
		MatChipsModule,
		MatPaginatorModule,
		MatDialogModule,
		CoreModule,
		CommonModule,
		MatRadioModule,
		MatTreeModule,
		MatButtonToggleModule,
		PartialsModule,
		MaterialPreviewModule,
		FormsModule,
		ReactiveFormsModule,
		NgbModule,
		RouterModule.forChild(routes),
		LayoutModule,
		MatDatepickerModule,
		MatCardModule,
		NgxSpinnerModule,
		DragDropModule,
		MatCardModule

	],
	exports: [RouterModule],
	entryComponents: [],
	providers: [
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				panelClass: 'm-mat-dialog-container__wrapper',
				height: 'auto',
				width: '1000px'
			}
		},
		{provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
		{provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
	],
	declarations: [
		CrmComponent,
		SubscriptionsAllComponent,
		SubscriptionsDetailsComponent,
		SubscriptionsCancelComponent,
		QurrahSubscriptionsComponent,
		ClientsAllComponent,
		ClientsReviewsComponent,
		ClientsDetailsComponent,
		ClientsBranchesComponent,
		ClientBranchDetailsComponent,
		ChildsAllComponent,
		ChildsSupervisorsComponent,
		ChildsCheckInsComponent,
		ChildsDetailsComponent,
		SubscriptionsDetailsComponent,
		SubscriptionsActiveComponent,
		FeedsAllComponent,
		FeedsDetailsComponent,
		SmsAllComponent,
		SmsNewComponent,
		SubscriptionDaysComponent,
		FeedsInteractionsComponent,
		VisitsAllComponent,
		DayEventsComponent
	]
})
export class CrmModule {}
