import { Injectable } from '@angular/core';
import { Api } from './api';
import { Observable } from 'rxjs';
import { ChildModel } from '../../../models/child.model';
import { ChildsProviderContract } from '../../contracts/childs.contract';
import { SupervisorModel } from '../../../models/supervisor.model';
import { AgeCategoryModel } from '../../../models/age-category.model';
import { DayEventModel } from '../../../models/dayevent.model';

@Injectable({
	providedIn: 'root'
})
export class ChildsServiceDataProvider implements ChildsProviderContract {
	addCategory(category: AgeCategoryModel): Observable<any> {
		return this.api.post('Childs/AddCategory', category);
	}
	updateCategory(category: AgeCategoryModel): Observable<any> {
		return this.api.post('Childs/UpdateCategory', category);
	}
	deleteCategory(categoryId: number): Observable<any> {
		return this.api.delete(`Childs/DeleteCategory?categoryId=${categoryId}`);
	}
	getCheckedInChilds(date?: string): Observable<ChildModel[]> {
		if (date)
		{
			return this.api.get(`DayEvents/GetCheckedInChilds?date=${date}`);
		}
		return this.api.get(`DayEvents/GetCheckedInChilds`);

	}
	getCheckedOutChilds(date?: string): Observable<ChildModel[]> {
		if (date)
		{
			return this.api.get(`DayEvents/GetCheckedOutChilds?date=${date}`);
		}
		return this.api.get(`DayEvents/GetCheckedOutChilds`);
	}
	insert(entity: ChildModel): Observable<any> {
		return this.api.post('Childs/Register', entity);
	}
	get(id: number): Observable<ChildModel> {
		return this.api.get(`Childs/Get?childId=${id}`);
	}
	getAll(): Observable<ChildModel[]> {
		return this.api.get('Childs/GetAll');
	}
	delete(id: number): Observable<any>  {
		return this.api.delete(`Childs/Delete?childId=${id}`);
	}
	checkChildSubscribtions(childId: number): Observable<SupervisorModel[]> {
		return this.api.post(`Childs/CheckChildSubscribtion?childId=${childId}`);
	}
	update(entity: ChildModel): Observable<any> {
		return this.api.post('Childs/Update', entity);
	}
	getChildsWithoutSupervisors(): Observable<ChildModel[]> {
		return this.api.get('Childs/GetAllWithoutSupervisors');
	}
	getClientChilds(clientId: number): Observable<ChildModel[]> {
		return this.api.get(`Childs/GetClientChilds?clientId=${clientId}`);
	}
	getSupervisors(branchId?: number): Observable<SupervisorModel[]> {
		return this.api.get('Supervisors/GetAll');
	}
	getSupervisorChilds(supervisorId: number): Observable<ChildModel[]> {
		return this.api.get(`Supervisors/GetSupervisorChilds?supervisorId=${supervisorId}`);
	}
	addSupervisor(childId: number, supervisorId: number): Observable<any> {
		return this.api.post(`Supervisors/AddSupervisor?childId=${childId}&supervisorId=${supervisorId}`);
	}
	removeSupervisor(childId: number): Observable<any> {
		return this.api.post(`Supervisors/RemoveSupervisor?childId=${childId}`);
	}
	getCategories(): Observable<AgeCategoryModel[]> {
		return this.api.get('Childs/GetCategories');
	}
	public newEvent(event: DayEventModel): Observable<DayEventModel> {
		return this.api.post('DayEvents/Add', event);
	  }
	constructor(private api: Api) {}
}
