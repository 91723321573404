import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DashboardRepository } from '../../../../../../data/repositories/dashboard.repository';
import { BaseComponent } from '../../../../../../core/base/base.component';
import { SubheaderService } from '../../../../../../core/services/layout/subheader.service';
import { LayoutUtilsService } from '../../../../../layout/utils/layout-utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TypesUtilsService } from '../../../../../layout/utils/types-utils.service';
import { AuthenticationService } from '../../../../../../core/auth/authentication.service';
import { SubscriptionsPerPackages, PieChartItemModel } from '../../../../../../models/dashboard.model';
import Chart from 'chart.js';

@Component({
	selector: 'm-doughnut-chart',
	templateUrl: './doughnut-chart.component.html',
	styleUrls: ['./doughnut-chart.component.scss'],
	providers: [DashboardRepository]
})
export class DoughnutChartComponent extends BaseComponent implements OnInit {
	public packagesSummary: PieChartItemModel[] = [];
	// Doughnut
	public doughnutChartLabels: string[] = [];
	public doughnutChartData: number[] = [];
	public doughnutChartType: string = 'doughnut';

	constructor(
		public dashboardRepository: DashboardRepository,
		public subheaderService: SubheaderService,
		public layoutUtilsService: LayoutUtilsService,
		public ref: ChangeDetectorRef,
		public spinner: NgxSpinnerService,
		public typesUtilsService: TypesUtilsService,
		public authService: AuthenticationService,
	) {
		super(
			subheaderService,
			layoutUtilsService,
			ref,
			spinner,
			typesUtilsService,
			authService
		);
	}

	async ngOnInit() {
		this.packagesSummary = await this.async(this.dashboardRepository.getSubscriptionsPerPackages());
    if(this.hasErrors) return;
		console.log(this.packagesSummary);
    
		this.createChart();
		this.refreshView();
	  }

	// events
	chartClicked(e: any): void {
	}

	chartHovered(e: any): void {
	}
	createChart() {
		let ctx2 = document.getElementById('doughnutChart');
		let myDoughnutChart = new Chart(ctx2, {
		  type: 'doughnut',
		  data: {
			datasets: [
			  {
				data: this.packagesSummary.map(a=> a.Value),
				backgroundColor: [
				  'rgba(255, 99, 132)',
				  'rgba(54, 162, 235)',
				  'rgba(255, 206, 86)',
				  'rgba(60, 206, 13)',
				  'rgba(80, 72, 86)',
				  'rgba(100, 140, 90)',
				  'rgba(80, 190, 86)'
				]
			  }
			],
			labels: this.packagesSummary.map(a=> a.Name)
		  }
		});
	  }
}
