import { BaseModel } from './_base.model';
import { BranchModel } from './branch.model';

export class CameraModel extends BaseModel {
	CameraId: number;
	DName1: string;
	DName2: string;
	StreamingLink: string;
	ThumbnailPath: string;
	IsEnabled: boolean;
	Branch: BranchModel = new BranchModel();
	BranchId: number;
}
