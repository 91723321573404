import { Injectable } from "@angular/core";
import { Api } from "./api";
import { Observable } from "rxjs";
import { InvoiceModel, ExpensesModel, ExpenseType } from '../../../models/accounts.model';
import { AccountsProviderContract } from '../../contracts/accounts.contract';

@Injectable({
	providedIn: "root"
})
export class AccountsServiceDataProvider implements AccountsProviderContract {
    deleteExpense(expenseId: number): Observable<any> {
        return this.api.delete(`Expenses/DeleteExpense?expenseId=${expenseId}`);
    }
    getExpensesTypes(): Observable<ExpenseType[]> {
        return this.api.get('Expenses/GetExpensesTypes');
    }
    addExpenseType(model: ExpenseType): Observable<any> {
        return this.api.post('Expenses/AddExpenseType', model);
    }
    updateExpenseType(model: ExpenseType): Observable<any> {
        return this.api.post('Expenses/UpdateExpenseType', model);
    }
    get(id: number): Observable<ExpensesModel> {
        throw new Error("Method not implemented.");
    }
    getAll(): Observable<ExpensesModel[]> {
        return this.api.get('Expenses/GetAll');
    }
    delete(id: number): Observable<any> {
        throw new Error("Method not implemented.");
    }
    update(entity: ExpensesModel): Observable<any> {
        return this.api.post('Expenses/Update', entity);
    }
    insert(entity: ExpensesModel): Observable<any> {
        return this.api.post('Expenses/Add', entity);
    }

	getAllInvoices(): Observable<InvoiceModel[]> {
		return this.api.get('Invoices/GetAll');
    }

	constructor(private api: Api) {}
}
