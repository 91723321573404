import { Injectable } from '@angular/core';
import { BaseRepository } from './_base.repository';
import { Observable } from 'rxjs';
import { DashboardProviderContract } from '../contracts/dashboard.contract';
import { SubscriptionsDashboardModel } from '../../models/dashboard.model';

@Injectable({
    providedIn: 'root'
})
export class DashboardRepository extends BaseRepository {


    constructor(private provider: DashboardProviderContract) {
        super();
        this.dataProvider = provider;
    }
    getSubscriptionsSummary(): Observable<SubscriptionsDashboardModel> {
        return this.provider.getSubscriptionsSummary();
    }
    getFinancialStatus(): Observable<any> {
        return this.provider.getFinancialStatus();
    }
    getSubscriptionsPerPackages(): Observable<any> {
        return this.provider.getSubscriptionsPerPackages();
    }
    getExpensesPerTypes(): Observable<any> {
        return this.provider.getExpensesPerTypes();
      }
      getsubscriptionsVisits(): Observable<any> {
        return this.provider.getsubscriptionsVisits();
	}

}
