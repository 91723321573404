import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClientModel } from '../../../../../models/client.model';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import { ClientsRepository } from '../../../../../data/repositories/clients.repository';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {
	LayoutUtilsService,
	MessageType
} from '../../../../layout/utils/layout-utils.service';
import { DataGridHelper } from '../../grid.helper';
import { AttachmentsRepository } from '../../../../../data/repositories/Attachments.repository';
import { AttachmentModel } from '../../../../../models/attachment.model';
import { QueryParamsModel } from '../../../../../models/query-models/query-params.model';
import { QueryResultsModel } from '../../../../../models/query-models/query-results.model';
import { mergeMap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseComponent } from '../../../../../core/base/base.component';
import { TypesUtilsService } from '../../../../layout/utils/types-utils.service';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';

@Component({
	selector: 'm-client-details',
	templateUrl: './clients-details.component.html',
	styleUrls: ['./clients-details.component.scss'],
	providers: [ClientsRepository, AttachmentsRepository]
})
export class ClientsDetailsComponent extends BaseComponent implements OnInit {
	loadingSubject = new BehaviorSubject<boolean>(false);
	isNewOrEdit: boolean;
	clientForm: FormGroup;
	client: ClientModel = new ClientModel();
	clientAttachments: AttachmentModel[] = [];
	withClose: boolean;
	hasFormErrors: boolean = false;
	viewLoading: boolean = false;
	loadingAfterSubmit: boolean = false;

	errorMessage: string = '';

	constructor(
		private clientFB: FormBuilder,
		public clientsRepository: ClientsRepository,
		public attachmentsRepository: AttachmentsRepository,
		public layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<ClientsDetailsComponent>,
		public spinner: NgxSpinnerService,
		public subheaderService: SubheaderService,
		public typesUtilsService: TypesUtilsService,
		public authService: AuthenticationService,
		public ref: ChangeDetectorRef,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		super(
			subheaderService,
			layoutUtilsService,
			ref,
			spinner,
			typesUtilsService,
			authService
		);
	}

	ngOnInit() {
		this.client = this.data.client;
		if (this.client.ClientId) {
			this.attachmentsRepository.getAttachments(15, 1).subscribe(res => {
				this.clientAttachments = res;
			});
		}
		this.createForm();
	}
	createForm() {
		this.clientForm = this.clientFB.group({
			gender: [this.client.Gender, Validators.required],
			firstName: [this.client.FirstName, Validators.required],
			lastName: [this.client.LastName, Validators.required],
			midName: [this.client.MidName, Validators.required],
			identityNumber: [this.client.IdentityNumber, Validators.required],
			phoneNumber: [this.client.PhoneNumber, Validators.required],
			email: [this.client.Email]
		});
	}

	/** UI */
	getTitle(): string {
		if (this.client.ClientId > 0) {
			return `ملف عميل:  '${this.client.FirstName} ${
				this.client.LastName
				}'`;
		}

		return 'تسجيل عميل جديد';
	}

	isControlInvalid(controlName: string): boolean {
		const control = this.clientForm.controls[controlName];
		const result = control.invalid && control.touched;
		return result;
	}

	/** ACTIONS */
	prepareClient(): ClientModel {
		const controls = this.clientForm.controls;
		const _Client = new ClientModel();
		_Client.ClientId = this.client.ClientId;
		_Client.FirstName = controls['firstName'].value;
		_Client.MidName = controls['midName'].value;
		_Client.LastName = controls['lastName'].value;
		_Client.Email = controls['email'].value;
		_Client.Gender = controls['gender'].value;
		_Client.IdentityNumber = controls['identityNumber'].value;
		_Client.PhoneNumber = controls['phoneNumber'].value;
		_Client.language = '1';
		return _Client;
	}

	onSubmit(withClose: boolean = false) {
		this.withClose = withClose;
		const controls = this.clientForm.controls;
		/** check form */
		if (this.clientForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		const editedClient = this.prepareClient();
		if (editedClient.ClientId) {
			this.updateClient(editedClient);
		} else {
			this.createClient(editedClient);
		}
	}

	saveCompleted() {
		if (!this.withClose) {
			this.layoutUtilsService.showActionNotification(
				'تم الحفظ بنجاح',
				MessageType.Success,
				4000,
				true,
				false
			);
			return;
		}
		this.dialogRef.close({
			isEdit: true
		});
	}

	async updateClient(_Client: ClientModel) {
		await this.async(this.clientsRepository.update(_Client));
		if (this.hasErrors) {return; }
		this.saveCompleted();
	}

	async createClient(_Client: ClientModel) {
		await this.async(this.clientsRepository.insert(_Client));
		if (this.hasErrors) {return; }

		this.saveCompleted();
	}
	async refreshDetails() {
	this.client = await this.async(this.clientsRepository.get(this.client.ClientId));
	}
}
