import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';

import 'hammerjs';
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AuthenticationModule } from './core/auth/authentication.module';
import { NgxPermissionsModule } from 'ngx-permissions';


import { LayoutModule } from './content/layout/layout.module';
import { PartialsModule } from './content/partials/partials.module';
import { CoreModule } from './core/core.module';
import { AclService } from './core/services/acl.service';
import { LayoutConfigService } from './core/services/layout-config.service';
import { MenuConfigService } from './core/services/menu-config.service';
import { PageConfigService } from './core/services/page-config.service';
import { UserService } from './core/services/user.service';
import { UtilsService } from './core/services/utils.service';
import { ClassInitService } from './core/services/class-init.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { GestureConfig, MatProgressSpinnerModule } from '@angular/material';
import { OverlayModule } from '@angular/cdk/overlay';

import { MessengerService } from './core/services/messenger.service';
import { ClipboardService } from './core/services/clipboard.sevice';

import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { LayoutConfigStorageService } from './core/services/layout-config-storage.service';
import { LogsService } from './core/services/logs.service';
import { QuickSearchService } from './core/services/quick-search.service';
import { SubheaderService } from './core/services/layout/subheader.service';
import { HeaderService } from './core/services/layout/header.service';
import { MenuHorizontalService } from './core/services/layout/menu-horizontal.service';
import { MenuAsideService } from './core/services/layout/menu-aside.service';
import { LayoutRefService } from './core/services/layout/layout-ref.service';
import { SplashScreenService } from './core/services/splash-screen.service';
import { DataTableService } from './core/services/datatable.service';
import { CrmModule } from './content/pages/crm/crm.module';
import { HcmModule } from './content/pages/hcm/hcm.module';
import { HttpUtilsService } from './core/services/http-utils.service';
import { DataModule } from './data/data.module';
import { SettingsModule } from './content/pages/settings/settings.module';
import { AccountsModule } from './content/pages/accounts/accounts.module';
import { DashboardModule } from './content/pages/components/dashboard/dashboard.module';
import { MessagingService } from './core/services/messaging.service';
import { AngularFireModule } from '@angular/fire/public_api';
import { AngularFireDatabaseModule } from '@angular/fire/database/public_api';
// import { AngularFireModule } from '@angular/fire';
// import { MessagingService } from './core/services/messaging.service';
// import { AngularFireDatabaseModule } from 'angularfire2/database';
// import {AngularFirestoreModule} from '@angular/fire/firestore';
// import * as firebase from "firebase/app";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	// suppressScrollX: true
};
export const MY_FORMATS = {
	parse: {
		dateInput: 'DD/MM/YYYY',
	},
	display: {
		dateInput: 'YYYY/MM/DD',
		monthYearLabel: 'YYYY MMM',
		dateA11yLabel: 'YYYY/MM/DD',
		monthYearA11yLabel: 'YYYY MMM',
	},
};

const firebaseConfig = {
	apiKey: "AIzaSyB5CtBdzjJPJUHMfHKETU49gIo9Op5ApoE",
    authDomain: "karazwtoot-6bb1d.firebaseapp.com",
    databaseURL: "https://karazwtoot-6bb1d.firebaseio.com",
    projectId: "karazwtoot-6bb1d",
    storageBucket: "karazwtoot-6bb1d.appspot.com",
    messagingSenderId: "214453058193",
    appId: "1:214453058193:web:5636e9c2a30222c2f55d30",
    measurementId: "G-8V6EB2GYDD"
  };
@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		LayoutModule,
		PartialsModule,
		CrmModule,
		HcmModule,
		SettingsModule,
		AccountsModule,
		DashboardModule,
		CoreModule,
		OverlayModule,
		AuthenticationModule,
		NgxPermissionsModule.forRoot(),
		NgbModule,
		TranslateModule.forRoot(),
		MatProgressSpinnerModule,
		DataModule
		,
	 AngularFireModule.initializeApp(firebaseConfig),
		AngularFireDatabaseModule,
	],
	providers: [
		AclService,
		LayoutConfigService,
		LayoutConfigStorageService,
		LayoutRefService,
		MenuConfigService,
		PageConfigService,
		UserService,
		UtilsService,
		ClassInitService,
		MessengerService,
		ClipboardService,
		LogsService,
		QuickSearchService,
		DataTableService,
		SplashScreenService,
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		},

		// template services
		SubheaderService,
		HeaderService,
		MenuHorizontalService,
		MenuAsideService,
		HttpUtilsService,
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: GestureConfig
		}
		,
		MessagingService
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor() {
		// firebase.initializeApp(firebaseConfig);
	}
}
