import { BaseModel } from "./_base.model";
import { BranchModel } from './branch.model';

export class InvoiceModel extends BaseModel {
	InvoiceId: number;
	PaymentMethodId: number;
	Date: string;
	Notes: string;
	Amount: number;
	SubscriptionId: number;
	BranchId: number;
	SerialNumber: string;
	Branch: BranchModel = new BranchModel();
}
export class ExpensesModel extends BaseModel {
	ExpenseId: number;
	PaymentMethodId: number;
	Date: string;
	Notes: string;
	Amount: number;
	BranchId: number;
	ExpenseTypeId: number;
	Branch: BranchModel = new BranchModel();
	ExpenseType: ExpenseType = new ExpenseType();
}
export class ExpenseType {
	Id: number;
	DName1: string;
	DName2: string;
}
