import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import {
	LayoutUtilsService,
	MessageType
} from '../../../../layout/utils/layout-utils.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TypesUtilsService } from '../../../../layout/utils/types-utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseComponent } from '../../../../../core/base/base.component';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { SubscriptionsRepository } from '../../../../../data/repositories/subscriptions.repository';
import { DayEventModel } from '../../../../../models/dayevent.model';

@Component({
	selector: 'm-day-events',
	templateUrl: './day-events.component.html',
	styleUrls: ['./day-events.component.scss'],
	providers: [SubscriptionsRepository]
})
export class DayEventsComponent extends BaseComponent implements OnInit {
	loadingSubject = new BehaviorSubject<boolean>(false);
	loading$ = this.loadingSubject.asObservable();
	viewLoading: boolean = false;
	dayevents: DayEventModel[]=[];
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		public layoutUtilsService: LayoutUtilsService,
		public subscriptionsRepository: SubscriptionsRepository,
		public spinner: NgxSpinnerService,
		public subheaderService: SubheaderService,
		public typesUtilsService: TypesUtilsService,
		public authService: AuthenticationService,
		public ref: ChangeDetectorRef,
		public dialogRef: MatDialogRef<DayEventsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		super(
			subheaderService,
			layoutUtilsService,
			ref,
			spinner,
			typesUtilsService,
			authService
		);
	}

  ngOnInit() {
	this.loadData();
	}
	async loadData() {
		var dayId = this.data.id;
		console.log(dayId);
		this.dayevents = await this.async(this.subscriptionsRepository.getDayEvents(dayId));
		if (this.dayevents.length)
			{
			  for (const event of this.dayevents) {
				event.Time = event.Time.slice(0, -3);
			  }
			}     
		console.log(this.dayevents);
	}
	}
