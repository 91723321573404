import {
	Component,
	OnInit,
	ViewChild,
	AfterViewInit,
	Inject,
	ChangeDetectorRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
	MatHorizontalStepper,
	MatStepper,
	MatDialog,
	MAT_DIALOG_DATA,
	MatDialogRef,
	MatTabChangeEvent
} from '@angular/material';
import { CdkStep } from '@angular/cdk/stepper';
import { startWith, map } from 'rxjs/operators';
import { SubscriptionModel } from '../../../../../models/subscription.model';
import { ClientsRepository } from '../../../../../data/repositories/clients.repository';
import { SubscriptionsRepository } from '../../../../../data/repositories/subscriptions.repository';
import { BranchModel } from '../../../../../models/branch.model';
import { ClientModel } from '../../../../../models/client.model';
import { DataGridHelper } from '../../grid.helper';
import { AttachmentModel } from '../../../../../models/attachment.model';
import { AttachmentsRepository } from '../../../../../data/repositories/Attachments.repository';
import {
	LayoutUtilsService,
	MessageType
} from '../../../../layout/utils/layout-utils.service';
import { PackageModel, Service } from '../../../../../models/package.model';
import { ChildModel } from '../../../../../models/child.model';
import { ChildsRepository } from '../../../../../data/repositories/childs.repository';
import { BranchesRepository } from '../../../../../data/repositories/branches.repository';
import { PackagesRepository } from '../../../../../data/repositories/packages.repository';
import { TypesUtilsService } from '../../../../layout/utils/types-utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChildsDetailsComponent } from '../../childs/childs-details/childs-details.component';
import { BaseComponent } from '../../../../../core/base/base.component';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { SubscriptionsCancelComponent } from '../subscriptions-cancel/subscriptions-cancel.component';
@Component({
	selector: 'm-subscriptions-details',
	templateUrl: './subscriptions-details.component.html',
	styleUrls: ['./subscriptions-details.component.scss'],
	providers: [
		SubscriptionsRepository,
		ClientsRepository,
		ChildsRepository,
		BranchesRepository,
		PackagesRepository
	]
})
export class SubscriptionsDetailsComponent extends BaseComponent
	implements OnInit {
	loadingSubject = new BehaviorSubject<boolean>(false);
	loading$ = this.loadingSubject.asObservable();
	isNewOrEdit: boolean = true;
	selectedTab: number = 0;
	subscriptionsAttachments: AttachmentModel[] = [];
	subscription: SubscriptionModel = new SubscriptionModel();
	subscriptionForm: FormGroup;
	branchesList: BranchModel[] = [];
	packagesList: PackageModel[] = [];
	servicesList: Service[] = [];
	childsList: ChildModel[] = [];
	filteredClientsList: ClientModel[] = [];

	subscriptionColumns = ['Date', 'State', 'Notes'];
	allClients: ClientModel[] = [];

	selectedPackage: PackageModel = new PackageModel();
	selectedService: Service = new Service();
	hasFormErrors: boolean;
	show: boolean = false;
	amountAfterDiscount: number;
	percentage: number = 0;
	calculatedVat: number = 0;
	withClose: boolean;
	subscriptionId: number;
	showSaveButtons: boolean;
	@ViewChild(MatHorizontalStepper) stepper: MatHorizontalStepper;
	constructor(
		public subheaderService: SubheaderService,
		public layoutUtilsService: LayoutUtilsService,
		public ref: ChangeDetectorRef,
		public spinner: NgxSpinnerService,
		public formBuilder: FormBuilder,
		public typesUtilsService: TypesUtilsService,
		public authService: AuthenticationService,
		public subscriptionsRepository: SubscriptionsRepository,
		public attachmentsRepository: AttachmentsRepository,
		public clientsRepository: ClientsRepository,
		public childsRepository: ChildsRepository,
		public branchesRepository: BranchesRepository,
		public packagesRepository: PackagesRepository,
		private route: ActivatedRoute,
		private router: Router,
		public dialog: MatDialog,
		private activeRoute: ActivatedRoute
	) {
		super(
			subheaderService,
			layoutUtilsService,
			ref,
			spinner,
			typesUtilsService,
			authService
		);
		this.createForm();
	}

	async clientChanged() {
		console.log('client changed');
		const client = this.subscriptionForm.controls['client'].value;
		if (!client || !client.Id) {
			//this.branchesList = [];
			this.childsList = [];
			return;
		}
		this.childsList = await this.async(
			this.childsRepository.getClientChilds(client.ClientId)
		);
		// this.branchesList = await this.async(
		// 	this.branchesRepository.getClientBranches(client.ClientId)
		// );
		this.loadBranches();
	}
	async loadBranches() {
		const permissions = JSON.parse(localStorage.getItem('userPermissions'));
		if (permissions.find(a => a.RoleId === 1)) {
			this.branchesList = await this.async(this.branchesRepository.getAll());
		} else {
			for (let i = 0; i < permissions.length; i++) {
				const element = permissions[i];
				if (element.Code === 'subscriptions_add' && !this.branchesList.find(a => a.Id === element.branchId)) {
					this.branchesList.push(element.Branch);
				}
			}
		}
	}
	async branchChanged() {
		console.log('branch changed');

		if (!this.subscription.Branch.Id || !this.subscription.Child.Id) {
			return;
		}
		this.servicesList = await this.async(
			this.packagesRepository.getChildPackages(
				this.subscription.Branch.Id,
				this.subscription.Child.Id
			)
		);
	}
	async loadServices() {
		console.log('loadServices');
		const childId = this.subscriptionForm.controls['child'].value;
		const branchId = this.subscriptionForm.controls['branch'].value;

		console.log(childId);
		console.log(branchId);
		if (!childId || !branchId) {
			return;
		}
		this.servicesList = await this.async(
			this.packagesRepository.getChildPackages(branchId, childId)
		);
		console.log(this.servicesList);
	}

	async ngOnInit() {

		// this.activeRoute.params.subscribe(routeParams => {
		// 	const id = routeParams.id;
		// 	this.subscriptionId = Number.parseInt(id);
		// 	this.initialization();
		// 	return;
		// });


		const id = this.activeRoute.snapshot.params['id'];
		console.log(id);
		console.log(this.activeRoute);
		let title = '';

		if (id) {
			// tslint:disable-next-line: radix
			this.subscriptionId = Number.parseInt(id);
			title = 'تفاصيل اشتراك';
		} else {
			title = 'تسجيل اشتراك جديد';
		}
		this.initialization();
		this.loadBranches();
		this.subheaderService.setBreadcrumbs([
			{ title: 'كل الإشتراكات', page: '/crm/subscriptions/list' },
			{ title: title }
		]);
	}
	async initialization() {
		this.isNewOrEdit = true;
		if (this.subscriptionId) {
			console.log('initialization');
			console.log(this.subscriptionId);
			this.subscription = await this.async(
				this.subscriptionsRepository.get(this.subscriptionId)
			);

			if (this.subscription.State === 1) {
				await this.loadClients();
				this.show = true;
			}
			console.log(this.subscription);
			this.moveStepper(this.subscription.State - 1);
			if (this.subscription.Discount > 0) {
				this.percentage = Math.round(
					(this.subscription.Discount * 100) /
					this.subscription.Amount
				);
			}
			this.amountAfterDiscount =
				this.subscription.Amount - this.subscription.Discount;
			if (this.subscription.VatAmount > 0) {
				this.calculatedVat =
					(this.subscription.RegistrationFees + this.amountAfterDiscount - this.subscription.QurrahFundAmount) *
					(this.subscription.VatAmount / 100);
				console.log(this.subscription.VatAmount);
			}
			this.selectedPackage.VatAmount = this.subscription.VatAmount;
			this.subscription.TotalAmount =
				this.subscription.Amount +
				this.calculatedVat +
				this.subscription.RegistrationFees -
				this.subscription.Discount -
				this.subscription.QurrahFundAmount;
			this.subscription.StartDate = new Date(this.subscription.StartDate);
			this.selectedPackage.AvailableFrom = this.subscription.AvailableFrom;
			this.selectedPackage.AvailableTo = this.subscription.AvailableTo;
			this.selectedPackage.Amount = this.subscription.Amount;
			this.selectedPackage.IsQurrah = this.subscription.IsQurrah;
			this.selectedPackage.QurrahFundAmount = this.subscription.QurrahFundAmount;
			this.selectedPackage.RegistrationFees = this.subscription.RegistrationFees;
			if (
				this.subscription.State === 1 ||
				this.subscription.State === 2
			) {
				this.showSaveButtons = true;
			}
			this.createForm();
			const selectedClient = this.allClients.filter(
				a => a.ClientId === this.subscription.Client.ClientId
			)[0];
			this.subscriptionForm.controls['client'].setValue(selectedClient);
			this.subscriptionForm.controls['child'].setValue(
				this.subscription.Child.Id
			);
			this.subscriptionForm.controls['branch'].setValue(
				this.subscription.Branch.Id
			);
			this.subscriptionForm.controls['service'].setValue(
				this.subscription.ServiceId
			);

			console.log(this.subscriptionForm.controls['paymentMethod'].value);
			this.subscriptionForm.controls['paymentMethod'].setValue(
				this.subscription.PaymentMethod
			);
			await this.loadServices();
			this.showPackages();
			if (this.subscription.State !== 1) {
				this.isNewOrEdit = false;
			}
		} else {
			await this.loadClients();
		}

		if (this.subscription.State === 1) {
			this.showPackageDetails();
		}
		this.refreshView();
	}

	async loadClients() {
		this.allClients = await this.async(this.clientsRepository.getAll());
		this.filteredClientsList = this.allClients;
	}
	showPackages() {
		const serviceId = this.subscriptionForm.controls['service'].value;
		console.log('showPackages');
		console.log(serviceId);
		console.log(this.servicesList);

		for (const service of this.servicesList) {
			if (serviceId === service.Id) {
				this.selectedService = service;
				this.packagesList = this.selectedService.Packages;
			}
		}
	}
	showPackageDetails() {
		const packageId = this.subscriptionForm.controls['package'].value;
		for (const packageItem of this.packagesList) {
			if (packageId === packageItem.Id) {
				this.selectedPackage = packageItem;
				this.packagesList = this.selectedService.Packages;
				if (this.selectedPackage.VatAmount > 0) {
					this.selectedPackage.VatAmount =
						this.selectedPackage.Amount *
						(this.selectedPackage.VatAmount / 100);
				}
				this.show = true;
			}
		}
	}
	createForm() {
		console.log('form create');
		this.subscriptionForm = this.formBuilder.group({
			branch: [this.subscription.Branch.Id, Validators.required],
			child: [this.subscription.Child.Id, Validators.required],
			client: [this.subscription.Client],
			package: [this.subscription.PackageId, Validators.required],
			startDate: [this.subscription.StartDate, Validators.required],
			service: [this.subscription.ServiceId, Validators.required],
			paymentMethod: [
				this.subscription.PaymentMethod,
				Validators.required
			],
			discount: [this.subscription.Discount],
			qurrahAmount: [this.subscription.QurrahFundAmount]
		});
		this.subscriptionForm.controls.client.valueChanges.subscribe(a => {
			this.clientChanged();
			this.filteredClientsList = this.filterClient(a);
		});

		this.subscriptionForm.controls.package.valueChanges.subscribe(
			() => (this.showSaveButtons = true)
		);
	}
	calculateDiscount() {
		console.log('percent changed');
		this.subscription.Discount =
			this.subscription.Amount * (this.percentage / 100);
		this.amountAfterDiscount =
			this.subscription.Amount - this.subscription.Discount;
		this.subscriptionForm.controls['discount'].setValue(
			this.subscription.Discount
		);
		if (this.subscription.VatAmount > 0) {
			this.calculatedVat =
				(this.subscription.RegistrationFees + this.amountAfterDiscount - this.subscription.QurrahFundAmount) *
				(this.subscription.VatAmount / 100);
		}
		this.subscription.TotalAmount =
			this.subscription.Amount +
			this.calculatedVat +
			this.subscription.RegistrationFees -
			this.subscription.Discount -
			this.subscription.QurrahFundAmount;
		this.refreshView();
	}
	calculatePercent() {
		console.log('discount changed');
		this.subscription.Discount = this.subscriptionForm.controls.discount.value;
		this.percentage = Math.round(
			(this.subscription.Discount * 100) / this.subscription.Amount
		);
		this.amountAfterDiscount =
			this.subscription.Amount - this.subscription.Discount;
		if (this.subscription.VatAmount > 0) {
			this.calculatedVat =
				(this.subscription.RegistrationFees + this.amountAfterDiscount - this.subscription.QurrahFundAmount) *
				(this.subscription.VatAmount / 100);
		}
		this.subscription.TotalAmount =
			this.subscription.Amount +
			this.calculatedVat +
			this.subscription.RegistrationFees -
			this.subscription.Discount -
			this.subscription.QurrahFundAmount;
		this.refreshView();
	}
	updateQurrahAmount() {
		this.subscription.QurrahFundAmount = this.subscriptionForm.controls.qurrahAmount.value;
		if (this.subscription.VatAmount > 0) {
			this.calculatedVat =
				(this.subscription.RegistrationFees + this.amountAfterDiscount - this.subscription.QurrahFundAmount) *
				(this.subscription.VatAmount / 100);
		}
		this.subscription.TotalAmount =
			this.subscription.Amount +
			this.calculatedVat +
			this.subscription.RegistrationFees -
			this.subscription.Discount -
			this.subscription.QurrahFundAmount;
		this.refreshView();
	}
	filterClient(val: string): ClientModel[] {
		return this.allClients.filter(option => {
			const fullName = option.FirstName + ' ' + option.MidName + ' ' + option.LastName;
			return fullName.includes(val) || option.PhoneNumber.includes(val);
		});
	}
	displayFn(client?: ClientModel): string | undefined {
		if (!client || !client.Id) {
			return;
		}
		return client.FirstName + ' ' + client.MidName + ' ' + client.LastName;
	}

	async createSubscription(
		_Subscription: SubscriptionModel,
		updateState: boolean
	) {


		const dialogRef = this.layoutUtilsService.showConfirmationDialog('إنشاء طلب إشتراك', 'هل أنت متأكد من أنك تريد انشاء الطلب؟');
		dialogRef.afterClosed().subscribe(async res => {
			if (!res) {
				return;
			}

			const id = await this.async(
				this.subscriptionsRepository.addSubscription(
					_Subscription,
					updateState
				)
			);
			if (id) {
				this.saveCompleted();
				this.subscriptionId = id;
				this.router.navigateByUrl('/crm/subscriptions/edit/' + id);
				this.initialization();
			}

		});







	}
	/** UI */
	getTitle(): string {
		if (this.subscription.Id) {
			return 'تعديل بيانات إشتراك';
		} else {
			return 'إضافة إشتراك جديد';
		}
	}
	isControlInvalid(controlName: string): boolean {
		const control = this.subscriptionForm.controls[controlName];
		const result = control.invalid && control.touched;
		return result;
	}

	/** ACTIONS */
	prepareSubscription(): SubscriptionModel {
		const controls = this.subscriptionForm.controls;
		const _Subscription = new SubscriptionModel();
		_Subscription.Id = this.subscription.Id;
		_Subscription.State = this.subscription.State;
		const _date = controls['startDate'].value;
		if (_date) {
			_Subscription.StartDate = this.typesUtilsService.dateFormat(_date);
		} else {
			_Subscription.StartDate = '';
		}
		_Subscription.PackageId = controls['package'].value;
		_Subscription.PaymentMethod = controls['paymentMethod'].value;
		_Subscription.ChildId = controls['child'].value;
		_Subscription.Discount = controls['discount'].value;
		_Subscription.QurrahFundAmount = controls['qurrahAmount'].value;
		console.log(_Subscription);
		return _Subscription;
	}

	onSubmit(withUpdateState: boolean) {
		this.hasFormErrors = false;
		const controls = this.subscriptionForm.controls;
		/** check form */
		if (this.subscriptionForm.invalid) {
			console.log('invalid');
			console.log(this.subscriptionForm);

			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			// this.hasFormErrors = true;
			return;
		}
		const permissions = JSON.parse(localStorage.getItem('userPermissions'));

		const editedSubscription = this.prepareSubscription();
		console.log('editedSubscription');
		console.log(editedSubscription);
		if (editedSubscription.Id > 0) {
			if (!permissions.find(a => a.Code === 'subscriptions_edit' || a.RoleId === 1)) {
				this.layoutUtilsService.showActionNotification(
					'عفواً, ليس لديك الصلاحية المناسبة',
					MessageType.Warning,
					4000,
					true,
					false
				);
				return;
			}
			if (editedSubscription.State === 2) {
				this.updatePaymentDetails(editedSubscription, withUpdateState);
			} else {
				this.updateSubscription(editedSubscription, withUpdateState);
			}
		} else {
			if (!permissions.find(a => a.Code === 'subscriptions_add' || a.RoleId === 1)) {
				this.layoutUtilsService.showActionNotification(
					'عفواً, ليس لديك الصلاحية المناسبة',
					MessageType.Warning,
					4000,
					true,
					false
				);
				return;
			}
			this.createSubscription(editedSubscription, withUpdateState);
		}
	}

	async updatePaymentDetails(
		_Subscription: SubscriptionModel,
		updateState: boolean
	) {

		if (updateState) {
			console.log('updatePaymentDetails');

			const dialogRef = this.layoutUtilsService.showConfirmationDialog('تسجيل سداد طلب إشتراك',
				'سيتم تسجيل سند قبض جديد لهذا العميل وتحريك الطلب للمرحلة التالية..هل أنت متأكد أنك تريد المواصلة؟');
			dialogRef.afterClosed().subscribe(async res => {
				if (!res) {
					return;
				}

				const id = await this.async(
					this.subscriptionsRepository.updatePaymentDetails(
						_Subscription.Id,
						_Subscription.PaymentMethod,
						_Subscription.Discount,
						_Subscription.QurrahFundAmount,
						updateState
					)
				);
				if (id) {
					this.saveCompleted();
					this.subscriptionId = id;
					this.initialization();
				}

			});
		}


	}

	async updateSubscription(
		_Subscription: SubscriptionModel,
		updateState: boolean
	) {

		const dialogRef = this.layoutUtilsService.showConfirmationDialog('تحديث حالة طلب إشتراك',
			'سيتم تحريك الطلب إلى الحالة سداد.. هل أنت متأكد أنك تريد المواصلة؟');
		dialogRef.afterClosed().subscribe(async res => {
			if (!res) {
				return;
			}
			const id = await this.async(
				this.subscriptionsRepository.updateSubscription(
					_Subscription,
					updateState
				)
			);
			if (id) {
				this.saveCompleted();
				this.subscriptionId = id;
				console.log(id);
				this.initialization();
			}

		});




	}

	async renew() {
		const dialogRef = this.layoutUtilsService.showConfirmationDialog('تجديد إشتراك',
			'سيتم تجديد هذا الإشتراك.. هل أنت متأكد أنك تريد المواصلة؟');
		dialogRef.afterClosed().subscribe(async res => {
			if (!res) {
				return;
			}

			const id = await this.async(
				this.subscriptionsRepository.renewSubscription(
					this.subscription.Id
				)
			);
			console.log(id);
			if (id) {
				this.saveCompleted();
				this.subscription = new SubscriptionModel();
				this.subscriptionId = id;
				this.router.navigateByUrl('/crm/subscriptions/edit/' + id);
				this.initialization();
				//this.ngOnInit();
			}
		});
	}
	saveCompleted() {
		this.layoutUtilsService.showActionNotification(
			'تم الحفظ بنجاح',
			MessageType.Success,
			4000,
			true,
			false
		);

		// this.dialogRef.close({
		// 	isEdit: true
		// });
	}
	moveStepper(index: number) {
		console.log(index);
		this.stepper.reset();
		if (index > 10) {
			console.log('reset');

			//this.stepper.reset();

			this.refreshView();
			return;
		}
		console.log('moveStepper');

		this.stepper.selectedIndex = 0;

		for (let i = 0; i < index; i++) {
			this.stepper.next();
		}
	}
	goBack() {
		this.router.navigateByUrl('/crm/subscriptions/list');
	}

	tabChanged(event: MatTabChangeEvent) {
		console.log('changed');
		console.log(event.index);
		if (event.index === 0) {
			this.moveStepper(this.subscription.State - 1);
		}
	}

	editChild(child: ChildModel) {
		const _saveMessage = 'تم الحفظ بنجاح';
		const dialogRef = this.dialog.open(ChildsDetailsComponent, {
			data: { child }
		});
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				console.log('closed');
				this.layoutUtilsService.showActionNotification(
					_saveMessage,
					MessageType.Success,
					10000,
					true,
					false
				);

				return;
			}

			this.layoutUtilsService.showActionNotification(
				_saveMessage,
				MessageType.Success,
				10000,
				true,
				false
			);
			// this.loadList();
		});
	}


	async cancelSubscription() {
		if (!this.subscription) { return; }
		const msg = this.subscription.State === 1 || this.subscription.State === 2 ?
			'حذف الإشتراك؟' : 'إلغاء الإشتراك؟';

		const dialogRef = this.layoutUtilsService.showConfirmationDialog(msg,
			`هل أنت متأكد أنك تريد ${msg} ?`);

		if (this.subscription.State === 1 || this.subscription.State === 2) {
			dialogRef.afterClosed().subscribe(async res => {
				if (!res) {
					return;
				}
				await this.async(
					this.subscriptionsRepository.deleteSubscription(
						this.subscription.Id
					)
				);
				if (this.hasErrors) { return; }
				this.saveCompleted();
				this.goBack();
			});
		}
		else {

			dialogRef.afterClosed().subscribe(async res => {
				if (!res) {
					return;
				}
				const data = this.subscription;
				const _saveMessage = 'تم الحفظ بنجاح';
				const cancelDialog = this.dialog.open(SubscriptionsCancelComponent, { data: { data } });
				cancelDialog.afterClosed().subscribe(res => {
					if (!res) {
						return;
					}

					this.layoutUtilsService.showActionNotification(_saveMessage, MessageType.Success, 10000, true, false);
					this.saveCompleted();
					this.initialization();
				});

			});
		}



	}
}
