import { Component, OnInit, Input } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { MatDialog } from "@angular/material";

import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { FeedsRepository } from '../../../../../../data/repositories/feeds.repository';
import { DataGridHelper } from '../../../grid.helper';
import { AttachmentModel, AttachmentType } from '../../../../../../models/attachment.model';
import { AttachmentsRepository } from '../../../../../../data/repositories/Attachments.repository';
import { LayoutUtilsService, MessageType } from '../../../../../layout/utils/layout-utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../../../../../../core/auth/authentication.service';
import { QueryParamsModel } from '../../../../../../models/query-models/query-params.model';
import { QueryResultsModel } from '../../../../../../models/query-models/query-results.model';
import { mergeMap } from 'rxjs/operators';
import { Interactions } from '../../../../../../models/feed.model';

@Component({
	selector: "m-feeds-interactions",
	templateUrl: "./feeds-interactions.component.html",
	styleUrls: ["./feeds-interactions.component.scss"],
	providers: [FeedsRepository]
})
export class FeedsInteractionsComponent extends DataGridHelper implements OnInit {
	displayedColumns = [
		"FeedInteractionId",
		"Content",
		"CreatedBy",
		"CreatedAt",
		"UserPicturePath",
		"Actions"
	];
	// Incoming data
	@Input() loadingSubject: BehaviorSubject<boolean>;
	@Input() feedId: number;
	@Input() interactionType: number;
	interactionsList: Interactions[] = [];
	constructor(
		public dialog: MatDialog,
		public feedsRepository: FeedsRepository,
		public layoutUtilsService: LayoutUtilsService,
		public spinner: NgxSpinnerService,
		public formBuilder: FormBuilder,
		private router: Router,
		public authService: AuthenticationService
	) {
		super(spinner, layoutUtilsService, authService);
		this.dataGridSource = feedsRepository;
	}
	ngOnInit() {
		// 	this.loadingSubject.next(true);
		console.log("created");
		this.initializeDataGrid();
		this.loadList();
		this.dataGridSource = this.feedsRepository;
	}
	filterConfiguration(): any {
		const filter: any = {};
		// const searchText: string = this.searchInput.nativeElement.value;
		if (this.filterState && this.filterState.length > 0) {
			filter.State = +this.filterState;
		}
		return filter;
	}

	find(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		return this.feedsRepository
			.getFeedInteractions(this.feedId, this.interactionType)
			.pipe(
				mergeMap(res => {
					this.interactionsList = res;
					console.log(res);
					const result = this.baseFilter(res, queryParams);
					return of(result);
				})
			);
	}

	async deleteInteraction(interaction: Interactions) {
		await this.async(
			this.feedsRepository.deleteInteraction(interaction.FeedInteractionId)
		);
		if (this.hasErrors) {
			return;
		}
		console.log("uploaded");
		this.loadList();
	}

}
