import { Component, OnInit, Input } from "@angular/core";
import { DataGridHelper } from "../../../../pages/crm/grid.helper";
import { BehaviorSubject, Observable, of } from "rxjs";
import { MatDialog } from "@angular/material";
import {
	LayoutUtilsService,
	MessageType
} from "../../../../layout/utils/layout-utils.service";
import { NgxSpinnerService } from "ngx-spinner";
import { QueryParamsModel } from "../../../../../models/query-models/query-params.model";
import { QueryResultsModel } from "../../../../../models/query-models/query-results.model";
import { mergeMap } from "rxjs/operators";
import { AttachmentsRepository } from "../../../../../data/repositories/Attachments.repository";
import {
	AttachmentModel,
	AttachmentType
} from "../../../../../models/attachment.model";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../../../../core/auth/authentication.service";
import { BranchesRepository } from '../../../../../data/repositories/branches.repository';

@Component({
	selector: "m-branch-workdays",
	templateUrl: "./branches-workdays.component.html",
	styleUrls: ["./branches-workdays.component.scss"],
	providers: [BranchesRepository]
})
export class BranchWorkdaysComponent extends DataGridHelper implements OnInit {
	displayedColumns = [
		"DayName",
		"Actions"
	];
	// Incoming data
	@Input() loadingSubject: BehaviorSubject<boolean>;
	@Input() branchId: number;
	workDaysList: any[] = [];
	daysList: any[] = [];
	newWorkDayForm: FormGroup;
	constructor(
		public dialog: MatDialog,
		public branchesRepository: BranchesRepository,
		public layoutUtilsService: LayoutUtilsService,
		public spinner: NgxSpinnerService,
		public formBuilder: FormBuilder,
		public authService: AuthenticationService
	) {
		super(spinner, layoutUtilsService, authService);
		this.dataGridSource = branchesRepository;
	}
	ngOnInit() {
		// 	this.loadingSubject.next(true);
		console.log("created");
		this.initializeDataGrid();
		this.loadDays();
		this.loadList();
		this.createForm();
		this.dataGridSource = this.branchesRepository;
	}


	find(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		return this.branchesRepository
			.getBranchWorkDays(this.branchId)
			.pipe(
				mergeMap(res => {
					this.workDaysList = res;
					console.log(res);
					const result = this.baseFilter(res, queryParams);
					return of(result);
				})
			);
	}
	createForm() {
		console.log("form create");
		this.newWorkDayForm = this.formBuilder.group({
			day: [null, Validators.required]
		});
	}
	async deleteWorkDay(workday: any) {
		const dialogRef = this.layoutUtilsService.showConfirmationDialog('حذف يوم عمل', 'هل أنت متأكد من أنك تريد الحذف؟');
		dialogRef.afterClosed().subscribe(async res => {
			if (!res) {
				return;
			}
console.log(this.branchId);
console.log(workday.DayId);
			await this.async(
				this.branchesRepository.removeWorkDayFromBranch(this.branchId, workday.DayId)
			);
			if (this.hasErrors) {
				return;
			}
			this.createForm();
			this.saveCompleted();
			this.loadList();

		});
	}

	loadDays() {
		this.branchesRepository
			.getWeekdays()
			.subscribe(result => {
				this.daysList = result;
			});
	}

	async onSubmit() {
		/** check form */
		if (this.newWorkDayForm.invalid) {
			const controls = this.newWorkDayForm.controls;
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}



		const dayId = this.newWorkDayForm.controls["day"]
			.value;

		await this.async(
			this.branchesRepository.addWorkDayToBranch(
				this.branchId,
				dayId
			)
		);
		if (this.hasErrors) {
			return;
		}
		this.createForm();
		this.saveCompleted();
		this.loadList();
	}
	saveCompleted() {
		this.layoutUtilsService.showActionNotification(
			"تم الحفظ بنجاح",
			MessageType.Success,
			4000,
			true,
			false
		);
	}
}
