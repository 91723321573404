import { ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { QueryParamsModel } from '../../../models/query-models/query-params.model';
import { merge, fromEvent, of, Observable } from 'rxjs';
import {
	tap,
	debounceTime,
	distinctUntilChanged,
	catchError,
	finalize,
	mergeMap
} from 'rxjs/operators';
import { BaseRepository } from '../../../data/repositories/_base.repository';
import { QueryResultsModel } from '../../../models/query-models/query-results.model';
import _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import {
	LayoutUtilsService,
	MessageType
} from '../../layout/utils/layout-utils.service';
import { forEach } from '@angular/router/src/utils/collection';
import { promise } from 'protractor';
import { AuthenticationService } from '../../../core/auth/authentication.service';
import { error } from 'util';
type callback = (result: any) => void;

export class DataGridHelper {

	constructor(
		public spinner: NgxSpinnerService,
		public layoutUtilsService: LayoutUtilsService,
		public authService: AuthenticationService,
	) { }
	errorMessage: string = '';
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	// Filter fields
	@ViewChild('searchInput') searchInput: ElementRef;
	filterState: string = '';
	filterBranch: string = '';
	hasErrors: boolean;
	// Selection
	selection = new SelectionModel<any>(true, []);
	subscriptionsResult: any[] = [];
	dataGridSource: BaseRepository;
	errors: string[];

	loadList(): void {
		this.selection.clear();
		const queryParams = new QueryParamsModel(
			this.filterConfiguration(),
			this.sort.direction,
			this.sort.active,
			this.paginator.pageIndex,
			this.paginator.pageSize
		);
		this.getFilteredResults(queryParams);
	}
	filterConfiguration(): any {
	}

	/** SELECTION */
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.subscriptionsResult.length;
		return numSelected === numRows;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		if (this.isAllSelected()) {
			this.selection.clear();
		} else {
			this.subscriptionsResult.forEach(row => this.selection.select(row));
		}
	}

	restoreState(queryParams: QueryParamsModel, id: number) {
		if (!queryParams.filter) {
			return;
		}

		if ('StateName' in queryParams.filter) {
			this.filterState = queryParams.filter.StateName.toString();
		}

		if ('BranchName' in queryParams.filter) {
			this.filterBranch = queryParams.filter.BranchName.toString();
		}

		if (queryParams.filter.model) {
			this.searchInput.nativeElement.value = queryParams.filter.model;
		}
	}

	initializeDataGrid() {
		this.paginator._intl.itemsPerPageLabel = 'عدد السطور في الصفحة';
		// If the user changes the sort order, reset back to the first page.
		this.sort.sortChange.subscribe(() => {
			this.paginator.pageIndex = 0;
		});

		/* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
		merge(this.sort.sortChange, this.paginator.page)
			.pipe(
				tap(() => {
					this.loadList();
				})
			)
			.subscribe();
		// Filtration, bind to searchInput
		if (this.searchInput) {
			fromEvent(this.searchInput.nativeElement, 'keyup')
				.pipe(
					debounceTime(150),
					distinctUntilChanged(),
					tap(() => {
						this.paginator.pageIndex = 0;
						this.loadList();
					})
				)
				.subscribe();
		}

	}
	getFilteredResults(queryParams: QueryParamsModel) {
		this.dataGridSource.loadingSubject.next(true);

		this.find(queryParams)
			.pipe(
				tap(res => {
					this.dataGridSource.entitySubject.next(res.items);
					this.dataGridSource.paginatorTotalSubject.next(
						res.totalCount
					);
				}),
				catchError(err => {
					this.handleException(err);
					return of(new QueryResultsModel([], err));
				}),
				finalize(() => this.dataGridSource.loadingSubject.next(false))
			)
			.subscribe();

	}

	find(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		throw new Error('find Method not implemented.');
	}

	baseFilter(
		_entities: any[],
		_queryParams: QueryParamsModel,
		_filtrationFields: string[] = []
	): QueryResultsModel {
		let entitiesResult: any = [];
		// Filtration

		if (this.searchInput) {
			entitiesResult = this.searchInArray(
				_entities,
				_queryParams.filter,
				_filtrationFields
			);
		} else {
			entitiesResult = this.searchInArray(
				_entities,
				''
			);
		}

		// Sorting
		// start
		if (_queryParams.sortField) {
			entitiesResult = this.sortArray(
				entitiesResult,
				_queryParams.sortField,
				_queryParams.sortOrder
			);
		}
		// end

		// Paginator
		// start
		const totalCount = entitiesResult.length;
		const initialPos = _queryParams.pageNumber * _queryParams.pageSize;
		entitiesResult = entitiesResult.slice(
			initialPos,
			initialPos + _queryParams.pageSize
		);
		// end

		const queryResults = new QueryResultsModel();
		queryResults.items = entitiesResult;
		queryResults.totalCount = totalCount;
		return queryResults;
	}

	sortArray(
		_incomingArray: any[],
		_sortField: string = '',
		_sortOrder: string = 'asc'
	): any[] {
		if (!_sortField) {
			return _incomingArray;
		}

		let result: any[] = [];
		result = _incomingArray.sort((a, b) => {
			const aValue = this.objectByString(a,_sortField);
			const bValue = this.objectByString(b,_sortField);
			if (aValue < bValue) {
				return _sortOrder === 'asc' ? -1 : 1;
			}

			if (aValue > bValue) {
				return _sortOrder === 'asc' ? 1 : -1;
			}

			return 0;
		});
		return result;
	}
	objectByString(o, s) {
		s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
		s = s.replace(/^\./, '');           // strip a leading dot
		var a = s.split('.');
		for (var i = 0, n = a.length; i < n; ++i) {
			var k = a[i];
			if (k in o) {
				o = o[k];
			} else {
				return;
			}
		}
		return o;
	}
	searchInArray(
		_incomingArray: any[],
		_queryObj: any,
		_filtrationFields: string[] = []
	): any[] {
		const result: any[] = [];
		let resultBuffer: any[] = [];
		const indexes: number[] = [];
		let firstIndexes: number[] = [];
		let doSearch: boolean = false;

		_filtrationFields.forEach(item => {
			console.log(item);
			if (item in _queryObj) {
				_incomingArray.forEach((element, index) => {
					if (element[item] === _queryObj[item]) {
						firstIndexes.push(index);
					}
				});
				firstIndexes.forEach(element => {
					resultBuffer.push(_incomingArray[element]);
				});
				_incomingArray = resultBuffer.slice(0);
				resultBuffer = [].slice(0);
				firstIndexes = [].slice(0);
			}
		});

		console.log(_queryObj);

		Object.keys(_queryObj).forEach(key => {
			const searchText = _queryObj[key]
				.toString()
				.trim()
				.toLowerCase();
			if (key && !_.includes(_filtrationFields, key) && searchText) {
				doSearch = true;
				try {
					_incomingArray.forEach((element, index) => {
						const _val = element[key]
							.toString()
							.trim()
							.toLowerCase();
						if (
							_val.indexOf(searchText) > -1 &&
							indexes.indexOf(index) === -1
						) {
							indexes.push(index);
						}
					});
				} catch (ex) {
					console.log(ex, key, searchText);
				}
			}
		});

		if (!doSearch) {
			return _incomingArray;
		}

		indexes.forEach(re => {
			result.push(_incomingArray[re]);
		});

		return result;
	}
	// tslint:disable-next-line: no-shadowed-variable
	executeAsync(observable: Observable<any>, callback: callback) {
		this.errorMessage = '';
		this.spinner.show();
		setTimeout(() => {
			/** spinner ends after 5 seconds */
			this.spinner.hide();
		}, 5000);

		observable.subscribe(
			result => {
				callback(result);
			},
			err => {
				this.handleException(err);
			}
		);
	}
	async async(observable: Observable<any>): Promise<any> {
		this.hasErrors = false;
		try {
			this.spinner.show();
			const dd = await observable.toPromise();
			console.log(dd);
			this.spinner.hide();
			return dd;
		} catch (err) {
			this.handleException(err);
		}
	}
	onAlertClose($event) {
		this.errorMessage = '';
	}

	handleException(err: any) {
		this.hasErrors = true;
		this.spinner.hide();
		console.log(err);
		let msg = '';
		if (err === 'net::ERR_CONNECTION_REFUSED') {
			msg = 'مشكلة في الإتصال ..الرجاء التحقق من الإتصال بالإنترنت';
		}
		if (err.status === 401) {
			this.authService.logout();
			return;
		}
		if (err.status === 500) {
			msg = 'حدث خطأ في النظام';
		} else if ((err.status === 400)) {
			const modelState = err.error.ModelState;
			if (modelState) {
				msg = '<ul>';
				for (const key in modelState) {
					if (modelState.hasOwnProperty(key)) {
						for (let i = 0; i < modelState[key].length; i++) {
							msg += '<li>' + modelState[key][i] + '</li>';
						}
					}
				}
				msg += '</ul>';
			} else if (err.error.Message) {
				msg = err.error.Message;
			}
		} else if (err.statusText && err.statusText === 'Unknown Error') {
			 msg = 'مشكلة في الإتصال ..الرجاء التحقق من الإتصال بالإنترنت';
		} else { msg = err; }
		console.log(err);
		this.layoutUtilsService.showActionNotification(
			msg,
			MessageType.Error,
			4000,
			true,
			false
		);
	}

}
