import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import { LayoutUtilsService, MessageType } from '../../../../layout/utils/layout-utils.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AttachmentsTypesModel, EntityType } from '../../../../../models/settings.model';
import { SettingsRepository } from '../../../../../data/repositories/settings.repository';
import { BaseComponent } from '../../../../../core/base/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { TypesUtilsService } from '../../../../layout/utils/types-utils.service';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';

@Component({
	templateUrl: './types-details.component.html',
	styleUrls: ['./types-details.component.scss'],
	providers: [SettingsRepository]
})
export class AttachmentsTypesDetailsComponent extends BaseComponent implements OnInit {
	loadingSubject = new BehaviorSubject<boolean>(false);
	loading$ = this.loadingSubject.asObservable();
	isNewOrEdit: boolean;
	attachmentTypeForm: FormGroup;
	attachmentType: AttachmentsTypesModel = new AttachmentsTypesModel();
	withClose: boolean;
	hasFormErrors: boolean = false;
	viewLoading: boolean = false;
	loadingAfterSubmit: boolean = false;
	entityTypes: EntityType[] = [];
	errorMessage: string = '';
	constructor(
		private typeFB: FormBuilder,
		public subheaderService: SubheaderService,
		public settingsRepository: SettingsRepository,
		public layoutUtilsService: LayoutUtilsService,

		public authService: AuthenticationService,
		public ref: ChangeDetectorRef,
		public spinner: NgxSpinnerService,
		public typesUtilsService: TypesUtilsService,
		public dialogRef: MatDialogRef<AttachmentsTypesDetailsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		super(
			subheaderService,
			layoutUtilsService,
			ref,
			spinner,
			typesUtilsService,
			authService
		);
		this.createForm();
	}

	async	ngOnInit() {
		this.loadingSubject.next(true);
		this.attachmentType = this.data.type;
		this.entityTypes = await this.async(
			this.settingsRepository.getEntityTypes()
		);
		this.createForm();
		this.loadingSubject.next(false);
	}
	createForm() {
		this.attachmentTypeForm = this.typeFB.group({
			typeNameAr: [this.attachmentType.DName1, Validators.required],
			typeNameEn: [this.attachmentType.DName2, Validators.required],
			entityTypeId: [this.attachmentType.EntityTypeId, Validators.required]
		});
	}

	isControlInvalid(controlName: string): boolean {
		const control = this.attachmentTypeForm.controls[controlName];
		const result = control.invalid && control.touched;
		return result;
	}

	/** ACTIONS */
	prepareType(): AttachmentsTypesModel {
		const controls = this.attachmentTypeForm.controls;
		const type = new AttachmentsTypesModel();
		type.Id = this.attachmentType.Id;
		type.DName1 = controls['typeNameAr'].value;
		type.DName2 = controls['typeNameEn'].value;
		type.EntityTypeId = controls['entityTypeId'].value;
		return type;
	}

	onSubmit(withClose: boolean = false) {
		this.withClose = withClose;
		this.hasFormErrors = false;
		this.errorMessage = '';
		this.loadingAfterSubmit = false;
		const controls = this.attachmentTypeForm.controls;
		/** check form */
		if (this.attachmentTypeForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			return;
		}

		const editedType = this.prepareType();
		console.log(editedType);
		if (editedType.Id) {
			this.updateType(editedType);
		} else {
			this.createType(editedType);
		}
	}

	saveCompleted() {
		if (!this.withClose) {
			this.layoutUtilsService.showActionNotification(
				'تم الحفظ بنجاح',
				MessageType.Success,
				4000,
				true,
				false
			);
			return;
		}
		this.dialogRef.close({
			isEdit: true
		});
	}

	async updateType(type: AttachmentsTypesModel) {
		await this.async(this.settingsRepository.updateAttachmentType(type));
		if (this.hasErrors) return;
		this.saveCompleted();
	}

	async createType(type: AttachmentsTypesModel) {

		await this.async(this.settingsRepository.addAttachmentType(type));
		if (this.hasErrors) return;
		this.saveCompleted();
	}

	onAlertClose($event) {
		this.hasFormErrors = false;
	}
	getTitle(): string {
		if (this.attachmentType.Id) { return 'تعديل نوع المرفق'; } else { return 'إضافة نوع مرفق'; }
	}
}
