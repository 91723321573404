import { Injectable } from '@angular/core';
import { BaseRepository } from './_base.repository';
import { Observable } from 'rxjs';
import { CamerasProviderContract } from '../contracts/camera.contract';
import { CameraModel } from '../../models/camera.model';

@Injectable({
	providedIn: 'root'
})
export class CamerasRepository extends BaseRepository {
	constructor(private provider: CamerasProviderContract) {
		super();
		this.dataProvider = provider;
	}

	getAll() {
		return this.provider.getAll();
	}

	insert(model: CameraModel): Observable<any> {
		return this.provider.insert(model);
	}

	update(model: CameraModel): Observable<any> {
		return this.provider.update(model);
	}
}
