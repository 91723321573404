import {
	Component,
	OnInit,
	ElementRef,
	ViewChild,
	ChangeDetectionStrategy
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// RXJS
import {
	debounceTime,
	distinctUntilChanged,
	tap,
	mergeMap
} from 'rxjs/operators';
import { fromEvent, Observable, of } from 'rxjs';
// Services
import { SubscriptionsRepository } from '../../../../../data/repositories/subscriptions.repository';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import { DataGridHelper } from '../../grid.helper';
import { QueryParamsModel } from '../../../../../models/query-models/query-params.model';
import { QueryResultsModel } from '../../../../../models/query-models/query-results.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { LayoutUtilsService, MessageType } from '../../../../layout/utils/layout-utils.service';
import { BranchModel } from '../../../../../models/branch.model';
import { BranchesRepository } from '../../../../../data/repositories/branches.repository';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { ClientsRepository } from '../../../../../data/repositories/clients.repository';
import { ChildsRepository } from '../../../../../data/repositories/childs.repository';
import { PackagesRepository } from '../../../../../data/repositories/packages.repository';
import { SubscriptionModel } from '../../../../../models/subscription.model';
import { ClientModel } from '../../../../../models/client.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SubscriptionStateModel } from '../../../../../models/subscription-state.model';
import { PackageModel } from '../../../../../models/package.model';
import { TypesUtilsService } from '../../../../layout/utils/types-utils.service';
import { SubscriptionsDetailsComponent } from '../subscriptions-details/subscriptions-details.component';
@Component({
	selector: 'm-subscriptions-qurrah',
	templateUrl: './subscriptions-qurrah.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		SubscriptionsRepository,
		ClientsRepository,
		ChildsRepository,
		BranchesRepository,
		PackagesRepository
	]
})


export class QurrahSubscriptionsComponent extends DataGridHelper
	implements OnInit {
	subscription: SubscriptionModel;
	displayedColumns = [
		'Id',
		'Client.FirstName',
		'Child.FirstName',
		'BranchName',
		'StartDate',
		'EndDate',
		'PackageName',
		'Amount',
		'Discount',
		'PaidAmount',
		'StateName'
	];
	// search BEGIN
	filteredClientsList: ClientModel[] = [];
	searchForm: FormGroup;
	allClients: ClientModel[] = [];
	branchesList: BranchModel[] = [];
	statesList: SubscriptionStateModel[] = [];
	packagesList: PackageModel[] = [];
	// search END
	constructor(
		public dialog: MatDialog,
		private subheaderService: SubheaderService,
		public subscriptionsRepository: SubscriptionsRepository,
		public clientsRepository: ClientsRepository,
		public childsRepository: ChildsRepository,
		public branchesRepository: BranchesRepository,
		public packagesRepository: PackagesRepository,
		public layoutUtilsService: LayoutUtilsService,
		public spinner: NgxSpinnerService,
		public typesUtilsService: TypesUtilsService,
		private router: Router,
		public formBuilder: FormBuilder,
		public authService: AuthenticationService


	) {
		super(spinner, layoutUtilsService, authService);
		this.dataGridSource = subscriptionsRepository;
		this.createForm();
	}
	// search BEGIN
	createForm() {
		this.searchForm = this.formBuilder.group({
			branch: [null],
			client: [null],
			startDateFrom: [null],
			startDateTo: [null],
			endDateFrom: [null],
			endDateTo: [null],
			createdDateFrom: [null],
			createdDateTo: [null],
			subscriptionId: [null],
			state: [null],
			package: [null]
		});
		this.searchForm.controls.client.valueChanges.subscribe(a => {
			this.filteredClientsList = this.filterClient(a);
		});
	}
	async loadStates() {
		const allStatesFilter = new SubscriptionStateModel();
		allStatesFilter.DName1 = 'كل الحالات';
		this.statesList = await this.async(this.subscriptionsRepository.getStates());
		this.statesList.unshift(allStatesFilter);
		
	}
	async loadClients() {
		this.allClients = await this.async(this.clientsRepository.getAll());
		this.filteredClientsList = this.allClients;
	}
	filterClient(val: string): ClientModel[] {
		return this.allClients.filter(option => {
			const fullName = option.FirstName + ' ' + option.MidName + ' ' + option.LastName;
			return fullName.includes(val) || option.PhoneNumber.includes(val);
		});
	}
	displayFn(client?: ClientModel): string | undefined {
		if (!client || !client.Id) {
			return;
		}
		return client.FirstName + ' ' + client.MidName + ' ' + client.LastName;
	}

	resetSearch(){
		this.createForm();
	}
	find(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		const controls = this.searchForm.controls;
		const stateId = controls['state'].value;
		const subscriptionId = controls['subscriptionId'].value;
		const client = controls['client'].value;
		const branchId = controls['branch'].value;
		const packageId = controls['package'].value;
		let clientId = null;
		if (client) {
			clientId = client.ClientId;
		}
		let startDateFrom = controls['startDateFrom'].value;
		if (startDateFrom) {
			startDateFrom = this.typesUtilsService.dateFormat(startDateFrom);
		} else {
			startDateFrom = null;
		}

		let startDateTo = controls['startDateTo'].value;
		if (startDateTo) {
			startDateTo = this.typesUtilsService.dateFormat(startDateTo);
		} else {
			startDateTo = null;
		}


		let endDateFrom = controls['endDateFrom'].value;
		if (endDateFrom) {
			endDateFrom = this.typesUtilsService.dateFormat(endDateFrom);
		} else {
			endDateFrom = null;
		}

		let endDateTo = controls['endDateTo'].value;
		if (endDateTo) {
			endDateTo = this.typesUtilsService.dateFormat(endDateTo);
		} else {
			endDateTo = null;
		}

		let createdDateFrom = controls['createdDateFrom'].value;
		if (createdDateFrom) {
			createdDateFrom = this.typesUtilsService.dateFormat(createdDateFrom);
		} else {
			createdDateFrom = null;
		}

		let createdDateTo = controls['createdDateTo'].value;
		if (createdDateTo) {
			createdDateTo = this.typesUtilsService.dateFormat(createdDateTo);
		} else {
			createdDateTo = null;
		}

// tslint:disable-next-line: max-line-length
		return this.subscriptionsRepository.getQurrah(branchId, stateId, subscriptionId, clientId, null, packageId, startDateFrom, startDateTo, endDateFrom, endDateTo, createdDateFrom, createdDateTo).pipe(
			mergeMap(res => {
				const result = this.baseFilter(res, queryParams);
				return of(result);
			})
		);
	}
	// search END
	async ngOnInit() {
		this.loadBranches();
		this.initializeDataGrid();
		this.loadList();
		this.loadClients();
		this.loadStates();
		this.loadPackages();
		this.subheaderService.setBreadcrumbs([
			{ title: ' الإشتراكات' },
			{ title: 'كل الإشتراكات', page: '/crm/subscriptions/list' }
		]);
		this.dataGridSource = this.subscriptionsRepository;
	}

	async loadPackages() {
		this.packagesList = await this.async(this.packagesRepository.getAll());
	}
async loadBranches() {
	const permissions = JSON.parse(localStorage.getItem('userPermissions'));
	if (permissions.find(a => a.RoleId === 1)) {
		this.branchesList =  await this.async(this.branchesRepository.getAll());
	} else {
		for (let i = 0; i < permissions.length; i++) {
			const element = permissions[i];
			if (element.Code === 'subscriptions_view' && !this.branchesList.find(a => a.Id === element.branchId)) {
				this.branchesList.push(element.Branch);
			}
		}
	}

}
	newSubscription() {
		this.editSubscription(null);
	}
	editSubscription(subscriptionId: number | null) {
		const _saveMessage = 'تم الحفظ بنجاح';
		const dialogRef = this.dialog.open(SubscriptionsDetailsComponent, {
			data: { subscriptionId }
		});
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}
			console.log(res);
			this.layoutUtilsService.showActionNotification(
				_saveMessage,
				MessageType.Success,
				4000,
				true,
				false
			);

			this.loadList();
		});
	}
	getItemCssClassByStatus(status: number = 0): string {
		switch (status) {
			case 1:
				return 'subscription-editing';
			case 2:
				return 'subscription-sadad';
			case 3:
				return 'subscription-paid';
			case 4:
				return 'subscription-active';
			case 5:
				return 'subscription-completed';
		}
		return '';
	}


	selectRow(row: any) {
		console.log(row);

		const id = row.Id;
		this.router.navigateByUrl('/crm/subscriptions/edit/' + id);
	}
}
