import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { PackagesRepository } from '../../../../../data/repositories/packages.repository';
import { BehaviorSubject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import {
	LayoutUtilsService,
	MessageType
} from '../../../../layout/utils/layout-utils.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PackageModel, Service } from '../../../../../models/package.model';
import { BranchesRepository } from '../../../../../data/repositories/branches.repository';
import { BranchModel } from '../../../../../models/branch.model';
import { BaseComponent } from '../../../../../core/base/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { TypesUtilsService } from '../../../../layout/utils/types-utils.service';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';

@Component({
	selector: 'm-packages-details',
	templateUrl: './packages-details.component.html',
	styleUrls: ['./packages-details.component.scss'],
	providers: [PackagesRepository]
})
export class PackagesDetailsComponent extends BaseComponent implements OnInit {
	loadingSubject = new BehaviorSubject<boolean>(false);
	loading$ = this.loadingSubject.asObservable();
	isNewOrEdit: boolean;
	packageForm: FormGroup;
	package: PackageModel = new PackageModel();
	allBranches: BranchModel[] = [];
	allServices: Service[] = [];
	withClose: boolean;
	hasFormErrors: boolean = false;
	viewLoading: boolean = false;
	loadingAfterSubmit: boolean = false;
	errorMessage: string = '';
	constructor(
		private packageFB: FormBuilder,
		public subheaderService: SubheaderService,
		public packageRepository: PackagesRepository,
		public branchesRepository: BranchesRepository,
		public layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<PackagesDetailsComponent>,
		public ref: ChangeDetectorRef,
		public spinner: NgxSpinnerService,
		public typesUtilsService: TypesUtilsService,
		public authService: AuthenticationService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		super(
			subheaderService,
			layoutUtilsService,
			ref,
			spinner,
			typesUtilsService,
			authService
		);
	}

	ngOnInit() {
		this.loadingSubject.next(true);
		this.branchesRepository.getAll().subscribe(result => {
			this.allBranches = result;
		});
		this.packageRepository.getServices().subscribe(result => {
			this.allServices = result;
		});
		this.package = this.data.packageModel;
		console.log(this.package);
		this.createForm();
		this.loadingSubject.next(false);
	}
	createForm() {
		this.packageForm = this.packageFB.group({
			nameAr: [this.package.DName1, Validators.required],
			nameEn: [this.package.DName2, Validators.required],
			description: [this.package.Description, Validators.required],
			branch: [this.package.Branch.Id, Validators.required],
			service: [this.package.ServiceId, Validators.required],
			units: [this.package.UnitsCount, Validators.required],
			ageFrom: [this.package.AgeAvailabilityFrom, Validators.required],
			ageTo: [this.package.AgeAvailabilityTo, Validators.required],
			amount: [this.package.Amount, Validators.required],
			vat: [this.package.VatAmount, Validators.required],
			availableFrom: [this.package.AvailableFrom, Validators.required],
			availableTo: [this.package.AvailableTo, Validators.required],
			isEnabled: [this.package.IsEnabled],
			isTrial: [this.package.IsTrial],
			registrationFees: [this.package.RegistrationFees, Validators.required],
			availableInApp: [this.package.IsAvailableInApp]
		});
	}
	isControlInvalid(controlName: string): boolean {
		const control = this.packageForm.controls[controlName];
		const result = control.invalid && control.touched;
		return result;
	}

	/** ACTIONS */
	preparePackage(): PackageModel {
		const controls = this.packageForm.controls;
		const _Package = new PackageModel();
		_Package.Id = this.package.Id;
		_Package.DName1 = controls['nameAr'].value;
		_Package.DName2 = controls['nameEn'].value;
		_Package.Description = controls['description'].value;
		_Package.BranchId = controls['branch'].value;
		_Package.ServiceId = controls['service'].value;
		_Package.UnitsCount = controls['units'].value;
		_Package.AgeAvailabilityFrom = controls['ageFrom'].value;
		_Package.AgeAvailabilityTo = controls['ageTo'].value;
		_Package.Amount = controls['amount'].value;
		_Package.VatAmount = controls['vat'].value;
		_Package.AvailableFrom = controls['availableFrom'].value;
		_Package.AvailableTo = controls['availableTo'].value;
		_Package.IsEnabled = controls['isEnabled'].value;
		_Package.IsTrial = controls['isTrial'].value;
		_Package.RegistrationFees = controls['registrationFees'].value;
		_Package.IsAvailableInApp = controls['availableInApp'].value;
		console.log(_Package);
		_Package.IsTrial = !_Package.IsTrial ? false : _Package.IsTrial;
		_Package.IsQurrah = !_Package.IsQurrah ? false : _Package.IsQurrah;
		_Package.IsEnabled = !_Package.IsEnabled ? false : _Package.IsEnabled;
		_Package.IsAvailableInApp = !_Package.IsAvailableInApp ? false : _Package.IsAvailableInApp;
		return _Package;
	}

	onSubmit(withClose: boolean = false) {
		this.withClose = withClose;
		this.hasFormErrors = false;
		this.errorMessage = '';
		this.loadingAfterSubmit = false;
		const controls = this.packageForm.controls;
		/** check form */
		if (this.packageForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			return;
		}

		const editedPackage = this.preparePackage();
		if (editedPackage.Id) {
			this.updatePackage(editedPackage);
		} else {
			this.createPackage(editedPackage);
		}
	}

	saveCompleted() {
		if (!this.withClose) {
			this.layoutUtilsService.showActionNotification(
				'تم الحفظ بنجاح',
				MessageType.Success,
				4000,
				true,
				false
			);
			return;
		}
		this.dialogRef.close({
			isEdit: true
		});
	}

	async updatePackage(_Package: PackageModel) {
		this.loadingAfterSubmit = true;
		this.viewLoading = true;
		await this.async(this.packageRepository.update(_Package));
		if(this.hasErrors)return;
		this.saveCompleted();
	}

	async createPackage(_Package: PackageModel) {
		await this.async(this.packageRepository.insert(_Package));
		if(this.hasErrors)return;
		this.saveCompleted();
	}

	onAlertClose($event) {
		this.hasFormErrors = false;
	}
	getTitle(): string {
		if (this.package.Id) { return 'تعديل بيانات باقة'; } else { return 'إضافة باقة جديدة'; }
	}
}
