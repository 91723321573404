import { Component, OnInit, Inject } from "@angular/core";
import { BranchModel } from "../../../../../models/branch.model";
import { BehaviorSubject } from "rxjs";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SubheaderService } from "../../../../../core/services/layout/subheader.service";
import { BranchesRepository } from "../../../../../data/repositories/branches.repository";
import {
	LayoutUtilsService,
	MessageType
} from "../../../../layout/utils/layout-utils.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
	selector: "m-branches-details",
	templateUrl: "./branches-details.component.html",
	styleUrls: ["./branches-details.component.scss"]
})
export class BranchesDetailsComponent implements OnInit {
	loadingSubject = new BehaviorSubject<boolean>(false);
	loading$ = this.loadingSubject.asObservable();
	isNewOrEdit: boolean;
	branchForm: FormGroup;
	branch: BranchModel = new BranchModel();
	withClose: boolean;
	hasFormErrors: boolean = false;
	viewLoading: boolean = false;
	loadingAfterSubmit: boolean = false;
	errorMessage: string = "";
	constructor(
		private branchFB: FormBuilder,
		private subheaderService: SubheaderService,
		public branchesRepository: BranchesRepository,
		private layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<BranchesDetailsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit() {
		this.loadingSubject.next(true);
		this.branch = this.data.branch;
		this.createForm();
		this.loadingSubject.next(false);
	}
	createForm() {
		this.branchForm = this.branchFB.group({
			nameAr: [this.branch.DName1, Validators.required],
			nameEn: [this.branch.DName2, Validators.required],
			phoneNo: [this.branch.PhoneNumber, Validators.required],
			isPrivate: [this.branch.IsPrivate]
		});
	}
	isControlInvalid(controlName: string): boolean {
		const control = this.branchForm.controls[controlName];
		const result = control.invalid && control.touched;
		return result;
	}

	/** ACTIONS */
	prepareBranch(): BranchModel {
		const controls = this.branchForm.controls;
		const branch = new BranchModel();
		branch.Id = this.branch.Id;
		branch.DName1 = controls["nameAr"].value;
		branch.DName2 = controls["nameEn"].value;
		branch.PhoneNumber = controls["phoneNo"].value;
		const isPrivate = controls["isPrivate"].value;
		if (isPrivate) {
			branch.IsPrivate = isPrivate;
		} else {
			branch.IsPrivate = false;
		}
		console.log(branch);
		return branch;
	}

	onSubmit(withClose: boolean = false) {
		this.withClose = withClose;
		this.hasFormErrors = false;
		this.errorMessage = "";
		this.loadingAfterSubmit = false;
		const controls = this.branchForm.controls;
		/** check form */
		if (this.branchForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			return;
		}

		const editedBranch = this.prepareBranch();
		if (editedBranch.Id) {
			this.updateBranch(editedBranch);
		} else {
			this.createBranch(editedBranch);
		}
	}

	saveCompleted() {
		if (!this.withClose) {
			this.layoutUtilsService.showActionNotification(
				"تم الحفظ بنجاح",
				MessageType.Success,
				4000,
				true,
				false
			);
			return;
		}
		this.dialogRef.close({
			isEdit: true
		});
	}

	updateBranch(branch: BranchModel) {
		this.loadingAfterSubmit = true;
		this.viewLoading = true;
		this.branchesRepository.update(branch).subscribe(
			res => {
				this.saveCompleted();
			},
			err => {
				console.log(err);
				this.hasFormErrors = true;
				this.errorMessage = err;
			}
		);
	}

	createBranch(branch: BranchModel) {
		this.branchesRepository.insert(branch).subscribe(
			res => {
				this.saveCompleted();
			},
			err => {
				console.log(err);
				this.hasFormErrors = true;
				this.errorMessage = err;
			}
		);
	}

	onAlertClose($event) {
		this.hasFormErrors = false;
	}
	getTitle(): string {
		if (this.branch.Id) return "تعديل بيانات الفرع";
		else return "إضافة فرع جديد";
	}
}
