import { ChangeDetectionStrategy, Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutConfigService } from '../../../../core/services/layout-config.service';
import { SubheaderService } from '../../../../core/services/layout/subheader.service';
import { BaseComponent } from '../../../../core/base/base.component';
import { LayoutUtilsService } from '../../../layout/utils/layout-utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TypesUtilsService } from '../../../layout/utils/types-utils.service';
import { AuthenticationService } from '../../../../core/auth/authentication.service';

@Component({
	selector: 'm-dashboard',
	templateUrl: './dashboard.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent extends BaseComponent implements OnInit {

	public config: any;

	constructor(
		public subheaderService: SubheaderService,
		public layoutUtilsService: LayoutUtilsService,
		public ref: ChangeDetectorRef,
		public spinner: NgxSpinnerService,
		public typesUtilsService: TypesUtilsService,
		public authService: AuthenticationService,
	) {
		super(
			subheaderService,
			layoutUtilsService,
			ref,
			spinner,
			typesUtilsService,
			authService
		  );
		 this.subheaderService.setBreadcrumbs([
		  ]);
	}

	ngOnInit(): void {}
}
