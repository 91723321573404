import {
	Component,
	OnInit,
	ElementRef,
	ViewChild,
	ChangeDetectionStrategy,
	OnDestroy,
	ChangeDetectorRef
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// Material
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// RXJS
import {
	debounceTime,
	distinctUntilChanged,
	tap,
	mergeMap
} from 'rxjs/operators';
import {
	fromEvent,
	merge,
	observable,
	Observable,
	Subscription,
	of
} from 'rxjs';
// Services
import {
	LayoutUtilsService,
	MessageType
} from '../../../../layout/utils/layout-utils.service';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import { ClientsRepository } from '../../../../../data/repositories/clients.repository';
import { DataGridHelper } from '../../grid.helper';
import { SubscriptionsRepository } from '../../../../../data/repositories/subscriptions.repository';
import { ClientModel } from '../../../../../models/client.model';
import { ClientsDetailsComponent } from '../clients-details/clients-details.component';
import { QueryParamsModel } from '../../../../../models/query-models/query-params.model';
import { QueryResultsModel } from '../../../../../models/query-models/query-results.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClientBranchDetailsComponent } from '../client-branch-details/client-branch-details.component';
import { BranchModel } from '../../../../../models/branch.model';
import { BranchesRepository } from '../../../../../data/repositories/branches.repository';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
// Models

@Component({
	selector: 'm-clients-list',
	templateUrl: './clients-branches.component.html',
	styleUrls: ['./clients-branches.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ClientsRepository]
})
export class ClientsBranchesComponent extends DataGridHelper
	implements OnInit, OnDestroy {

	branches: BranchModel[] = [];
	constructor(
		public dialog: MatDialog,
		private subheaderService: SubheaderService,
		public clientsRepository: ClientsRepository,
		public layoutUtilsService: LayoutUtilsService,
		public spinner: NgxSpinnerService,
		public branchesRepository: BranchesRepository,
		public authService: AuthenticationService,
		public ref: ChangeDetectorRef,


	) {
		super(spinner, layoutUtilsService,authService);
		this.dataGridSource = clientsRepository;
		console.log('cons');
	}
	displayedColumns = [
		'ClientId',
		'Picture',
		'FirstName',
		'PhoneNumber',
		'IdentityNumber',
		'Gender',
		'ChildrensCount',
		'BranchName',
		'Delete'
	];
	ngOnDestroy(): void {
		console.log('destroyed');
	}
	find(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		return this.clientsRepository.getAllInPrivateBranches().pipe(
			mergeMap(res => {
				const result = this.baseFilter(res, queryParams);
				return of(result);
			})
		);
	}
	async ngOnInit() {
		this.initializeDataGrid();
		this.loadList();
		this.branches =  await this.async(this.branchesRepository.getAll());
		this.subheaderService.setBreadcrumbs([
			{ title: 'العملاء' },
			{ title: 'عملاء الفروع الخاصة', page: '/crm/clients/branches' }
		]);

		console.log(this.clientsRepository);
	}

	/** FILTRATION */
	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;
		if (this.filterBranch && this.filterBranch.length > 0) {
			filter.BranchName = +this.filterBranch;
		}
		filter.FullName = searchText;
		filter.PhoneNumber = searchText;
		filter.IdentityNumber = searchText;
		filter.BranchName =  searchText;
		return filter;
	}

	addClientToBranch(client?: ClientModel) {
		const dialogRef = this.dialog.open(ClientBranchDetailsComponent, {
			data: { client }
		});
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}
		
			this.loadList();
			this.ref.detectChanges();
			console.log(res);
			this.saveCompleted();
			});
		}

	editClient(client: ClientModel) {
		const dialogRef = this.dialog.open(ClientsDetailsComponent, {
			data: { client }
		});
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}
			console.log(res);
			this.saveCompleted();
			this.loadList();
			this.ref.detectChanges();
		});
	}

	async removeClientFromBranch(client: ClientModel){
		await this.async(this.clientsRepository.updateClientBranch(client.ClientId, null));
		if(this.hasErrors)return;
		this.saveCompleted();
		this.loadList();
		this.ref.detectChanges();
	}

	saveCompleted() {
			this.layoutUtilsService.showActionNotification(
				'تم الحفظ بنجاح',
				MessageType.Success,
				4000,
				true,
				false
			);
			return;
		}
}
