import { Injectable } from '@angular/core';
import { BaseRepository } from './_base.repository';
import { Observable } from 'rxjs';
import { AccountsProviderContract } from '../contracts/accounts.contract';
import { ExpensesModel, ExpenseType } from '../../models/accounts.model';

@Injectable({
    providedIn: 'root'
})
export class AccountsRepository extends BaseRepository {


    constructor(private provider: AccountsProviderContract) {
        super();
        this.dataProvider = provider;
    }
    getAll() {
		return this.provider.getAll();
	}
    getAllInvoices() {
        return this.provider.getAllInvoices();
    }
    insert(expense: ExpensesModel) {
        return this.provider.insert(expense);
    }
    update(expense: ExpensesModel) {
        return this.provider.update(expense);
    }
    getExpensesTypes(): Observable<ExpenseType[]> {
        return this.provider.getExpensesTypes();
    }
    addExpenseType(model: ExpenseType): Observable<any> {
        return this.provider.addExpenseType(model);
    }
    updateExpenseType(model: ExpenseType): Observable<any> {
        return this.provider.updateExpenseType(model);
    }
    deleteExpense(expenseId: number): Observable<any> {
        return this.provider.deleteExpense(expenseId);
	}
}
