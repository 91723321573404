import { BaseModel } from "./_base.model";
import { BranchModel } from "./branch.model";

export class PackageModel extends BaseModel {
	Id: number;
	DName1: string;
	DName2: string;
	ServiceId: number;
	BranchId: number;
	AvailableFrom: string;
	AvailableTo: string;
	Description: string;
	IsQurrah: boolean;
	Amount: number;
	Branch: BranchModel = new BranchModel();
	AgeAvailabilityFrom: number;
	AgeAvailabilityTo: number;
	VatAmount: number;
	IsEnabled: boolean;
	IsAvailableInApp: boolean;
	IsTrial: boolean;
	RegistrationFees: number;
	QurrahFundAmount: number;
	UnitsCount: number;
	ServiceName: string;
}
export class Service extends BaseModel {
	Id: number;
	DName1: string;
	DName: string;
	DName2: string;
	Image: any;
	Packages: PackageModel[]= [];
}
