import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { CamerasRepository } from '../../../../../data/repositories/cameras.repository';
import { BehaviorSubject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CameraModel } from '../../../../../models/camera.model';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import {
	LayoutUtilsService,
	MessageType
} from '../../../../layout/utils/layout-utils.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BranchModel } from '../../../../../models/branch.model';
import { BaseComponent } from '../../../../../core/base/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { TypesUtilsService } from '../../../../layout/utils/types-utils.service';
import { BranchesRepository } from '../../../../../data/repositories/branches.repository';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';

@Component({
	selector: 'm-cameras-details',
	templateUrl: './cameras-details.component.html',
	styleUrls: ['./cameras-details.component.scss'],
	providers: [CamerasRepository, BranchesRepository]
})
export class CamerasDetailsComponent extends BaseComponent implements OnInit {
	loadingSubject = new BehaviorSubject<boolean>(false);
	loading$ = this.loadingSubject.asObservable();
	isNewOrEdit: boolean;
	cameraForm: FormGroup;
	camera: CameraModel = new CameraModel();
	withClose: boolean;
	hasFormErrors: boolean = false;
	viewLoading: boolean = false;
	loadingAfterSubmit: boolean = false;
	branches: BranchModel[] = [];
	errorMessage: string = '';
	constructor(
		private cameraFB: FormBuilder,
		public subheaderService: SubheaderService,
		public camerasRepository: CamerasRepository,
		public branchesRepository: BranchesRepository,
		public layoutUtilsService: LayoutUtilsService,
		public ref: ChangeDetectorRef,
		public spinner: NgxSpinnerService,
		public typesUtilsService: TypesUtilsService,
		public authService: AuthenticationService,
		public dialogRef: MatDialogRef<CamerasDetailsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		super(
			subheaderService,
			layoutUtilsService,
			ref,
			spinner,
			typesUtilsService,
			authService
		);
		this.createForm();
	}

	ngOnInit() {
		this.loadingSubject.next(true);
		this.camera = this.data.camera;
		this.loadBranches();
		this.createForm();
		this.loadingSubject.next(false);
	}
	loadBranches() {
		this.branchesRepository.getAll().subscribe(result => {
			this.branches = result;
		});
	}
	createForm() {
		this.cameraForm = this.cameraFB.group({
			cameraNameAr: [this.camera.DName1, Validators.required],
			cameraNameEn: [this.camera.DName2, Validators.required],
			thumbnail: [this.camera.ThumbnailPath, Validators.required],
			branch: [this.camera.Branch.Id, Validators.required],
			enabled: [this.camera.IsEnabled, Validators.required],
			link: [this.camera.StreamingLink, Validators.required]
		});
	}

	isControlInvalid(controlName: string): boolean {
		const control = this.cameraForm.controls[controlName];
		const result = control.invalid && control.touched;
		return result;
	}

	/** ACTIONS */
	prepareClient(): CameraModel {
		const controls = this.cameraForm.controls;
		const camera = new CameraModel();
		camera.CameraId = this.camera.CameraId;
		camera.DName1 = controls['cameraNameAr'].value;
		camera.DName2 = controls['cameraNameEn'].value;
		camera.ThumbnailPath = controls['thumbnail'].value;
		camera.BranchId = controls['branch'].value;
		camera.StreamingLink = controls['link'].value;
		camera.IsEnabled = controls['enabled'].value;
		camera.IsEnabled = !camera.IsEnabled ? false : camera.IsEnabled;
		return camera;
	}

	onSubmit(withClose: boolean = false) {
		this.withClose = withClose;
		this.hasFormErrors = false;
		this.errorMessage = '';
		this.loadingAfterSubmit = false;
		const controls = this.cameraForm.controls;
		/** check form */
		if (this.cameraForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			return;
		}

		const editedCamera = this.prepareClient();
		if (editedCamera.CameraId) {
			this.updateCamera(editedCamera);
		} else {
			this.createCamera(editedCamera);
		}
	}

	saveCompleted() {
		if (!this.withClose) {
			this.layoutUtilsService.showActionNotification(
				'تم الحفظ بنجاح',
				MessageType.Success,
				4000,
				true,
				false
			);
			return;
		}
		this.dialogRef.close({
			isEdit: true
		});
	}

	updateCamera(camera: CameraModel) {
		this.loadingAfterSubmit = true;
		this.viewLoading = true;
		this.camerasRepository.update(camera).subscribe(
			res => {
				this.saveCompleted();
			},
			err => {
				console.log(err);
				this.hasFormErrors = true;
				this.errorMessage = err;
			}
		);
	}

	createCamera(camera: CameraModel) {
		this.camerasRepository.insert(camera).subscribe(
			res => {
				this.saveCompleted();
			},
			err => {
				console.log(err);
				this.hasFormErrors = true;
				this.errorMessage = err;
			}
		);
	}

	onAlertClose($event) {
		this.hasFormErrors = false;
	}
	getTitle(): string {
		if (this.camera.CameraId) {
			return 'تعديل بيانات كاميرا';
		} else {
			return 'إضافة كاميرا جديدة';
		}
	}
}
