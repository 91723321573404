import { BaseModel } from "./_base.model";
import { NumericDictionary } from "lodash";

export class FeedModel extends BaseModel {
	FeedId: number;
	Content: string;
	Attachments: Attachments;
	BranchId: number;
	NumberOfLikes: number;
	NumberOfComments: number;
	CanInteract: boolean;
	IsLikedByUser: boolean;
	PublishedByUserName: string;
	PublishedAt: any;
	CreatedBy: string;
	CreatedAt: string;
	IsEnabled: boolean;
	DName1: string;
	DName2: string;
}
export class Attachments {
	AttachmentId: number;
	Extension: string;
	Path: string;
	CreatedBy: string;
	CreatedAt: string;
	Description: string;
	AttachmentTypeId: number;
	EntityId: number;
	EntityType: number;
}
export interface Interactions {
	FeedInteractionId: number;
	FeedId: string;
	UserName: string;
	InteractionType: FeedInteractionTypes;
	Content: string;
	CreatedBy?: string;
	CreatedAt?: Date;
	UserPicturePath?: any;
	date?: any;
  }
  export enum FeedInteractionTypes {
	Like = 1,
	Comment,
	Share
  }
  export interface FeedComment {
	FeedId: number;
	Content: string;
  }