import { BaseModel } from './_base.model';
import { AgeCategoryModel } from './age-category.model';
import { ClientModel } from './client.model';

export class ChildModel extends BaseModel {
    Id: number;
    FirstName:string;
    MidName:string;
    LastName:string;
    NickName:string;
    Picture:string;
    BirthDate: any;
    Gender: string;
    clientName: string;
    ClientId: number;
    AgeInMonths: number;
    Supervisor: string;
    QurrahSubscriptionNo: string;
    QurrahSubscriptionDate: any;
    AgeCategory: AgeCategoryModel;
    Client: ClientModel;
	IdentityNumber: string;
    public get fullName() : string {
        return this.FirstName+' '+this.LastName;
    }
  
}