import { Component, OnInit } from '@angular/core';
import {
	LayoutUtilsService,
	MessageType
} from '../../../../layout/utils/layout-utils.service';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import { QueryParamsModel } from '../../../../../models/query-models/query-params.model';
import { QueryResultsModel } from '../../../../../models/query-models/query-results.model';
import { MatDialog } from '@angular/material';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { DataGridHelper } from '../../../crm/grid.helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { AccountsRepository } from '../../../../../data/repositories/accounts.repository';
import { InvoiceModel } from '../../../../../models/accounts.model';

@Component({
	selector: 'm-invoices-all',
	templateUrl: './invoices-all.component.html',
	styleUrls: ['./invoices-all.component.scss'],
	providers: [AccountsRepository]
})
export class InvoicesAllComponent extends DataGridHelper implements OnInit {
	displayedColumns = [
		'InvoiceId',
		'Date',
		'Notes',
		'Amount',
		'PaymentMethodId',
		'SubscriptionId',
		'Branch',
		'SerialNumber',
		'IsDeleted',
		'Actions'
	];
	dataGridSource: AccountsRepository;
	searchInput: any;

	constructor(
		public dialog: MatDialog,
		private subheaderService: SubheaderService,
		public accountsRepository: AccountsRepository,
		public layoutUtilsService: LayoutUtilsService,
		public spinner: NgxSpinnerService,
		public authService: AuthenticationService


	) {
		super(spinner, layoutUtilsService,authService);
		this.dataGridSource = accountsRepository;
	}
	find(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		return this.accountsRepository.getAllInvoices().pipe(
			mergeMap(res => {
				const result = this.baseFilter(res, queryParams);
				return of(result);
			})
		);
	}
	ngOnInit() {
		this.initializeDataGrid();
		this.loadList();
		console.log('init');
		this.subheaderService.setBreadcrumbs([
			{ title: 'سندات القبض' },
			{ title: 'كل سندات القبض', page: '/accounts/invoices/all' }
		]);

		console.log(this.accountsRepository);
	}

	/** FILTRATION */
	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;

		filter.FirstName = searchText;
		return filter;
	}
	downloadVoucher(invoice: InvoiceModel) {
		if(!invoice) { return; }
		const url = 'https://admin-karazwotoot.com:5443/api/';
		window.location.href = `${url}Invoices/Download?invoiceId=${invoice.InvoiceId}`;

		// this.subscriptionsRepository.downloadReceiptVoucher(1).subscribe(res => {
		// 	console.log(res);
		// });
	}
}
