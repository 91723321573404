import { BaseModel } from './_base.model';

export class AgeCategoryModel extends BaseModel {
	Id: number;
	DName1: string;
    DName2: string;
    AgeFrom:number;
    AgeTo:number;
    Color:string;
}
