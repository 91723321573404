import { ChildsRepository } from '../../../../../data/repositories/childs.repository';
import { SupervisorModel } from '../../../../../models/supervisor.model';
import { ChildModel } from '../../../../../models/child.model';
import { BaseComponent } from '../../../../../core/base/base.component';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import { LayoutUtilsService } from '../../../../layout/utils/layout-utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder } from '@angular/forms';
import { TypesUtilsService } from '../../../../layout/utils/types-utils.service';
import { SubscriptionsRepository } from '../../../../../data/repositories/subscriptions.repository';
import { AttachmentsRepository } from '../../../../../data/repositories/Attachments.repository';
import { ClientsRepository } from '../../../../../data/repositories/clients.repository';
import { BranchesRepository } from '../../../../../data/repositories/branches.repository';
import { PackagesRepository } from '../../../../../data/repositories/packages.repository';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { BranchModel } from '../../../../../models/branch.model';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { DayEventModel } from '../../../../../models/dayevent.model';
const speed = 10;
@Component({
	selector: 'm-childs-supervisors',
	templateUrl: './childs-supervisors.component.html',
	styleUrls: ['./childs-supervisors.component.scss'],
	providers: [ChildsRepository]
})
export class ChildsSupervisorsComponent extends BaseComponent implements OnInit {
	allSupervisors: SupervisorModel[] = [];
	filteredSupervisor: SupervisorModel[] = [];
	branches: BranchModel[] = [];
	allChilds: ChildModel[] = [];
	filteredChildsList: ChildModel[] = [];
	supervisor: SupervisorModel = new SupervisorModel();
	allDropLists: string[] = [];

	constructor(
		public subheaderService: SubheaderService,
		public layoutUtilsService: LayoutUtilsService,
		public ref: ChangeDetectorRef,
		public spinner: NgxSpinnerService,
		public formBuilder: FormBuilder,
		public typesUtilsService: TypesUtilsService,
		public authService: AuthenticationService,
		public childsRepository: ChildsRepository,
		public branchesRepository: BranchesRepository
	) {
		super(
			subheaderService,
			layoutUtilsService,
			ref,
			spinner,
			typesUtilsService,
			authService
		);
	}
	private _filterSupervisors: string = '';
	public get filterSupervisors(): string {
		return this._filterSupervisors;
	}
	public set filterSupervisors(v: string) {
		console.log(v);

		this._filterSupervisors = v;
		this.filterSupervisorsList();
	}

	private _filterChilds: string = '';
	public get filterChilds(): string {
		return this._filterChilds;
	}
	public set filterChilds(v: string) {
		this._filterChilds = v;
		console.log(v);
		this.filterChildsList();
	}
	filterChildsList() {
		if (this.filterChilds.length === 0) {
			this.filteredChildsList = this.allChilds;
		} else {
			this.filteredChildsList = this.allChilds.filter(a =>
				a.FirstName.startsWith(this.filterChilds)
			);
		}
		const childPlaceHolder = new ChildModel();
		this.filteredChildsList.push(childPlaceHolder);
	}
	filterSupervisorsList() {
		if (this.filterSupervisors.length === 0) {
			this.filteredSupervisor = this.allSupervisors;
		} else {
			this.filteredSupervisor = this.allSupervisors.filter(a =>
				a.Employee.FirstName.startsWith(this.filterSupervisors)
			);
		}
	}
	async ngOnInit() {
		this.branches = await this.async(this.branchesRepository.getAll());
		this.refresh();
	}
	async refresh() {
		this.allDropLists = [];
		this.filteredSupervisor = [];
		this.filteredChildsList = [];
		this.allSupervisors = await this.async(
			this.childsRepository.getSupervisors()
		);
		this.allSupervisors.forEach(element => {
			this.allDropLists.push(element.Employee.EmployeeId.toString());
			const childPlaceHolder = new ChildModel();
			element.Childs.push(childPlaceHolder);
		});
		this.allChilds = await this.async(
			this.childsRepository.getChildsWithoutSupervisors()
		);
		this.filterSupervisorsList();
		this.filterChildsList();
		console.log(this.allChilds);
		console.log(this.allSupervisors);

		this.refreshView();
	}

	async drop(event: CdkDragDrop<any>) {
		if (event.previousContainer === event.container) {
			// moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {
			console.log(event.previousContainer.data);
			console.log(event.container.data);

			const childId = event.previousContainer.data[event.previousIndex].Id;
			if (!childId) {
				return;
			}
			if (event.container.id === 'allChilds') {
				await this.async(
					this.childsRepository.removeSupervisor(childId)
				);
			} else {
				await this.async(
					this.childsRepository.addSupervisor(
						childId,
						// tslint:disable-next-line:radix
						Number.parseInt(event.container.id)
					)
				);
			}

			this.refresh();
		}
	}



	async updateSupervisor(childId: number, supervisorId?: number) {
		console.log(childId);
		console.log(supervisorId);
		if (supervisorId) {
			console.log('update supervisor');
			await this.async(this.childsRepository.addSupervisor(childId, supervisorId));
		} else {
			console.log('remove supervisor');
			await this.async(this.childsRepository.removeSupervisor(childId));
		}
		if (this.hasErrors) { return; }


		this.refresh();
		// const el = document.getElementById(supervisorId.toString());
		// el.scrollIntoView();
	}

	async checkOut(childId: number) {
		const newEvent = new DayEventModel();
		newEvent.ChildId = childId;
		newEvent.EventTypeId = 2;
		await this.async(this.childsRepository.newEvent(newEvent));
		if (this.hasErrors) { return; }
		this.refresh();
	}
}
