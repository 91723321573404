import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import { LayoutUtilsService, MessageType } from '../../../../layout/utils/layout-utils.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SettingsRepository } from '../../../../../data/repositories/settings.repository';
import { BaseComponent } from '../../../../../core/base/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { TypesUtilsService } from '../../../../layout/utils/types-utils.service';
import { EventType } from '../../../../../models/settings.model';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { EventsTypesDetailsComponent } from '../../daily-events/events-details/events-details.component';
import { ChildsRepository } from '../../../../../data/repositories/childs.repository';
import { AgeCategoryModel } from '../../../../../models/age-category.model';

@Component({
	templateUrl: './age-categories-details.component.html',
	styleUrls: ['./age-categories-details.component.scss'],
	providers: [ChildsRepository]
})
export class AgeCategoriesDetailsComponent extends BaseComponent implements OnInit {
	loadingSubject = new BehaviorSubject<boolean>(false);
	loading$ = this.loadingSubject.asObservable();
	isNewOrEdit: boolean;
	ageCategoryForm: FormGroup;
	ageCateory: AgeCategoryModel = new AgeCategoryModel();
	hasFormErrors: boolean = false;
	viewLoading: boolean = false;
	loadingAfterSubmit: boolean = false;
	errorMessage: string = '';
	constructor(
		private typeFB: FormBuilder,
		public subheaderService: SubheaderService,
		public childsRepository: ChildsRepository,
		public layoutUtilsService: LayoutUtilsService,
		public ref: ChangeDetectorRef,
		public spinner: NgxSpinnerService,
		public typesUtilsService: TypesUtilsService,
		public authService: AuthenticationService,
		public dialogRef: MatDialogRef<AgeCategoriesDetailsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		super(
			subheaderService,
			layoutUtilsService,
			ref,
			spinner,
			typesUtilsService,
			authService
		);
		this.createForm();

	}

	async	ngOnInit() {
		this.loadingSubject.next(true);
		console.log(this.data.category.Id);
		if (this.data.category.Id) {

			this.ageCateory = Object.assign([], this.data.category);

		} else {
			this.ageCateory.Color = "#ffffff";
		}
		this.createForm();
		this.loadingSubject.next(false);
		this.refreshView();
	}
	createForm() {
		this.ageCategoryForm = this.typeFB.group({
			nameAr: [this.ageCateory.DName1, Validators.required],
			nameEn: [this.ageCateory.DName2, Validators.required],
			color: [this.ageCateory.Color, Validators.required],
			ageFrom: [this.ageCateory.AgeFrom, Validators.required],
			ageTo: [this.ageCateory.AgeTo, Validators.required]
		});
	}

	isControlInvalid(controlName: string): boolean {
		const control = this.ageCategoryForm.controls[controlName];
		const result = control.invalid && control.touched;
		return result;
	}

	/** ACTIONS */
	prepareCategory(): AgeCategoryModel {
		const controls = this.ageCategoryForm.controls;
		const category = new AgeCategoryModel();
		category.Id = this.ageCateory.Id;
		category.DName1 = controls['nameAr'].value;
		category.DName2 = controls['nameEn'].value;
		category.Color = this.ageCateory.Color.slice(1);
		category.AgeFrom = controls['ageFrom'].value;
		category.AgeTo = controls['ageTo'].value;
		console.log(category);
		return category;
	}

	onSubmit(withClose: boolean = false) {
		this.hasFormErrors = false;
		this.errorMessage = '';
		this.loadingAfterSubmit = false;
		const controls = this.ageCategoryForm.controls;
		/** check form */
		if (this.ageCategoryForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			return;
		}

		const editedCategory = this.prepareCategory();
		console.log(editedCategory);
		if (editedCategory.Id) {
			this.updateCategory(editedCategory);
		} else {
			this.createCategory(editedCategory);
		}
	}

	saveCompleted() {
		this.layoutUtilsService.showActionNotification(
			'تم الحفظ بنجاح',
			MessageType.Success,
			4000,
			true,
			false
		);

		this.dialogRef.close({
			isEdit: true
		});
	}

	async	updateCategory(category: AgeCategoryModel) {

		await this.async(this.childsRepository.updateCategory(category));
		if (this.hasErrors) { return; }
		this.saveCompleted();
	}

	async createCategory(category: AgeCategoryModel) {

		await this.async(this.childsRepository.addCategory(category));
		if (this.hasErrors) { return; }
		this.saveCompleted();
	}

	onAlertClose($event) {
		this.hasFormErrors = false;
	}
	getTitle(): string {
		if (this.ageCateory.Id) { return 'تعديل تصنيف'; } else { return 'إضافة تصنيف'; }
	}
}
