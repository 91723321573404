import { Injectable } from '@angular/core';

import { BaseRepository } from './_base.repository';
import { ChatsProviderContract } from '../contracts/chats.contract';
import { Observable } from 'rxjs';
import { UserChats, NewMessage } from '../../models/models';

@Injectable({
	providedIn: 'root'
})
export class ChatsRepository extends BaseRepository {


	getMessages(chatId: number, lastId: number): Observable<any> {
        return this.provider.getMessages(chatId, lastId);
    }
    getChats(): Observable<UserChats[]> {
        return this.provider.getChats();
    }
    sendMessage(newMessage: NewMessage) {
        return this.provider.sendMessage(newMessage);
	}
    constructor(private provider: ChatsProviderContract) {
		super();
		this.dataProvider = provider;
	}
}