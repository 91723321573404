import { Injectable } from "@angular/core";
import {
	HttpClient,
	HttpErrorResponse,
	HttpHeaders,
} from "@angular/common/http";
import { map, tap, catchError, finalize } from "rxjs/operators";
import defaults from "lodash/defaults";
import {
	IApiOptions,
	IAppSettings,
	Language,
	DefaultLanguage,
} from "./contracts/index";
import { BehaviorSubject, Observable } from "rxjs";
@Injectable()
export class Api {
	loadingSubject = new BehaviorSubject<boolean>(false);
	loading$: Observable<boolean>;
	private readonly defaults: IApiOptions = {
		appendBaseUrl: true,
		handleError: true,
		handleLoading: true,
		headers: new HttpHeaders().set("Content-Type", "application/json"),
	};

	private token: string;
	private settings: IAppSettings;
	// private apiUrl: string = 'https://api-karazwotoot.azurewebsites.net/api/';
	//private apiUrl: string = "http://localhost:8080/api/";
	//  private apiUrl: string = "http://40.119.164.32:90/api/";
	//private apiUrl: string = "http://40.119.164.32:1011/api/";
	//  private apiUrl: string = 'https://app-karazwotoot.com:5443/api/';
	private apiUrl: string = "https://admin.karazwotoot.com:8090/api/";
	constructor(private http: HttpClient) {
		// tslint:disable-next-line: max-line-length
		// localStorage.setItem('accessToken','tPH-mNXZGn7HjImMNkVMrfki0pOkHgBzCX9_SFdgH4LMR5JbeEokZg9Uvp5-q8kMCIXms-ndz2KNx6X0IwvL75_7XJN1422JdgFqEJziVsizZU3MbhtqqV2ZTaJsBSa-JOExhTfygVhpFonAE8C7ZgQ7YI3UHqNtQpya-UWB-x-Ho1FJmFY-2HUSA_38xyqfpIgtMuS5Dmnuj0xIOwMcB4n4JU9DeeNSi7q1-lFlX8xR-PxC2B3wbmTbboYb0jCIyRwdDs12VY-E10tJTPs1kFYU4sv81Y41VTsh06sLXSF787Pla6zbHXC3X9jxaoe0Bz8GbtXjVczoIYKI92xxej9gXmCjw_EAaPCsxHx1VFJC84m2sNbweYrg-yARapbGxYHedtH2Ls5cmbrzcwiCugPZWC1oZtshLoEF_T1Aks0');
		this.token = localStorage.getItem("accessToken");
	}
	public getAuthToken() {
		return this.token;
	}
	public setAuthToken(token: string) {
		this.token = token;
	}
	public removeAuthToken() {
		this.token = null;
	}
	public get(url: string, options?: IApiOptions) {
		return this.request("GET", { url, options });
	}
	public post(url: string, data?: any, options?: IApiOptions) {
		return this.request("POST", { url, data, options });
	}
	public update(url: string, data?: any, options?: IApiOptions) {
		return this.request("PUT", { url, data, options });
	}
	public delete(url: string, options?: IApiOptions) {
		return this.request("DELETE", { url, options });
	}
	private request(
		method: string,
		{
			url,
			data = {},
			options = {},
		}: { url: string; data?: any; options: IApiOptions }
	) {
		defaults(options, this.defaults);
		url = this.appendBaseUrl(url);
		options.url = url;
		options.body = data;
		//     options.headers = options.headers.set('lang', this.settings ?
		//     Language[this.settings.language] : Language[DefaultLanguage]
		// );

		if (this.token) {
			options.headers = options.headers.set(
				"Authorization",
				`bearer ${this.token}`
			);
		}
		if (options.handleLoading) {
			// this.loadingSubject.next(true);
		}
		return this.http.request(method, url, options).pipe(
			tap((e) => {}),
			map((data) => {
				return data;
			}),
			// ,catchError(err=>{
			//     console.log(err);
			//     //this.layoutUtilsService.showActionNotification(err.statusCode, MessageType.Success, 10000, true, false);
			//     return err;
			// })
			finalize(() => {
				// console.log('hide loading');
			})
		);
		// .tap(e => { if (options.handleLoading) {
		//    // this.ui.hideLoading();
		//    console.log('loading');
		// } })
		// ,map(data => {
		//     return data;
		// });
	}
	private noConnection(options: IApiOptions) {
		// this.errHandler
		//     .handleError(new InternalError(
		//         'No internet connection', ErrorCodes.Offline, options.handleError
		//     ));
		// this.ui.hideLoading();
		// return Observable.empty();
		console.log("noConnection");
	}

	private appendBaseUrl(shortUrl: string) {
		return this.apiUrl + shortUrl;
	}
}
