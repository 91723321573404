import { ConfigModel } from '../core/interfaces/config';

export class PagesConfig implements ConfigModel {
	public config: any = {};

	constructor() {
		this.config = {
			'/': {
				page: {
					title: 'لوحة المعلومات',
					desc: 'Latest updates and statistic charts'
				}
			},
			crm:
			{
				// crm
				subscriptions:
				{
					all: {
						page: { title: 'كل الإشتراكات', desc: '' }
					},
					qurrah: {
						page: { title: 'إشتراكات قرة', desc: '' }
					},
					details: {
						page: { title: 'تفاصيل اشتراك', desc: '' }
					}
				},
				clients:
				{
					all:
					{
						page: { title: 'كل العملاء', desc: '' }
					},
					reviews:
					{
						page: { title: 'تقييمات العملاء', desc: '' }
					}
				},
				childs:
				{
					all:
					{
						page: { title: 'كل الأطفال', desc: '' }
					},
					'childs-supervisors': {
						page: { title: 'مشرفات الأطفال', desc: '' }
					},
					'childs-without-supervisors': {
						page: { title: 'أطفال بدون مشرفات', desc: '' }
					}
					,
					details: {
						page: { title: 'ملف طفل', desc: '' }
					}
				},
				ads:
				{
					feeds:
					{
						all:
						{
							page: { title: 'صفحة المشاركات', desc: '' }
						}
					},
					sms: {
						all:
						{
							page: { title: 'الرسائل النصية', desc: '' }
						}
					}
				}
			},
			hcm:
			{
				employees :
				{
					all :
					{
						page: { title: 'كل الموظفين', desc: ''}
					}
				}
			},
			accounts:
			{
				invoices :
				{
					all :
					{
						page: { title: 'سندات القبض', desc: ''}
					}
				}
			},
			settings:
			{
				packages :
				{
					all :
					{
						page: { title: 'كل الباقات', desc: ''}
					}
				}
			},
			404: {
				page: { title: '404 Not Found', desc: '', subheader: false }
			}
		};
	}
}
