import { BaseModel } from './_base.model';

export class AttachmentsTypesModel extends BaseModel {
	Id: number;
	DName1: string;
	DName2: string;
	EntityType: EntityType;
	EntityTypeId: number;
}
export class EntityType {
	Id: number;
	DName1: string;
	DName2: string;
}
export class EventType {
	DayEventTypeId: number;
	DName: string;
	DName1: string;
	DName2: string;
	Color: string;
	Image: string;
	IsVisibleToClient: boolean;
	IsNotesRequired: boolean;
	IsEmojiRequired: boolean;
}
export class Emoji {
	EmojiId: number;
	Content: string;
	DName1: string;
	DName2: string;
}
