import { Component, OnInit, Input } from "@angular/core";
import { DataGridHelper } from "../../../../pages/crm/grid.helper";
import { BehaviorSubject, Observable, of } from "rxjs";
import { MatDialog } from "@angular/material";
import {
	LayoutUtilsService,
	MessageType
} from "../../../../layout/utils/layout-utils.service";
import { NgxSpinnerService } from "ngx-spinner";
import { QueryParamsModel } from "../../../../../models/query-models/query-params.model";
import { QueryResultsModel } from "../../../../../models/query-models/query-results.model";
import { mergeMap } from "rxjs/operators";
import { AttachmentsRepository } from "../../../../../data/repositories/Attachments.repository";
import {
	AttachmentModel,
	AttachmentType
} from "../../../../../models/attachment.model";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../../../../core/auth/authentication.service";

@Component({
	selector: "m-attachments-list",
	templateUrl: "./attachments-list.component.html",
	styleUrls: ["./attachments-list.component.scss"],
	providers: [AttachmentsRepository]
})
export class AttachmentsListComponent extends DataGridHelper implements OnInit {
	displayedColumns = [
		"AttachmentId",
		"AttachmentName",
		"PublishedAt",
		"PublishedBy",
		"Actions"
	];
	// Incoming data
	@Input() loadingSubject: BehaviorSubject<boolean>;
	@Input() entityId: number;
	@Input() entityType: number;
	attachmentList: AttachmentModel[] = [];
	attachmentTypes: AttachmentType[] = [];
	newAttachmentForm: FormGroup;
	attachmentFile: any;
	constructor(
		public dialog: MatDialog,
		public attachmentsRepository: AttachmentsRepository,
		public layoutUtilsService: LayoutUtilsService,
		public spinner: NgxSpinnerService,
		public formBuilder: FormBuilder,
		private router: Router,
		public authService: AuthenticationService
	) {
		super(spinner, layoutUtilsService, authService);
		this.dataGridSource = attachmentsRepository;
	}
	ngOnInit() {
		// 	this.loadingSubject.next(true);
		console.log("created");
		this.initializeDataGrid();
		this.loadTypes();
		this.loadList();
		this.createForm();
		this.dataGridSource = this.attachmentsRepository;
	}
	filterConfiguration(): any {
		const filter: any = {};
		// const searchText: string = this.searchInput.nativeElement.value;
		if (this.filterState && this.filterState.length > 0) {
			filter.State = +this.filterState;
		}
		return filter;
	}

	find(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		return this.attachmentsRepository
			.getAttachments(this.entityId, this.entityType)
			.pipe(
				mergeMap(res => {
					this.attachmentList = res;
					console.log(res);
					const result = this.baseFilter(res, queryParams);
					return of(result);
				})
			);
	}
	createForm() {
		console.log("form create");
		this.newAttachmentForm = this.formBuilder.group({
			attachmentType: [null, Validators.required],
			attachmentFile: [this.attachmentFile, Validators.required]
		});
	}
	async deleteAttachment(attachment: AttachmentModel) {
		await this.async(
			this.attachmentsRepository.delete(attachment.AttachmentId)
		);
		if (this.hasErrors) {
			return;
		}
		this.createForm();
		console.log("uploaded");
		this.saveCompleted();
		this.loadList();
	}

	loadTypes() {
		this.attachmentsRepository
			.getAttachmentTypes(this.entityType)
			.subscribe(result => {
				this.attachmentTypes = result;
			});
	}

	async onSubmit() {
		/** check form */
		if (this.newAttachmentForm.invalid) {
			const controls = this.newAttachmentForm.controls;
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		console.log(
			"file form",
			this.newAttachmentForm.controls["attachmentFile"].value
		);
		const file = this.newAttachmentForm.controls["attachmentFile"].value
			.files[0];

		const attachmentType = this.newAttachmentForm.controls["attachmentType"]
			.value;
		console.log("file blob", file);
		const formData = new FormData();
		formData.append("myFile", file, file.name);
		formData.set("enctype", "multipart/form-data");
		console.log("formData", formData);
		console.log("entityId", this.entityId);
		console.log("entityType", this.entityType);
		console.log("attachmentType", attachmentType);

		await this.async(
			this.attachmentsRepository.upload(
				formData,
				this.entityId,
				"",
				attachmentType,
				this.entityType
			)
		);
		if (this.hasErrors) {
			return;
		}
		this.createForm();
		console.log("uploaded");
		this.saveCompleted();
		this.loadList();
	}
	saveCompleted() {
		this.layoutUtilsService.showActionNotification(
			"تم الحفظ بنجاح",
			MessageType.Success,
			4000,
			true,
			false
		);
	}
}
