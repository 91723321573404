import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { BaseComponent } from '../../../../../core/base/base.component';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import { LayoutUtilsService } from '../../../../layout/utils/layout-utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder } from '@angular/forms';
import { TypesUtilsService } from '../../../../layout/utils/types-utils.service';
import { DataGridHelper } from '../../grid.helper';
import { MatDialog } from '@angular/material';
import { SubscriptionsRepository } from '../../../../../data/repositories/subscriptions.repository';
import { QueryParamsModel } from '../../../../../models/query-models/query-params.model';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { QueryResultsModel } from '../../../../../models/query-models/query-results.model';
import { mergeMap } from 'rxjs/operators';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';

@Component({
  selector: 'm-subscription-days',
  templateUrl: './subscription-days.component.html',
  styleUrls: ['./subscription-days.component.scss'], 
  changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [SubscriptionsRepository]
})
export class SubscriptionDaysComponent extends DataGridHelper implements OnInit {

  	// Incoming data
	@Input() loadingSubject = new BehaviorSubject<boolean>(false);
  @Input() subscriptionId: number;
  
	subscriptionColumns = ["Date", "State", "Notes"];
subscriptionDays: Observable<any>;
	constructor(
		public dialog: MatDialog,
		private subheaderService: SubheaderService,
		public subscriptionsRepository: SubscriptionsRepository,
		public layoutUtilsService: LayoutUtilsService,
		public spinner: NgxSpinnerService,
		public authService: AuthenticationService


	) {
		super(spinner,layoutUtilsService,authService);
		this.dataGridSource = subscriptionsRepository;
	}

  ngOnInit() {
    console.log('created');
    this.initializeDataGrid();
		this.loadList();
		this.dataGridSource = this.subscriptionsRepository;
  }
	filterConfiguration(): any {
		const filter: any = {};
		// const searchText: string = this.searchInput.nativeElement.value;

		if (this.filterState && this.filterState.length > 0) {
			filter.State = +this.filterState;
		}

		return filter;
	}

	find(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		return this.subscriptionsRepository.getSubscriptionDays(this.subscriptionId).pipe(
			mergeMap(res => {
        this.subscriptionDays = res;
        console.log(res);
				const result = this.baseFilter(res, queryParams);
				return of(result);
			})
		);
	}
}
