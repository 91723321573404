import { Component, OnInit } from '@angular/core';
import { SubheaderService } from '../../../../../../core/services/layout/subheader.service';

@Component({
  selector: 'm-sms-all',
  templateUrl: './sms-all.component.html',
  styleUrls: ['./sms-all.component.scss']
})
export class SmsAllComponent implements OnInit {

  constructor(
    private subheaderService: SubheaderService) { 
      this.subheaderService.setBreadcrumbs([
        { title: ' الأطفال' },
        { title: 'كل الأطفال',  page: '/crm/childs/all' }
      ]);
    }


  ngOnInit() {
  }

}
