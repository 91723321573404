import { SubscriptionModel } from "../../models/subscription.model";
import { BaseContract } from "./base.contract";
import { Observable } from "rxjs";
import { UserModel } from '../../models/user.model';

export abstract class UsersProviderContract extends BaseContract<UserModel> {
	abstract getUserPermissions(id: string): Observable<any>;

	abstract login(email:string,password:string): Observable<UserModel>;
}
