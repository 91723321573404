import { Injectable } from '@angular/core';
import { Api } from './api';
import { Observable } from 'rxjs';
import { UserModel } from '../../../models/user.model';
import { UsersProviderContract } from '../../contracts/users.contract';

@Injectable({
  providedIn: 'root'
})
export class UsersServiceDataProvider implements UsersProviderContract  {
    getUserPermissions(id: string): Observable<any> {
        return this.api.get(`Permissions/GetUserPermissions?userId=${id}`)
    }
    login(email: string, password: string): Observable<UserModel> {
        return this.api.post('Users/AdminLogin',{email,password})
    }
    get(id: number): Observable<UserModel> {
        throw new Error("Method not implemented.");
    }
    getAll(): Observable<UserModel[]> {
        throw new Error("Method not implemented.");
    }
    delete(id: number): Observable<any>  {
        throw new Error("Method not implemented.");
    }
    update(entity: UserModel): Observable<any> {
        throw new Error("Method not implemented.");
    }
    insert(entity: UserModel): Observable<any> {
        throw new Error("Method not implemented.");
    }


  constructor(private api:Api) { }

}
