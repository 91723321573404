import { Injectable } from '@angular/core';
import { BaseRepository } from './_base.repository';
import { FeedsProviderContract } from '../contracts/feeds.contract';
import { FeedModel, Interactions } from '../../models/feed.model';
import { Observable, observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
  })
export class FeedsRepository extends BaseRepository {

	constructor(private provider: FeedsProviderContract) {
        super();
        this.dataProvider = provider;
    }
    get(feedId: number) {
			return this.provider.get(feedId);
    }
    getAll() {
			return this.provider.getAll();
    }
    addFeed(feed: FeedModel): Observable<number> {
      return this.provider.insert(feed);
    }
    updateFeed(feed: FeedModel): Observable<number> {
      return this.provider.update(feed);
  }
  getFeedInteractions(feedId: number, interactionType: number): Observable<any> {
	  return this.provider.getFeedInteractions(feedId, interactionType);
  }
  deleteInteraction(feedInteractionId: number): Observable<any> {
		throw new Error("Method not implemented.");
	}
}
