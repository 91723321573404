import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Routes } from '@angular/router';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule, MatDatepickerModule } from '@angular/material';
import {
	MatAutocompleteModule,
	MatNativeDateModule,
	MatFormFieldModule,
	MatInputModule,
	MatRadioModule,
	MatButtonModule,
	MatCardModule,
	MatChipsModule,
	MatSelectModule,
	MatProgressBarModule,
	MatProgressSpinnerModule,
	MatIconModule,
	MatSliderModule,
	MatPaginatorModule,
	MatSortModule,
	MatSidenavModule,
	MatSnackBarModule,
	MatStepperModule,
	MatToolbarModule,
	MatDividerModule,
	MatTabsModule,
	MatTableModule,
	MatTooltipModule,
	MatListModule,
	MatGridListModule,
	MatButtonToggleModule,
	MatBottomSheetModule,
	MatExpansionModule,
	_MatChipListMixinBase,
	MatMenuModule,
	MatTreeModule,
	MatCheckboxModule,
	MAT_BOTTOM_SHEET_DATA,
	MatBottomSheetRef,
	MatSlideToggleModule
} from '@angular/material';
import { ColorPickerModule } from 'ngx-color-picker';
import { CoreModule } from '../../../core/core.module';
import { PartialsModule } from '../../partials/partials.module';
import { MaterialPreviewModule } from '../../partials/content/general/material-preview/material-preivew.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NgxSpinnerModule } from 'ngx-spinner';
import { InvoicesAllComponent } from './invoices/invoices-all/invoices-all.component';
import { ExpensesAllComponent } from './expenses/expenses-all/expenses-all.component';
import { ExpensesDetailsComponent } from './expenses/expenses-details/expenses-details.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
export const MY_FORMATS = {
	parse: {
		dateInput: 'DD/MM/YYYY',
	},
	display: {
		dateInput: 'YYYY/MM/DD',
		monthYearLabel: 'YYYY MMM',
		dateA11yLabel: 'YYYY/MM/DD',
		monthYearA11yLabel: 'YYYY MMM',
	},
};
const routes: Routes = [
	{
		path: '',
		children: [
			{
				path: 'invoices/all',
				component: InvoicesAllComponent
			}
		]
	},
	{
		path: '',
		children: [
			{
				path: 'expenses/all',
				component: ExpensesAllComponent
			},
			{
				path: "expenses/edit/0/new",
				component: ExpensesDetailsComponent
			},
			{
				path: "expenses/edit/:id",
				component: ExpensesDetailsComponent
			}
		]
	}
];
@NgModule({
	imports: [
		CommonModule,
		MatInputModule,
		MatFormFieldModule,
		MatAutocompleteModule,
		MatListModule,
		MatSliderModule,
		MatCheckboxModule,
		MatCardModule,
		MatSelectModule,
		MatButtonModule,
		MatIconModule,
		MatNativeDateModule,
		MatSlideToggleModule,
		MatMenuModule,
		MatTabsModule,
		MatTooltipModule,
		MatSidenavModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatTableModule,
		MatGridListModule,
		MatToolbarModule,
		MatBottomSheetModule,
		MatExpansionModule,
		MatDividerModule,
		MatSortModule,
		MatStepperModule,
		MatChipsModule,
		MatPaginatorModule,
		MatDialogModule,
		CoreModule,
		CommonModule,
		MatRadioModule,
		MatTreeModule,
		MatButtonToggleModule,
		PartialsModule,
		MaterialPreviewModule,
		FormsModule,
		ReactiveFormsModule,
		LayoutModule,
		ColorPickerModule,
		MaterialFileInputModule,
		NgxSpinnerModule,
		MatDatepickerModule,
		RouterModule.forChild(routes)
	],
	exports: [RouterModule],
	entryComponents: [],
	providers: [
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				panelClass: 'm-mat-dialog-container__wrapper',
				height: 'auto',
				width: '1000px'
			}
		},
		{provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
		{provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
	],
	declarations: [
		InvoicesAllComponent,
		ExpensesAllComponent,
		ExpensesDetailsComponent
	]
})
export class AccountsModule {}
