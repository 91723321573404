import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DashboardRepository } from '../../../../../../data/repositories/dashboard.repository';
import { BaseComponent } from '../../../../../../core/base/base.component';
import { SubheaderService } from '../../../../../../core/services/layout/subheader.service';
import { LayoutUtilsService } from '../../../../../layout/utils/layout-utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TypesUtilsService } from '../../../../../layout/utils/types-utils.service';
import { AuthenticationService } from '../../../../../../core/auth/authentication.service';
import Chart from 'chart.js';
import { BarChartItemModel } from '../../../../../../models/dashboard.model';


@Component({
	selector: 'm-subscriptions-visits',
	templateUrl: './subscriptions-visits.component.html',
	styleUrls: ['./subscriptions-visits.component.scss'],
	providers: [DashboardRepository]
})
export class SubscriptionsVisitsComponent extends BaseComponent implements OnInit {
	public data: BarChartItemModel[] = [];

	constructor(
		public dashboardRepository: DashboardRepository,
		public subheaderService: SubheaderService,
		public layoutUtilsService: LayoutUtilsService,
		public ref: ChangeDetectorRef,
		public spinner: NgxSpinnerService,
		public typesUtilsService: TypesUtilsService,
		public authService: AuthenticationService,
	) {
		super(
			subheaderService,
			layoutUtilsService,
			ref,
			spinner,
			typesUtilsService,
			authService
		);
	}

	async ngOnInit() {
		this.data = await this.async(
		  this.dashboardRepository.getsubscriptionsVisits()
		);
		this.createChart();
		this.refreshView();
	  }

	// events
	chartClicked(e: any): void {
	}

	chartHovered(e: any): void {
	}

	createChart() {
		let ctx2 = document.getElementById('subscriptionsVisits');
		let myDoughnutChart = new Chart(ctx2, {
		  type: 'bar',
		  data: {
			datasets: [
			  {
				data: this.data.map(a => a.Value1),
				backgroundColor: 'rgba(52, 152, 219,1.0)',
				label: 'الزيارات'
			  }
			],
			labels: this.data.map(a=> a.Name)
		  }
		});
	  }
}
