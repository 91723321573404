export class SubscriptionStateModel {
	Id: number;
	DName1: string;
	DName2: string;
	Color: string;
}
export class SubscriptionDayStateModel {
	Id: number;
	DName1: string;
	DName2: string;
}