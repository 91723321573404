import { BaseContract } from './base.contract';
import { AttachmentModel, AttachmentType } from '../../models/attachment.model';
import { Observable } from 'rxjs';


export  abstract class AttachmentsProviderContract extends BaseContract<AttachmentModel>{


    abstract getAttachments(entityId: number, entityType: number): Observable<AttachmentModel[]>;
    abstract uploadAttachment(formData: FormData, EntityId:number, Description:string, AttachmentTypeId:number, EntityType:number);
    abstract getAttachmentTypes(entityType: number): Observable<AttachmentType[]>;

}