import { BaseModel } from "./_base.model";

export class BranchModel extends BaseModel {
	Id: number;
	DName: string;
	DName1: string;
	DName2: string;
	PhoneNumber: string;
	IsPrivate: boolean;
}
