
// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// Lodash
import * as _lodash from 'lodash';
// RxJS
import { Observable, of, Subscription, BehaviorSubject } from 'rxjs';
// Lodash
import { each, find, some } from 'lodash';
// NGRX
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
import { delay, every } from 'rxjs/operators';
import { RoleModel, Permissions, Modules } from '../../../../../../models/role.model';
import { PermissionModel } from '../../../../../../models/permission.model';
import { BaseComponent } from '../../../../../../core/base/base.component';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { SubheaderService } from '../../../../../../core/services/layout/subheader.service';
import { PermissionsRepository } from '../../../../../../data/repositories/permissions.repository';
import { EmployeesRepository } from '../../../../../../data/repositories/employees.repository';
import { BranchesRepository } from '../../../../../../data/repositories/branches.repository';
import { LayoutUtilsService, MessageType } from '../../../../../layout/utils/layout-utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TypesUtilsService } from '../../../../../layout/utils/types-utils.service';
import { AuthenticationService } from '../../../../../../core/auth/authentication.service';

@Component({
	selector: 'role-edit-dialog',
	templateUrl: './role-edit.dialog.component.html',
	changeDetection: ChangeDetectionStrategy.Default,
})
export class RoleEditDialogComponent extends BaseComponent implements OnInit {
	loadingSubject = new BehaviorSubject<boolean>(false);
	loading$ = this.loadingSubject.asObservable();
	isNewOrEdit: boolean;
	role: RoleModel = new RoleModel();
	hasFormErrors: boolean = false;
	viewLoading: boolean = false;
	loadingAfterSubmit: boolean = false;
	rolePermissions: RoleModel = new RoleModel();
	roleForm: FormGroup;
	withClose: boolean;
	errorMessage: string = '';
	selectedPermissions: string = '';
	constructor(private roleFB: FormBuilder,
		public subheaderService: SubheaderService,
		public permissionsRepository: PermissionsRepository,
		public employeesRepository: EmployeesRepository,
		public branchesRepository: BranchesRepository,
		public layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<RoleEditDialogComponent>,
		public ref: ChangeDetectorRef,
		public spinner: NgxSpinnerService,
		public typesUtilsService: TypesUtilsService,

		public authService: AuthenticationService,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		super(
			subheaderService,
			layoutUtilsService,
			ref,
			spinner,
			typesUtilsService,
			authService
		);
		this.createForm();
	}

	ngOnInit() {
		this.loadingSubject.next(true);
		if (this.data.role) {
			this.role = this.data.role;
		}
		this.createForm();
		this.loadPermissions();
		this.loadingSubject.next(false);
	}
	getTitle(): string {
		if (this.role.RoleId) { return 'تعديل بيانات دور'; } else { return 'إضافة دور جديدة'; }
	}
	async loadPermissions() {
		this.rolePermissions = await this.async(
			this.permissionsRepository.getRole(this.role.RoleId)
		);

	for (let i = 0; i < this.rolePermissions.Modules.length; i++) {
		const md = this.rolePermissions.Modules[i];
		if (md.Permissions.find(a=>a.ModuleId===md.ModuleId && !a.IsInRole))
		{
			continue;
		}
		this.rolePermissions.Modules[i].isSelected = true;
	}
	}

	createForm() {
		console.log('form create');
		this.roleForm = this.roleFB.group({
			arName: [this.role.DName1, Validators.required],
			enName: [this.role.DName2, Validators.required]
		});
	}
	preparePermissionIds(): number[] {
		const result = [];
		each(this.rolePermissions.Modules, (_root: Modules) => {
			each(_root.Permissions, (_child: Permissions) => {
				if (_child.IsInRole) {
					result.push(_child.PermissionId);
				}
			});
		});
		return result;
	}

	prepareRole(): RoleModel {
		const controls = this.roleForm.controls;
		const _role = new RoleModel();
		_role.RoleId = this.role.RoleId;
		_role.DName1 = controls['arName'].value;
		_role.DName2 = controls['enName'].value;
		return _role;
	}

	onSubmit(withClose: boolean = false) {
		this.selectedPermissions = '';
		this.withClose = withClose;
		this.hasFormErrors = false;
		this.errorMessage = '';
		this.loadingAfterSubmit = false;
		const controls = this.roleForm.controls;
		/** check form */
		if (this.roleForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			return;
		}
		const permissions = this.preparePermissionIds();
		console.log(permissions);
		let index = 0;
		for (const item of permissions) {
			if (index !== (permissions.length - 1)) {
				this.selectedPermissions = this.selectedPermissions.concat('permissions=' + item + '&');
				index++;
			} else {
				this.selectedPermissions = this.selectedPermissions.concat('permissions=' + item);
			}
		}
		console.log(this.selectedPermissions);
		const editedRole = this.prepareRole();
		this.insertUpdateRole(editedRole);
	}

	insertUpdateRole(_role: RoleModel) {
		this.loadingAfterSubmit = true;
		this.viewLoading = true;
		this.permissionsRepository.insertUpdateRole(_role, this.selectedPermissions).subscribe(
			res => {
				this.saveCompleted();
			},
			err => {
				console.log(err);
				this.hasFormErrors = true;
				this.errorMessage = err;
			}
		);
	}
	saveCompleted() {
		if (!this.withClose) {
			this.layoutUtilsService.showActionNotification(
				"تم الحفظ بنجاح",
				MessageType.Success,
				4000,
				true,
				false
			);
			return;
		}
		this.dialogRef.close({
			isEdit: true
		});
	}
	onAlertClose($event) {
		this.hasFormErrors = false;
	}

	isSelectedChanged($event, permission?: Permissions, roleModule?: Modules) {
		if (permission && permission.IsInRole) {
			const _root = find(this.rolePermissions.Modules, (item: Modules) => item.ModuleId === permission.ModuleId);
			if (_root && !_root.isSelected) {
				if (_root.Permissions.every(item => item.IsInRole === true)) {
					_root.isSelected = true;
				}
			}
			return;
		}
		if (permission && !permission.IsInRole) {
			const _root = find(this.rolePermissions.Modules, (item: Modules) => item.ModuleId === permission.ModuleId);
			if (_root && _root.isSelected) {
				if (!some(_root.Permissions, (item: Permissions) => item.IsInRole === true)) {
					_root.isSelected = false;
				}
			}
			return;
		}

		if (roleModule && roleModule.isSelected) {
			each(roleModule.Permissions, (item: Permissions) => item.IsInRole = true);
			return;
		}

		if (roleModule && !roleModule.isSelected) {
			each(roleModule.Permissions, (item: Permissions) => {
				item.IsInRole = false;
			});
			return;
		}
	}
}
