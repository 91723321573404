import { SubscriptionModel, CancelSubscriptionModel } from '../../models/subscription.model';
import { BaseContract } from './base.contract';
import { Observable } from 'rxjs';
import { DayEventModel } from '../../models/dayevent.model';
import { SubscriptionDayStateModel } from '../../models/subscription-state.model';

export abstract class SubscriptionsContract extends BaseContract<
	SubscriptionModel
> {
	abstract getdayVisits(subscriptionId: number,
		 branchId?: number,
		 subscriptionState?: number,
		 dayStateId?: number,
		 clientId?: number,
		 childId?: number,
		 packageId?: number,
		 dateFrom?: string,
		 dateTo?: string,
		 eventTypeId?: number): Observable<any[]>;

	abstract getSubscriptionsList(
		branchId: number,
		stateId: number
	): Observable<any[]>;
	abstract addSubscription(
		subscription: SubscriptionModel,
		updateState: boolean
	): Observable<number>;
	abstract updatePaymentDetails(
		subscriptionId: number,
		paymentMethodId: number,
		discountAmount: number,
		qurrahFundAmount: number,
		withUpdateState: boolean
	): Observable<number>;
	abstract updateSubscription(
		subscription: SubscriptionModel,
		updateState: boolean
	): Observable<number>;
	abstract getSubscriptionDays(subscriptionId: number): Observable<any[]>;
	abstract getAll(branchId?: number,
		stateId?: number,
		subscriptionId?: number,
		clientId?: number,
		childId?: number,
		packageId?: number,
		startDateFrom?: string,
		startDateTo?: string,
		endDateFrom?: string,
		endDateTo?: string,
		createdDateFrom?: string,
		createdDateTo?: string): Observable<SubscriptionModel[]>;

		abstract getQurrah(branchId?: number,
			stateId?: number,
			subscriptionId?: number,
			clientId?: number,
			childId?: number,
			packageId?: number,
			startDateFrom?: string,
			startDateTo?: string,
			endDateFrom?: string,
			endDateTo?: string,
			createdDateFrom?: string,
			createdDateTo?: string): Observable<SubscriptionModel[]>;


	abstract downloadReceiptVoucher(subscriptionId: number): Observable<any>;
	abstract getStates(): Observable<any>;
	abstract getDayEvents(subscriptionDayId: number): Observable<DayEventModel[]>;
	abstract getDayStatuses(): Observable<SubscriptionDayStateModel[]>;
	abstract cancelSubscription(cancelSubscriptionModel: CancelSubscriptionModel): Observable<any>;
	abstract deleteSubscription(subscriptionDayId: number): Observable<any>;
	abstract renewSubscription(subscriptionDayId: number): Observable<any>;
	}

