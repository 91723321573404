import { Component, OnInit } from '@angular/core';
import {
	LayoutUtilsService,
	MessageType
} from '../../../../layout/utils/layout-utils.service';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import { QueryParamsModel } from '../../../../../models/query-models/query-params.model';
import { QueryResultsModel } from '../../../../../models/query-models/query-results.model';
import { MatDialog } from '@angular/material';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { DataGridHelper } from '../../../crm/grid.helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { AccountsRepository } from '../../../../../data/repositories/accounts.repository';
import { ExpensesModel } from '../../../../../models/accounts.model';
import { ExpensesDetailsComponent } from '../expenses-details/expenses-details.component';

@Component({
	selector: 'm-expenses-all',
	templateUrl: './expenses-all.component.html',
	styleUrls: ['./expenses-all.component.scss'],
	providers: [AccountsRepository]
})
export class ExpensesAllComponent extends DataGridHelper implements OnInit {
	displayedColumns = [
		'ExpenseId',
		'Date',
		'Notes',
		'Amount',
		'PaymentMethodId',
		'Branch',
		'ExpenseType.DName1',
		'Actions'
	];
	dataGridSource: AccountsRepository;
	searchInput: any;

	constructor(
		public dialog: MatDialog,
		private subheaderService: SubheaderService,
		public accountsRepository: AccountsRepository,
		public layoutUtilsService: LayoutUtilsService,
		public spinner: NgxSpinnerService,
		public authService: AuthenticationService


	) {
		super(spinner, layoutUtilsService, authService);
		this.dataGridSource = accountsRepository;
	}
	find(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		return this.accountsRepository.getAll().pipe(
			mergeMap(res => {
				const result = this.baseFilter(res, queryParams);
				return of(result);
			})
		);
	}
	ngOnInit() {
		this.initializeDataGrid();
		this.loadList();
		console.log('init');
		this.subheaderService.setBreadcrumbs([
			{ title: 'المصروفات' },
			{ title: 'كل المصروفات', page: '/accounts/expenses/all' }
		]);
	}

	/** FILTRATION */
	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;

		filter.FirstName = searchText;
		return filter;
	}

	addExpense() {
		const expense = new ExpensesModel();
		this.editExpense(expense);
	}
	editExpense(expense: ExpensesModel) {
		let saveMessageTranslateParam =
			expense.ExpenseId > 0 ? 'UPDATE_MESSAGE' : 'ADD_MESSAGE';
		const _saveMessage = 'تم الحفظ بنجاح';
		const _messageType =
			expense.ExpenseId > 0 ? MessageType.Success : MessageType.Success;
		const dialogRef = this.dialog.open(ExpensesDetailsComponent, {
			data: { expense }
		});
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}
			console.log(res);
			this.layoutUtilsService.showActionNotification(
				_saveMessage,
				_messageType,
				4000,
				true,
				false
			);

			this.loadList();
		});
	}

	async	deleteExpense(expenseId: number) {
		const dialogRef = this.layoutUtilsService.showConfirmationDialog('حذف مصروف', 'هل أنت متأكد من أنك تريد حذف المصروف؟');
		dialogRef.afterClosed().subscribe(async res => {
			if (!res) {
				return;
			}
			await this.async(this.accountsRepository.deleteExpense(expenseId));
			if (this.hasErrors) { return; }
			this.loadList();

		});



	}
}
