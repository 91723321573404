import { Component, OnInit } from '@angular/core';
import { DataGridHelper } from '../../../crm/grid.helper';
import { SubheaderService } from '../../../../../core/services/layout/subheader.service';
import { MatDialog } from '@angular/material';
import {
	LayoutUtilsService,
	MessageType
} from '../../../../layout/utils/layout-utils.service';
import { Observable, of } from 'rxjs';
import { QueryParamsModel } from '../../../../../models/query-models/query-params.model';
import { QueryResultsModel } from '../../../../../models/query-models/query-results.model';
import { mergeMap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { AttachmentsTypesDetailsComponent } from '../types-details/types-details.component';
import { SettingsRepository } from '../../../../../data/repositories/settings.repository';
import { AttachmentsTypesModel } from '../../../../../models/settings.model';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
@Component({
  templateUrl: './types-all.component.html',
  styleUrls: ['./types-all.component.scss'],
  providers: [SettingsRepository]
})
export class AttachmentsTypesAllComponent extends DataGridHelper implements OnInit {
  displayedColumns = [
		'TypeId',
    'DName1',
    'DName2',
		'EntityType'
	];
  constructor(
    public dialog: MatDialog,
		private subheaderService: SubheaderService,
    public settingsRepository: SettingsRepository,
		public spinner: NgxSpinnerService,
    public layoutUtilsService: LayoutUtilsService,
		public authService: AuthenticationService
    ) {
		super(spinner, layoutUtilsService,authService);
		this.dataGridSource = settingsRepository;
    }

   find(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
      return this.settingsRepository.getAllAttachmentsTypes().pipe(
        mergeMap(res => {
          const result = this.baseFilter(res, queryParams);
          return of(result);
        })
      );
   }
    ngOnInit() {
      this.initializeDataGrid();
      this.loadList();
      this.subheaderService.setBreadcrumbs([
        { title: 'أنواع المرفقات' },
        { title: 'كل أنواع المرفقات', page: '/settings/types/all' }
      ]);
    }

    /** FILTRATION */
    filterConfiguration(): any {
      const filter: any = {};
      const searchText: string = this.searchInput.nativeElement.value;
      filter.DName1 = searchText;
      filter.DName2 = searchText;
      filter.EntityType = searchText;
      return filter;
    }

    addType() {
      const type = new AttachmentsTypesModel();
      this.editType(type);
    }
    editType(type: AttachmentsTypesModel) {
      const _saveMessage = 'تم الحفظ بنجاح';
      const dialogRef = this.dialog.open(AttachmentsTypesDetailsComponent, {
        data: { type }
      });
      dialogRef.afterClosed().subscribe(res => {
        if (!res) {
          return;
        }
        console.log(res);
        this.layoutUtilsService.showActionNotification(
          _saveMessage,
          4000,
          null,
          true,
          false
        );

        this.loadList();
      });
    }

}
